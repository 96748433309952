import React from "react";
import { useModal } from "react-modal-hook";
import { useForm, Controller } from "react-hook-form";

import Modal from "../../Common/Modal";
import { Select } from "../../Inputs";
import { Textarea } from "../../Inputs/react-hook-form";
import { useFlagTranscriptionTaskMutation } from "../../../generated/graphql";
import { Button } from "../../Common/Button";
import { FLAGGED_REASONS } from "common/constants";
import { useStatusToasts } from "../../../hooks/useStatusToasts";

import {
  ButtonSection,
  Container,
  ContentSection,
  FormSection,
  HeaderSection,
  InputRow,
  PrimaryButtons,
} from "../../Common/__styles__/Modal";

export type FlagTaskModalProps = {
  hide: () => void;
  taskId: string;
  enableForm: () => void;
  assignTask: () => void;
  onCancel: () => void;
};

type FlagTaskModalHookProps = Pick<
  FlagTaskModalProps,
  "taskId" | "enableForm" | "assignTask"
>;

interface FlagTaskFormStructure {
  flaggedReason: string;
  notes: Maybe<string>;
}

export const useFlagTaskModal = ({ ...props }: FlagTaskModalHookProps) => {
  const onCancel = () => {
    props.enableForm();
    hide();
  };

  const [show, hide] = useModal(() => {
    return (
      <Modal onRequestClose={onCancel}>
        <FlagTaskModal onCancel={onCancel} hide={hide} {...props} />
      </Modal>
    );
  }, [props]);

  return [show, hide] as const;
};

const FlagTaskModal = ({
  hide,
  taskId,
  onCancel,
  assignTask,
}: FlagTaskModalProps) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FlagTaskFormStructure>();

  const { addSuccessToast, addFailureToast } = useStatusToasts();

  const [flagTask, { loading }] = useFlagTranscriptionTaskMutation({
    onCompleted: () => {
      addSuccessToast("This task has been successfully flagged.");
      hide();
      //This timeout gives the user has a chance to read the success toast. When we assign the new task there is a page reload which dismisses the toast.
      setTimeout(() => {
        assignTask();
      }, 500);
    },
    onError: () => {
      addFailureToast(
        "An error occurred while flagging this task, please try again. If the issue persists, please select 'Exit' to un-assign this task."
      );
    },
  });

  const handleSave = async ({
    flaggedReason,
    notes,
  }: {
    flaggedReason: string;
    notes: Maybe<string>;
  }) => {
    await flagTask({
      variables: {
        flaggedReason,
        notes,
        id: taskId,
      },
    });
  };

  return (
    <Container overflows>
      <HeaderSection>
        <h1>Flag task</h1>
        <h2>Please explain why this task is being flagged.</h2>
      </HeaderSection>
      <FormSection
        overflows
        onSubmit={handleSubmit(async userProvidedData => {
          await handleSave({
            flaggedReason: userProvidedData.flaggedReason,
            notes: userProvidedData.notes,
          });
        })}
      >
        <ContentSection data-testid="inputs" overflows>
          <InputRow hasError={!!errors.flaggedReason}>
            <Controller
              control={control}
              name="flaggedReason"
              rules={{ required: "This field is required" }}
              render={({ field, fieldState }) => {
                return (
                  <Select
                    name="flaggedReason"
                    required={true}
                    value={field.value}
                    error={fieldState.error?.message}
                    options={FLAGGED_REASONS.map(reason => ({
                      value: reason,
                      label: reason,
                    }))}
                    onChange={field.onChange}
                  />
                );
              }}
            />
          </InputRow>
          <Textarea
            placeholder="Optional note..."
            {...register("notes")}
            style={{ fontSize: "14px" }}
          />
        </ContentSection>
        <ButtonSection>
          <PrimaryButtons>
            <Button
              styleVariant="secondary"
              onClick={() => {
                onCancel();
              }}
              disabled={loading}
              size="large"
            >
              Cancel
            </Button>
            <Button
              styleVariant="primary"
              disabled={Object.keys(errors).length > 0}
              loading={loading}
              size={"large"}
            >
              Flag task
            </Button>
          </PrimaryButtons>
        </ButtonSection>
      </FormSection>
    </Container>
  );
};

export default FlagTaskModal;
