import React from "react";
import { Map } from "./Map";
import { LayerContextProvider } from "./layers";
import { useMapsForAccountQuery } from "../../generated/graphql";
import { InternalMapContextProvider } from "./InternalMapContextProvider";

export default () => {
  const { loading, error, data } = useMapsForAccountQuery({
    variables: { filterHidden: false, isGuest: false },
  });

  if (loading || error || !data?.account?.bounds) {
    return <div />;
  }

  return (
    <LayerContextProvider account={data.account}>
      <InternalMapContextProvider>
        <Map />
      </InternalMapContextProvider>
    </LayerContextProvider>
  );
};
