import React, { useContext } from "react";
import useNavigationTab from "../../hooks/useNavigationTabs";
import { AddressPanelTabProps } from ".";
import Improvements from "./Improvements";
import {
  Section,
  TabContainer,
  Title,
  Wrapper,
} from "./__styles__/AddressPanel";
import { AuthContext } from "../Authorization/AuthContext";
import { SubmissionCategory } from "../../generated/graphql";
import { map } from "lodash";
import TabGroup from "../Common/TabGroup";
import Logs from "./Logs";
import Permits from "./Permits";
import { RECORDS_TAB_NAME } from "common/routing";
import { Inspections } from "./Inspections";
import { FlexRow } from "../Common/Layout";

export const Layout = ({
  actionButton,
  children,
  title,
}: {
  actionButton?: React.ReactNode;
  children: React.ReactNode;
  title: string;
}) => (
  <div>
    <FlexRow style={{ justifyContent: "space-between" }}>
      <Title>
        <span>{title}</span>
      </Title>
      {actionButton}
    </FlexRow>
    {children}
  </div>
);

export const Records = (props: Omit<AddressPanelTabProps, "onClose">) => {
  const { account } = useContext(AuthContext);

  const tabs = [
    { value: RECORDS_TAB_NAME.IMPROVEMENTS, label: "SI/SD" },
    { value: RECORDS_TAB_NAME.LOGS, label: "Logs" },
    { value: RECORDS_TAB_NAME.INSPECTIONS, label: "Inspections" },
  ];

  const categories = new Set(
    account?.submissionTypes.map(type => type.category)
  );

  if (categories.has(SubmissionCategory.PERMITTING)) {
    tabs.splice(2, 0, {
      value: RECORDS_TAB_NAME.PERMITS,
      label: "Permits",
    });
  }

  const defaultTab = tabs[0]!;

  const [tab, setTab] = useNavigationTab({
    defaultTab: defaultTab.value,
    allowedTabs: map(tabs, "value"),
    queryParamName: "recordTab",
  });

  return (
    <>
      <TabContainer style={{ marginTop: "8px" }}>
        <TabGroup currentTab={tab} setTab={setTab} tabs={tabs} />
      </TabContainer>

      <Wrapper>
        <Section>
          {tab === RECORDS_TAB_NAME.IMPROVEMENTS && <Improvements {...props} />}
          {tab === RECORDS_TAB_NAME.LOGS && <Logs {...props} />}
          {tab === RECORDS_TAB_NAME.PERMITS && <Permits {...props} />}
          {tab === RECORDS_TAB_NAME.INSPECTIONS && <Inspections {...props} />}
        </Section>
      </Wrapper>
    </>
  );
};
