import React from "react";
import { Body } from "../../../../Common/__styles__/Typography";
import { Required } from "../../../../Inputs/__styles__/Label";
import { useFormContext } from "react-hook-form";
import { EditSectionFormStructure } from "./EditSection";
import { Checkbox, Text } from "../../../../Inputs/react-hook-form";
import { FlexColumn, FlexRow } from "../../../../Common/Layout";
import { Label } from "../../../../Inputs";
import { PROPERTY_INFORMATION_SECTION_CODE } from "common/constants";
import { colors } from "../../../../../stitches.config";

export const SectionSettings = ({
  code,
}: {
  code: PROPERTY_INFORMATION_SECTION_CODE;
}) => {
  const {
    formState: { errors },
    register,
  } = useFormContext<EditSectionFormStructure>();

  const isRepLoss = code === PROPERTY_INFORMATION_SECTION_CODE.REPETITIVE_LOSS;

  const repLossDisplaySettings = (
    <FlexRow style={{ gap: "8px" }}>
      <Checkbox id="isPii" disabled={true} checked={true} />
      <Label
        text="Personally Identifiable Information"
        htmlFor="isPii"
        disabled={true}
      />
    </FlexRow>
  );

  return (
    <FlexColumn style={{ gap: "16px" }}>
      <Body size={"large"} type={"emphasis"}>
        All fields are required<Required>*</Required>
      </Body>
      <Text
        {...register("label", { required: "This is a required field" })}
        label="Section name"
        error={errors.label?.message}
      />
      <FlexColumn>
        <Label text="Display setting" />
        {isRepLoss && repLossDisplaySettings}
        <FlexRow style={{ gap: "8px" }}>
          <Checkbox
            id="displayToPublic"
            {...register("displayToPublic")}
            disabled={isRepLoss}
          />
          <FlexColumn style={{ gap: "4px" }}>
            <Label
              text="Display on public website"
              htmlFor="displayToPublic"
              disabled={isRepLoss}
              style={{ marginBottom: "0px" }}
            />
            <Body
              style={{ color: colors.contentSecondary.toString() }}
              size="default"
              type="regular"
            >
              Attribute display values are maintained but overridden if section
              is hidden
            </Body>
          </FlexColumn>
        </FlexRow>
      </FlexColumn>
    </FlexColumn>
  );
};
