import React from "react";
import { useLocation } from "react-router-dom";
import { buildLink } from "common/routing";
import {
  Submission,
  SubmissionStatus,
  SubmissionType,
} from "../../../generated/graphql";
import { formatDateString } from "../../../utils/dates";
import ActionButton from "../../Submissions/ActionButton";
import { Row, Subtitle, Title } from "../DocumentUploads/__styles__/ListItem";
import { SubmissionLink } from "./__styles__/Permits";
import { hasSummary } from "../../Submissions/utils";

export const statusDisplayMapper: Record<SubmissionStatus, string> = {
  [SubmissionStatus.CANCELED]: "Unprocessed",
  [SubmissionStatus.COMPLETED]: "Completed",
  [SubmissionStatus.PROCESSING]: "Processing",
};

export type SubmissionListItemProps = {
  submission: Pick<Submission, "id" | "createdAt" | "updatedAt" | "status"> & {
    summaries: Array<{ __typename: string }>;
    submissionTypeVersion: {
      submissionType: Pick<SubmissionType, "name" | "category">;
    };
  };
  onUpdate: () => void;
};

export default ({ submission, onUpdate }: SubmissionListItemProps) => {
  const { pathname, search } = useLocation();

  const prevLocation = `${pathname}${search}`;
  const dateText =
    submission.createdAt === submission.updatedAt ? "Submitted" : "Last edited";

  return (
    <Row>
      <SubmissionLink
        to={{
          pathname: buildLink("viewSubmission", {
            submissionId: submission.id,
          }),
          state: { prevLocation },
        }}
      >
        <div style={{ margin: "8px 0" }}>
          <Title>{submission.submissionTypeVersion.submissionType.name}</Title>
          <Subtitle>
            {dateText}{" "}
            {formatDateString({
              format: "MM/DD/YYYY",
              dateString: submission.updatedAt,
            })}{" "}
            - {statusDisplayMapper[submission.status]}
          </Subtitle>
        </div>
      </SubmissionLink>

      <ActionButton
        submission={{
          ...submission,
          hasSummary: hasSummary(submission),
          category: submission.submissionTypeVersion.submissionType.category,
        }}
        onUpdate={onUpdate}
      />
    </Row>
  );
};
