import React, { useState } from "react";
import { Radio } from "../../../../../Inputs";
import {
  ButtonSection,
  Container,
  ContentSection,
  HeaderSection,
  PrimaryButtons,
} from "../../../../../Common/__styles__/Modal";
import { Button } from "../../../../../Common/Button";
import { useModal } from "react-modal-hook";
import Modal from "../../../../../Common/Modal";
import { Body } from "../../../../../Common/__styles__/Typography";

type DeleteModalRadioValue = "moduleOnly" | "moduleAndInputs";

export const useDeleteModuleModal = ({
  onSubmit,
}: {
  onSubmit: (values: {
    deleteType: DeleteModalRadioValue;
    fieldName: string;
  }) => void;
}) => {
  const [props, setProps] = useState<{ fieldName: string }>({ fieldName: "" });
  const [show, hide] = useModal(() => {
    return (
      <Modal onRequestClose={hide}>
        <DeleteModuleModal
          onClose={hide}
          onSubmit={(deleteType: DeleteModalRadioValue) => {
            onSubmit({ deleteType, fieldName: props.fieldName });
            hide();
          }}
        />
      </Modal>
    );
  }, [props]);

  const showWithProps = ({ fieldName }: { fieldName: string }) => {
    setProps({ fieldName });
    show();
  };

  return [showWithProps, hide] as const;
};

const DeleteModuleModal = ({
  onClose,
  onSubmit,
}: {
  onClose: () => void;
  onSubmit: (deleteType: DeleteModalRadioValue) => void;
}) => {
  const [radioValue, setRadioValue] =
    useState<DeleteModalRadioValue>("moduleOnly");
  return (
    <Container>
      <HeaderSection>
        <h1>Delete Module</h1>
      </HeaderSection>
      <ContentSection>
        <Body size="default" type="emphasis">
          This field is associated with a module. You must delete the module
          before deleting any of its associated fields.
        </Body>
        <Radio
          name={"moduleDeletionOptions"}
          value={radioValue}
          options={[
            { value: "moduleOnly", text: "Delete the module" },
            {
              value: "moduleAndInputs",
              text: "Delete the module and all associated fields",
            },
          ]}
          onChange={value => {
            setRadioValue(value as DeleteModalRadioValue);
          }}
        />
      </ContentSection>
      <ButtonSection>
        <PrimaryButtons>
          <Button styleVariant="secondary" onClick={onClose} size="medium">
            Cancel
          </Button>
          <Button
            styleVariant="alert"
            onClick={() => onSubmit(radioValue)}
            size="medium"
          >
            Delete
          </Button>
        </PrimaryButtons>
      </ButtonSection>
    </Container>
  );
};
