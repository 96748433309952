import React, { useState } from "react";
import * as Sentry from "@sentry/browser";
import { useDropzone as useReactDropzone } from "react-dropzone";
import { FormProvider, useForm } from "react-hook-form";
import { useStatusToasts } from "../../../../../hooks/useStatusToasts";
import {
  FirmComponent,
  FirmSource,
  useGetPresignedGeoJsonComponentUploadS3UrlsLazyQuery,
  useScheduleFirmImportMutation,
} from "../../../../../generated/graphql";

import RevalidateEcsFormStage from "./RevalidateEcsStage";
import UploadGeoJsonComponentsStage from "./UploadGeoJsonComponentsStage";
import { keyBy } from "lodash";

export enum FORM_STAGE {
  GEOJSON_COMPONENT_UPLOAD,
  REVALIDATE_ECS,
}

export interface Component {
  label: string;
  name: FirmComponent;
  id?: string;
  originalFilename: string;
  blob: any;
}

export type UseDropzones = Array<{
  name: FirmComponent;
  useDropzone: (
    args: Pick<NonNullable<Parameters<typeof useReactDropzone>[0]>, "onDrop">
  ) => ReturnType<typeof useReactDropzone>;
}>;

export interface FirmImportForm {
  components?: Array<Component>;
  revalidateEcs: boolean;
  issueDate?: Maybe<Date>;
}

export interface FirmImportFormProps {
  firmId: string;
  firmSource: FirmSource;
  onCancel: () => void;
  onScheduledFirmImport: () => void;
  useDropzones?: UseDropzones;
}

const DefaultComponents = [
  { label: "Floodzones", name: FirmComponent.FLOODZONES },
  { label: "FIRM panels", name: FirmComponent.PANELS },
  { label: "LiMWA", name: FirmComponent.LIMWAS },
  { label: "Cross sections", name: FirmComponent.CROSS_SECTIONS },
  {
    label: "Base flood elevations",
    name: FirmComponent.BASE_FLOOD_ELEVATIONS,
  },
  {
    label: "Profile baselines",
    name: FirmComponent.PROFILE_BASELINES,
  },
];

export const FirmImportForm = ({
  firmId,
  firmSource,
  onCancel,
  onScheduledFirmImport,
  useDropzones = undefined,
}: FirmImportFormProps) => {
  const { addSuccessToast, addFailureToast } = useStatusToasts();
  const isGeojson = firmSource === FirmSource.GEOJSON;

  const formMethods = useForm<FirmImportForm>({
    defaultValues: {
      revalidateEcs: false,
      components: isGeojson ? DefaultComponents : undefined,
    },
  });

  const [formStage, setFormStage] = useState<FORM_STAGE>(
    isGeojson ? FORM_STAGE.GEOJSON_COMPONENT_UPLOAD : FORM_STAGE.REVALIDATE_ECS
  );
  const [s3FileUploadsLoading, setS3FileUploadsLoading] = useState(false);

  const onError = (message: string) => {
    addFailureToast(message);
  };

  const [scheduleFirmImport, { loading: scheduleFirmImportLoading }] =
    useScheduleFirmImportMutation({
      fetchPolicy: "no-cache",
      onCompleted: () => {
        onCancel();
        addSuccessToast("Your FIRM Import is processing.");
        onScheduledFirmImport();
      },
      onError: error => onError(error.message),
    });

  const [getPresignedUrls] =
    useGetPresignedGeoJsonComponentUploadS3UrlsLazyQuery({
      onCompleted: async data => {
        const forerunnerJobId =
          data.getPresignedGeoJsonComponentUploadS3Urls.forerunnerJobId;
        const firmComponentUploadUrls = keyBy(
          data.getPresignedGeoJsonComponentUploadS3Urls.firmComponentUploadUrls,
          url => url.name
        );

        const components = formMethods
          .getValues("components")!
          .filter(component => component.blob);

        try {
          await Promise.all(
            components.map(async component => {
              const firmComponentUploadUrl =
                firmComponentUploadUrls[component.name]!.url;

              const result = await fetch(firmComponentUploadUrl, {
                method: "PUT",
                body: component.blob,
              });

              if (!result.ok) {
                throw new Error(
                  `Received a ${result.status} when uploading ${component.name}`
                );
              }

              return result;
            })
          );

          const issueDate = formMethods.getValues("issueDate");

          await scheduleFirmImport({
            variables: {
              data: {
                forerunnerJobId,
                firmId,
                componentNames: components.map(component => component.name),
                revalidateEcs: formMethods.getValues("revalidateEcs"),
                issueDate: issueDate ? new Date(issueDate) : undefined,
              },
            },
          });
        } catch (error) {
          onError(
            "There was a problem scheduling your firm import. Please try again."
          );
          Sentry.captureException(error);
        } finally {
          setS3FileUploadsLoading(false);
        }
      },
      fetchPolicy: "no-cache",
    });

  const handleScheduleFirmImport = async ({ components }: FirmImportForm) => {
    if (firmSource === FirmSource.GEOJSON) {
      setS3FileUploadsLoading(true);
      await getPresignedUrls({
        variables: {
          componentNames: components!
            .filter(component => component.blob)
            .map(component => component.name),
        },
      });
    } else {
      const issueDate = formMethods.getValues("issueDate");

      await scheduleFirmImport({
        variables: {
          data: {
            firmId,
            revalidateEcs: formMethods.getValues("revalidateEcs"),
            issueDate: issueDate ? new Date(issueDate) : undefined,
          },
        },
      });
    }
  };

  return (
    <FormProvider {...formMethods}>
      {formStage === FORM_STAGE.GEOJSON_COMPONENT_UPLOAD && (
        <UploadGeoJsonComponentsStage
          onNext={() => setFormStage(FORM_STAGE.REVALIDATE_ECS)}
          onCancel={onCancel}
          useDropzones={useDropzones}
        />
      )}
      {formStage === FORM_STAGE.REVALIDATE_ECS && (
        <RevalidateEcsFormStage
          loading={scheduleFirmImportLoading || s3FileUploadsLoading}
          onBack={
            firmSource === FirmSource.GEOJSON
              ? () => setFormStage(FORM_STAGE.GEOJSON_COMPONENT_UPLOAD)
              : undefined
          }
          onCancel={onCancel}
          onImport={formMethods.handleSubmit(handleScheduleFirmImport)}
        />
      )}
    </FormProvider>
  );
};
