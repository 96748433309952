import React, { MouseEvent, useEffect, useRef } from "react";

import DropdownMenu from "./Inputs/DropdownMenu";
import { User } from "common/authorization/customPolicies";
import { Account, Admin, SubmissionCategory } from "../generated/graphql";
import { getPublicAccountPortalBaseURL } from "common/utils/url";
import { getPath } from "common/routing";
import { track } from "../utils/tracking";
import { Icon } from "./Common/Icons/LucideIcons";

import {
  AccountInfo,
  AccountLogo,
  AccountTitle,
  Container,
  ItemWrapper,
  HomeButton,
  Label,
  StyledNavLink,
  BottomNav,
} from "./__styles__/SideNav";
import { AuthContext } from "./Authorization/AuthContext";
import { FEATURE_FLAG_NAME } from "common/constants";

type SideNavWidthContextType = {
  width: number;
};
export const SideNavWidthContext = React.createContext<SideNavWidthContextType>(
  {
    width: 0,
  }
);

const actions = [
  {
    label: "Help center",
    href: "https://forerunner.helpscoutdocs.com/",
    target: "_blank",
  },
  {
    label: "Release notes",
    href: "https://headwayapp.co/forerunner-changelog",
    target: "_blank",
  },
];

const PublicAccountPortal = ({
  subdomain,
  expanded,
}: {
  subdomain: string;
  expanded: boolean;
}) => {
  const { account } = React.useContext(AuthContext);

  if (!account?.publicPortal.enabled) return null;

  const link = getPublicAccountPortalBaseURL({
    subdomain: subdomain,
    protocol: window.location.protocol,
    appDomain: `${window.env.APPLICATION_DOMAIN}`,
  });

  return (
    <StyledNavLink
      as="a"
      href={link}
      data-testid="public-account-portal-link"
      target="_blank"
      onClick={() => track("Public website button clicked")}
    >
      <Label expanded={expanded}>
        <Icon color="white" iconName="panels-top-left" size="16" />
        <span>Public site</span>
      </Label>
    </StyledNavLink>
  );
};

const trackNavLinkClick = (location: string) => {
  track("Sidetab nav clicked", { location });
};

export interface SideNavProps {
  user?: Omit<User, "role">;
  account?: Pick<Account, "logoUrl" | "name"> & {
    publicPortal: Pick<Account["publicPortal"], "subdomain">;
    submissionTypes: Pick<Account["submissionTypes"][0], "category">[];
  };
  admin?: Pick<Admin, "id">;
  setSideNavWidth?: (width: number) => void;
}
const SideNav = ({ user, account, admin, setSideNavWidth }: SideNavProps) => {
  const [expanded, setExpanded] = React.useState(false);
  const sideNavRef = useRef<HTMLDivElement>(null);
  const { isFeatureEnabled } = React.useContext(AuthContext);

  useEffect(() => {
    if (sideNavRef.current && setSideNavWidth) {
      setSideNavWidth(sideNavRef.current.offsetWidth);
    }
  }, [expanded]);

  const HelpIcon = ({
    onClick,
  }: {
    onClick: (event: MouseEvent<HTMLElement>) => void;
  }) => (
    <ItemWrapper onClick={onClick}>
      <Label expanded={expanded}>
        <Icon color="white" iconName="help-circle" size="16" />
        <span>Help Center</span>
      </Label>
    </ItemWrapper>
  );

  if (!account) {
    return null;
  }

  return (
    <Container ref={sideNavRef}>
      <AccountInfo>
        <HomeButton to={getPath("map")}>
          {account.logoUrl ? (
            <AccountLogo
              src={account.logoUrl}
              alt={`${account.name} Logo`}
            ></AccountLogo>
          ) : (
            <AccountTitle>{account.name[0]}</AccountTitle>
          )}
        </HomeButton>
        <ItemWrapper>
          <Label onClick={() => setExpanded(!expanded)} round>
            <Icon
              color="white"
              iconName={expanded ? "chevron-left" : "chevron-right"}
              size="16"
            />
          </Label>
        </ItemWrapper>
      </AccountInfo>

      <StyledNavLink
        to={getPath("map")}
        onClick={() => trackNavLinkClick("map")}
      >
        <Label expanded={expanded}>
          <Icon color="white" iconName="globe-2" size="16" />
          <span>Map</span>
        </Label>
      </StyledNavLink>
      <StyledNavLink
        to={getPath("documentUploads")}
        onClick={() => trackNavLinkClick("files")}
      >
        <Label expanded={expanded}>
          <Icon color="white" iconName="file-text" size="16" />
          <span>Files</span>
        </Label>
      </StyledNavLink>
      {isFeatureEnabled(FEATURE_FLAG_NAME.PROPERTY_TABLE) && (
        <StyledNavLink
          to={getPath("properties")}
          onClick={() => trackNavLinkClick("properties")}
        >
          <Label expanded={expanded}>
            <Icon color="white" iconName="home" size="16" />
            <span>Properties</span>
          </Label>
        </StyledNavLink>
      )}
      {account.submissionTypes.find(
        submissionType =>
          submissionType.category === SubmissionCategory.PERMITTING
      ) && (
        <StyledNavLink
          to={getPath("submissions")}
          onClick={() => trackNavLinkClick("permitting")}
        >
          <Label expanded={expanded}>
            <Icon color="white" iconName="folder-down" size="16" />
            <span>Permits</span>
          </Label>
        </StyledNavLink>
      )}
      <StyledNavLink
        to={getPath("inspections")}
        onClick={() => trackNavLinkClick("inspections")}
      >
        <Label expanded={expanded}>
          <Icon color="white" iconName="clipboard-check" size="16" />
          <span>Inspections</span>
        </Label>
      </StyledNavLink>
      <StyledNavLink
        to={getPath("logs")}
        onClick={() => trackNavLinkClick("logs")}
      >
        <Label expanded={expanded}>
          <Icon color="white" iconName="library" size="16" />
          <span>Logs</span>
        </Label>
      </StyledNavLink>
      <StyledNavLink
        to={getPath("settings")}
        onClick={() => trackNavLinkClick("settings")}
      >
        <Label expanded={expanded}>
          <Icon color="white" iconName="settings" size="16" />
          <span>Settings</span>
        </Label>
      </StyledNavLink>

      <BottomNav>
        <PublicAccountPortal
          subdomain={account.publicPortal.subdomain}
          expanded={expanded}
        />
        <DropdownMenu
          position="above-right"
          actions={actions}
          customButton={HelpIcon}
        />
        {user && (
          <StyledNavLink as="a" href={getPath("logout")}>
            <Label expanded={expanded} border>
              <Icon color="white" iconName="arrow-right-square" size="16" />
              <span>Log Out</span>
            </Label>
          </StyledNavLink>
        )}
        {admin && (
          <StyledNavLink as="a" href={getPath("adminAccounts")}>
            <Label expanded={expanded} border>
              <Icon color="white" iconName="arrow-right-square" size="16" />
              <span>Accounts</span>
            </Label>
          </StyledNavLink>
        )}
      </BottomNav>
    </Container>
  );
};
export default SideNav;
