import { formatDateString } from "common/utils/dates";
import React, { useContext } from "react";
import { AddressPanelTabProps } from "..";
import {
  Submission,
  SubmissionCategory,
  SubmissionIntakeSource,
  useGetInspectionsQuery,
} from "../../../generated/graphql";
import { AuthContext } from "../../Authorization/AuthContext";
import Divider from "../../Common/Divider";
import { EmptyState } from "../../Common/EmptyState";
import ListItem from "../Improvements/ListItem";
import { Layout } from "../Records";
import { canCreateInternalSubmission } from "common/authorization/customPolicies";
import { CreateSubmissionButton } from "../../Common/CreateSubmissionButton";
import { AddressPanelContext } from "../AddressPanelContext";

export type PartialInspection = Pick<
  Submission,
  "id" | "updatedAt" | "createdAt"
> & {
  description: string;
  isPda: boolean;
  submissionTypeVersion: {
    submissionType: {
      name: string;
    };
  };
};

export const Inspections = ({
  address,
  latitude,
  longitude,
}: Pick<AddressPanelTabProps, "address" | "latitude" | "longitude">) => {
  const { user, admin } = useContext(AuthContext);
  const { property, loading: loadingPropertyId } =
    useContext(AddressPanelContext);
  const propertyId = property?.id;

  const { data, error, loading, refetch } = useGetInspectionsQuery({
    variables: {
      propertyId: propertyId!,
      submissionCategory: SubmissionCategory.INSPECTIONS,
    },
    fetchPolicy: "cache-and-network",
    skip: loadingPropertyId || !propertyId,
  });

  if (loading || loadingPropertyId) return <em>Loading...</em>;

  if (error || !data?.property) {
    return <em>Problem loading improvements and damage entries.</em>;
  }

  const currentUser = user || admin;
  const canCreateInspection = canCreateInternalSubmission({
    role: currentUser?.role.name,
  });

  const actionButton = (
    <CreateSubmissionButton
      disabled={!canCreateInspection}
      address={address}
      latitude={latitude}
      longitude={longitude}
      propertyId={propertyId!}
      submissionText="inspection"
      submissionTypeFilter={submissionType =>
        submissionType.category === SubmissionCategory.INSPECTIONS &&
        submissionType.intakeSource === SubmissionIntakeSource.INTERNAL
      }
    />
  );

  const inspections: Array<PartialInspection> = [
    ...data.property.submissions.map(submission => ({
      ...submission,
      isPda: false,
      description: `Last edited ${formatDateString({
        format: "MM/DD/YYYY",
        dateString: submission.updatedAt,
      })}`,
    })),
  ];
  if (!inspections.length) {
    return (
      <React.Fragment>
        <Layout title="Inspections" actionButton={actionButton}>
          <EmptyState message="There are no inspections on this property yet" />
        </Layout>
        <Divider margins="24px 0 0 0" />
      </React.Fragment>
    );
  }

  inspections.sort(
    (a: PartialInspection, b: PartialInspection) =>
      new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );

  return (
    <React.Fragment>
      <Layout title="Inspections" actionButton={actionButton}>
        {inspections.map(inspection => (
          <ListItem
            address={address}
            onUpdate={refetch}
            item={inspection}
            key={inspection.id}
            date={inspection.createdAt}
            title={inspection.submissionTypeVersion.submissionType.name}
            showDots={true}
            excluded={false}
          />
        ))}
      </Layout>
    </React.Fragment>
  );
};
