import React, { useContext, useState, useEffect } from "react";
import {
  CONDITIONALS_FOR_FILTER_TYPE,
  FILTER_CONDITIONALS,
  FILTER_TYPE,
} from "common/utils/queryBuilder";
import { AttributeLeaf } from "../FullWidthTable/types";

import Divider from "../Divider";
import { FilterAction } from "./useFilterReducer";
import { Button } from "../Button";
import { FlexColumn, FlexRow } from "../Layout";
import { Datepicker, Text } from "../../Inputs";
import { track } from "../../../utils/tracking";
import { AttributeRow } from ".";
import { TableContext } from "../FullWidthTable/TableContext";

import {
  AttributesWrapper,
  SectionTitleLabel,
  DropdownItem,
} from "./__styles__/Filters";
import { formatDate } from "common/utils/strings";
import { v4 } from "uuid";
import { sortBy, uniqBy } from "lodash";

type OnApplyProps = {
  key: string;
  value?: string;
  label?: string;
};

const Input = ({
  attribute,
  conditional,
  value,
  onChange,
  onApply,
}: {
  attribute: AttributeLeaf;
  conditional?: FILTER_CONDITIONALS;
  value: string | undefined;
  onChange: (value: string) => void;
  onApply: ({ key, value, label }: OnApplyProps) => void;
}) => {
  switch (attribute.type) {
    case FILTER_TYPE.ENUM:
    case FILTER_TYPE.SELECT:
      const options = uniqBy(sortBy(attribute.options!, "label"), "label");
      return (
        <>
          {options.map(option => {
            return (
              <AttributeRow
                attribute={{ label: option.label, icon: attribute.icon }}
                showRightChevron={false}
                onClick={() => {
                  const { key, label, value } = option;
                  onApply({ key, value, label });
                }}
                key={option.value}
              />
            );
          })}
        </>
      );
    case FILTER_TYPE.TEXT:
      return (
        <Text
          value={value}
          name={attribute.key}
          size="smaller"
          placeholder="Enter value"
          onChange={onChange}
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter" && value) {
              onApply({ key: attribute.key, value });
            }
          }}
        />
      );
    case FILTER_TYPE.NUMBER:
      return (
        <Text
          type="number"
          value={value}
          name={attribute.key}
          size="smaller"
          placeholder="Enter value"
          onChange={onChange}
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter" && value) {
              onApply({ key: attribute.key, value });
            }
          }}
        />
      );
    case FILTER_TYPE.DATE:
      if (
        [FILTER_CONDITIONALS.BETWEEN, FILTER_CONDITIONALS.NOT_BETWEEN].includes(
          conditional!
        )
      ) {
        const [fistValue, setFirstValue] = useState("");
        const [secondValue, setSecondValue] = useState("");

        useEffect(() => {
          if (fistValue && secondValue) {
            onChange(`${formatDate(fistValue)} and ${formatDate(secondValue)}`);
          }
        }, [fistValue, secondValue]);

        return (
          <FlexRow style={{ gap: "8px", alignItems: "center" }}>
            <Datepicker
              name={attribute.key}
              value={fistValue}
              onChange={(value: string) => setFirstValue(value)}
              placeholderText="Enter value"
              size="smaller"
            />
            <span>-</span>
            <Datepicker
              name={attribute.key}
              value={secondValue}
              onChange={(value: string) => setSecondValue(value)}
              placeholderText="Enter value"
              size="smaller"
            />
          </FlexRow>
        );
      }

      return (
        <Datepicker
          name={attribute.key}
          value={value ?? ""}
          onChange={(value: string) => onChange(formatDate(value))}
          placeholderText="Enter value"
          size="smaller"
        />
      );
  }
};

const ConditionalSelector = ({
  attribute,
  onSelect,
}: {
  attribute: AttributeLeaf;
  onSelect: (conditional: FILTER_CONDITIONALS) => void;
}) => {
  const conditionals = CONDITIONALS_FOR_FILTER_TYPE[attribute.type];

  return (
    <AttributesWrapper>
      <SectionTitleLabel>Conditionals</SectionTitleLabel>
      {conditionals.map(conditional => {
        return (
          <AttributeRow
            key={conditional}
            onClick={() => {
              onSelect(conditional);
            }}
            attribute={{ label: conditional }}
          />
        );
      })}
    </AttributesWrapper>
  );
};

export const FilterInput = ({
  attribute,
  dispatch,
}: {
  attribute: AttributeLeaf & {
    conditional?: FILTER_CONDITIONALS;
    value?: Maybe<string>;
  };
  dispatch: (value: FilterAction) => void;
}) => {
  const conditional = attribute.conditional;
  const [inputValue, setInputValue] = useState<string>();

  const { name } = useContext(TableContext);

  const onConditionalSelect = (conditional: FILTER_CONDITIONALS) => {
    dispatch({
      type: "setAttribute",
      data: {
        newAttribute: attribute,
        lastAttribute: attribute,
        conditional,
      },
    });

    if (
      [
        FILTER_CONDITIONALS.IS_PRESENT,
        FILTER_CONDITIONALS.IS_NOT_PRESENT,
      ].includes(conditional)
    ) {
      const isPresent = conditional === FILTER_CONDITIONALS.IS_PRESENT;
      const filterId = v4();

      if (attribute.isGroup) {
        dispatch({
          type: "setFilterValue",
          data: {
            key: attribute.key,
            value: attribute.value,
            conditional: isPresent
              ? FILTER_CONDITIONALS.INCLUDES
              : FILTER_CONDITIONALS.EXCLUDES,
            conditionalLabel: conditional,
            filterId,
          },
        });
      } else {
        dispatch({
          type: "setFilterValue",
          data: {
            key: attribute.key,
            value: attribute.value,
            conditional,
            filterId,
          },
        });
      }
    }
  };

  const onApply = ({ key, value, label }: OnApplyProps) => {
    if (!value || !conditional) return;
    const filterId = v4();

    dispatch({
      type: "setFilterValue",
      data: { key, value, label: label ?? value, conditional, filterId },
    });

    track("Applied filter", {
      tableName: name,
      filter: `${key} ${conditional} ${value}`,
    });
  };

  const hasConditional = !!conditional;

  const canOverflow = attribute.type !== FILTER_TYPE.DATE;

  return (
    <DropdownItem style={{ overflowY: canOverflow ? "auto" : "unset" }}>
      {hasConditional ? (
        <FlexColumn style={{ gap: "8px" }}>
          <SectionTitleLabel>{attribute.label}</SectionTitleLabel>
          <Input
            attribute={attribute}
            value={inputValue}
            onChange={setInputValue}
            onApply={onApply}
            conditional={conditional}
          />
          {attribute.type !== FILTER_TYPE.SELECT && (
            <>
              <Divider margins={"8px -16px 8px -16px"} />
              <FlexRow style={{ justifyContent: "flex-end" }}>
                <Button
                  styleVariant="outlineLight"
                  size="small"
                  onClick={() =>
                    onApply({ key: attribute.key, value: inputValue })
                  }
                  disabled={!inputValue}
                >
                  Apply
                </Button>
              </FlexRow>
            </>
          )}
        </FlexColumn>
      ) : (
        <>
          <ConditionalSelector
            attribute={attribute}
            onSelect={onConditionalSelect}
          />
          <AttributesWrapper>
            <Divider margins={"8px -16px 8px -16px"} />
            <AttributeRow
              attribute={{ label: FILTER_CONDITIONALS.IS_PRESENT }}
              showRightChevron={false}
              onClick={() =>
                onConditionalSelect(FILTER_CONDITIONALS.IS_PRESENT)
              }
            />
            <AttributeRow
              attribute={{
                label: FILTER_CONDITIONALS.IS_NOT_PRESENT,
              }}
              showRightChevron={false}
              onClick={() =>
                onConditionalSelect(FILTER_CONDITIONALS.IS_NOT_PRESENT)
              }
            />
          </AttributesWrapper>
        </>
      )}
    </DropdownItem>
  );
};
