import React, { Dispatch, useEffect, useMemo, useRef } from "react";
import { flatten, isUndefined } from "lodash";

import { Button } from "../Button";
import { useOutsideAlerter } from "../../../utils/outsideAlerter";
import Divider from "../Divider";
import {
  FilterAction,
  FilterReducerState,
  useFilterReducer,
} from "./useFilterReducer";
import { FilterInput } from "./Inputs";
import { FlexRow } from "../Layout";
import { Icon } from "../Icons/LucideIcons";
import { Attribute, isAttributeNode } from "../FullWidthTable/types";
import {
  buildFilterDescriptionFromFilterStateObject,
  getAllFilterableAttributes,
} from "./utils";

import {
  FilterContainer,
  DropdownMenuWrapper,
  DropdownItem,
  AttributesWrapper,
  SectionTitleLabel,
  ButtonWrapper,
  FilterCountWrapper,
  AttributeWrapper,
  AttributeLabel,
} from "./__styles__/Filters";
import { FilterDescription } from "common/utils/queryBuilder";
import FilterRows from "./FilterRows";

export const SectionTitle = ({
  label,
  onGoBack,
}: {
  label: string;
  onGoBack?: () => void;
}) => {
  return (
    <AttributeWrapper omitHoverStyles>
      <FlexRow
        style={{ gap: "4px", cursor: onGoBack ? "pointer" : "unset" }}
        onClick={onGoBack}
      >
        {onGoBack && <Icon iconName="chevron-left" color={"grey4"} size={16} />}
        <SectionTitleLabel>{label}</SectionTitleLabel>
      </FlexRow>
    </AttributeWrapper>
  );
};

export const AttributeRow = ({
  attribute,
  onClick,
  showRightChevron = true,
}: {
  attribute: Pick<Attribute, "icon" | "label" | "columnId">;
  onClick: () => void;
  showRightChevron?: boolean;
}) => {
  const { icon, label } = attribute;

  return (
    <AttributeWrapper onClick={onClick}>
      <FlexRow style={{ gap: "4px" }}>
        {icon && <Icon iconName={icon} color={"grey4"} size={16} />}
        {label && (
          <AttributeLabel data-testid={`${attribute.columnId}-filter-select`}>
            {label}
          </AttributeLabel>
        )}
      </FlexRow>
      {showRightChevron && (
        <Icon iconName="chevron-right" color={"grey4"} size={16} />
      )}
    </AttributeWrapper>
  );
};

const AddFilterState = ({
  attributeHistory,
  currentAttribute,
  dispatch,
}: {
  attributeHistory: Array<Attribute>;
  currentAttribute: Attribute;
  dispatch: Dispatch<FilterAction>;
}) => {
  const parentAttributeLabel =
    attributeHistory[attributeHistory.length - 1]?.label;
  const hasConditionalParent = !isUndefined(currentAttribute.conditional);

  let menuTitle = parentAttributeLabel ?? "Filters";

  if (hasConditionalParent) {
    menuTitle = "Conditionals";
  }

  return (
    <>
      <DropdownItem>
        <SectionTitle
          onGoBack={() => dispatch({ type: "goBack" })}
          label={menuTitle}
        />
      </DropdownItem>
      <Divider />
      {isAttributeNode(currentAttribute) ? (
        <DropdownItem>
          <AttributesWrapper>
            <SectionTitleLabel>Attributes</SectionTitleLabel>
            {currentAttribute.attributes
              .filter(attribute => !attribute.omitFromFilters)
              .map((attribute, index) => (
                <AttributeRow
                  attribute={attribute}
                  onClick={() => {
                    dispatch({
                      type: "setAttribute",
                      data: {
                        newAttribute: attribute,
                        lastAttribute: currentAttribute,
                      },
                    });
                  }}
                  key={index}
                />
              ))}
          </AttributesWrapper>
        </DropdownItem>
      ) : (
        <FilterInput attribute={currentAttribute} dispatch={dispatch} />
      )}
    </>
  );
};

const AppliedFiltersState = ({
  filterCount,
  filterState,
  dispatch,
}: {
  filterCount: number;
  filterState: FilterReducerState;
  dispatch: Dispatch<FilterAction>;
}) => {
  return (
    <>
      <DropdownItem>
        {filterCount === 0 ? (
          <SectionTitleLabel>No filter applied</SectionTitleLabel>
        ) : (
          <FilterRows filterState={filterState} dispatch={dispatch} />
        )}
      </DropdownItem>
      <Divider />
      <ButtonWrapper>
        <Button
          data-testid="add-filter-button"
          styleVariant="outlineLight"
          leftIconName="plus"
          size="small"
          onClick={() => dispatch({ type: "addingFilter" })}
        >
          Add filter
        </Button>
        {filterCount > 0 && (
          <Button
            styleVariant="ghostGrey"
            onClick={() => dispatch({ type: "clearFilters" })}
          >
            Clear all
          </Button>
        )}
      </ButtonWrapper>
    </>
  );
};

export const Filter = ({
  config,
  onFilterChange,
  defaultState,
}: {
  config: Array<Attribute>;
  onFilterChange: (filters: Array<FilterDescription>) => void;
  defaultState?: Partial<FilterReducerState>;
}) => {
  const filterConfig = useMemo(
    () => getAllFilterableAttributes(config),
    [config]
  );

  const wrapperRef = useRef(null as null | HTMLDivElement);

  const [filterState, dispatch] = useFilterReducer({
    defaultValue: defaultState,
    initialAttributes: filterConfig,
  });

  const { currentAttribute, attributeHistory, menuOpen, addingFilter } =
    filterState;

  const filterCount = flatten(Object.values(filterState.filterValues)).length;

  const toggleFilterMenu = () => {
    if (menuOpen) {
      dispatch({ type: "closeMenu" });
    } else {
      dispatch({ type: "openMenu" });
    }
  };

  useOutsideAlerter(wrapperRef, () => {
    dispatch({ type: "closeMenu" });
  });

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        dispatch({ type: "closeMenu" });
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const filterDescriptions: Array<FilterDescription> = [];

    for (const [key, filters] of Object.entries(filterState.filterValues)) {
      for (const filter of filters) {
        filterDescriptions.push(
          buildFilterDescriptionFromFilterStateObject({
            field: key,
            filter,
          })
        );
      }
    }

    onFilterChange(filterDescriptions);
  }, [JSON.stringify(filterState.filterValues)]);

  return (
    <FilterContainer ref={wrapperRef}>
      <Button
        onClick={toggleFilterMenu}
        leftIconName="filter"
        styleVariant="outlineLight"
        size="small"
      >
        Filter
        {filterCount > 0 && (
          <FilterCountWrapper data-testid="filter-count">
            {filterCount}
          </FilterCountWrapper>
        )}
      </Button>
      {menuOpen && (
        <DropdownMenuWrapper>
          {addingFilter ? (
            <AddFilterState
              currentAttribute={currentAttribute}
              attributeHistory={attributeHistory}
              dispatch={dispatch}
            />
          ) : (
            <AppliedFiltersState
              filterCount={filterCount}
              dispatch={dispatch}
              filterState={filterState}
            />
          )}
        </DropdownMenuWrapper>
      )}
    </FilterContainer>
  );
};
