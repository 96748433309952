import React from "react";
import { EditableWarning } from "../../../Warnings/WarningList";
import { useModal } from "react-modal-hook";
import Modal from "../../../Common/Modal";
import {
  ButtonSection,
  Container,
  ContentSection,
  FormSection,
  HeaderSection,
  PrimaryButtons,
} from "../../../Common/__styles__/Modal";
import { Button } from "../../../Common/Button";
import { useTogglePropertyWarningDefinitionActiveStateMutation } from "../../../../generated/graphql";
import { track } from "../../../../utils/tracking";
import { useStatusToasts } from "../../../../hooks/useStatusToasts";

type ToggleWarningModalProps = {
  warning: EditableWarning;
  onCancel: () => void;
  onSave: () => void;
};

type ToggleWarningHookProps = Pick<ToggleWarningModalProps, "warning">;

export const useWarningActiveStateModal = ({
  ...props
}: ToggleWarningHookProps) => {
  const [show, hide] = useModal(() => {
    return (
      <Modal onRequestClose={hide}>
        <WarningActiveStateModal onCancel={hide} onSave={hide} {...props} />
      </Modal>
    );
  }, [props]);

  return [show, hide] as const;
};

const WarningActiveStateModal = ({
  warning,
  onCancel,
  onSave,
}: ToggleWarningModalProps) => {
  const { addSuccessToast, addFailureToast } = useStatusToasts();

  const [toggleWarningActiveState] =
    useTogglePropertyWarningDefinitionActiveStateMutation({
      onCompleted: warning => {
        onSave();
        const status = warning.setAccountPropertyWarningDefinitionActiveState
          .isActive
          ? "activated"
          : "inactivated";
        addSuccessToast(`Your warning has been successfully ${status}.`);
        track("Account Property Warning Updated", {
          ...warning.setAccountPropertyWarningDefinitionActiveState,
        });
      },
      onError: () => {
        addFailureToast(
          "Your warning failed to be updated. Please try again. If the problem persists, please email support@withforerunner.com"
        );
      },
      update: (cache, result) => {
        const resultWarning =
          result.data?.setAccountPropertyWarningDefinitionActiveState!;
        cache.modify({
          id: cache.identify(resultWarning),
          fields: {
            isActive() {
              return resultWarning.isActive;
            },
          },
        });
      },
    });

  const handleSave = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    await toggleWarningActiveState({
      variables: {
        id: warning.id,
        isActive: !warning.isActive,
      },
    });
  };

  const newWarningActiveState = warning?.isActive ? "inactive" : "active";

  return (
    <Container overflows>
      <HeaderSection>
        <h1>Make warning {newWarningActiveState}</h1>
      </HeaderSection>
      <FormSection overflows>
        <ContentSection overflows>
          Are you sure you would like to make your <b>"{warning?.title}"</b>{" "}
          warning {newWarningActiveState}
        </ContentSection>
        <ButtonSection>
          <PrimaryButtons>
            <Button styleVariant="secondary" onClick={onCancel} size="medium">
              Cancel
            </Button>
            <Button styleVariant="alert" onClick={handleSave} size="medium">
              Yes, make {newWarningActiveState}
            </Button>
          </PrimaryButtons>
        </ButtonSection>
      </FormSection>
    </Container>
  );
};

export default WarningActiveStateModal;
