import React, { useContext } from "react";
import { FilterDescription, TABLE_NAMES } from "common/utils/queryBuilder";
import { NetworkStatus } from "@apollo/client";
import { formatAddressFromAddressParts } from "common/utils/address";
import { RESOURCE_NAME } from "common/authorization";
import { buildFullLink, buildLink, pipLink } from "common/routing";
import { ColumnDef } from "@tanstack/table-core/build/lib/types";

import {
  GetQueryPropertiesQueryVariables,
  useGetPropertyColumnConfigQuery,
  useGetQueryPropertiesLazyQuery,
} from "../../generated/graphql";
import { AuthContext } from "../Authorization/AuthContext";
import {
  useManualPaginationConfig,
  useQueryDescription,
} from "../Common/Tables/hooks";
import {
  FullWidthTable,
  splitTableAndColumnNames,
} from "../Common/FullWidthTable/FullWidthTable";
import { QueryPropertyTableResult } from "./__queries__/table";
import { ActionsProps } from "../Inputs/DropdownMenu";
import { useDeletePropertyModal } from "../AddressPanel/Header/DeletePropertyModal";
import { ACTION_COLUMN_DEF_CONSTANTS, ActionCell } from "../Common/ActionCell";
import { useLocation } from "react-router";
import { useLocalTableDisplayConfig } from "../../hooks/useTableDisplayConfig";
import { sanityCheckLocalColumnOrder } from "../Common/FullWidthTable/utils";
import { ExpandableCell } from "../Common/ExpandableCell";
import { buildResidentPropertyURL } from "common/utils/url";
import LinkCell from "../Common/LinkCell";
import { formatDocumentUploadName } from "common-client/utils/documentUploads";
import { Attribute } from "../Common/FullWidthTable/types";
import { CellRenderConfig } from "../Common/Tables/utils";

const DEFAULT_QUERY_DESCRIPTION = {
  table: TABLE_NAMES.PROPERTIES,
  fields: [
    { table: TABLE_NAMES.PROPERTIES, name: "id" },
    { table: TABLE_NAMES.PROPERTIES, name: "streetAddress" },
    { table: TABLE_NAMES.PROPERTIES, name: "city" },
    { table: TABLE_NAMES.PARCELS, name: "parcelId" },
    { table: TABLE_NAMES.PROPERTIES, name: "warnings" },
  ],
  filters: [],
  orderBy: {
    table: TABLE_NAMES.PROPERTIES,
    field: "streetAddress",
    direction: "ASC" as const,
  },
};

const DEFAULT_COLUMN_DEFINITION_IDS = [
  "Properties.streetAddress",
  "Properties.city",
  "Parcels.parcelId",
  "Properties.warnings",
  "Properties.files",
  "actions",
];

const propertyLink = ({ admin }: { admin: boolean }) => {
  return {
    cell: ({ row }: { row: { original: QueryPropertyTableResult } }) => {
      const { account } = useContext(AuthContext);
      const props = admin
        ? row.original["Properties.adminLink"]
        : row.original["Properties.userLink"];

      if (!props) {
        return null;
      }

      const { latitude, longitude, propertyId, streetAddress } = props;

      const url = buildFullLink(
        "map",
        {
          params: { accountId: account?.id! },
          queryParams: {
            propertyId,
            lat: latitude,
            lng: longitude,
            address: streetAddress,
          },
          prefix: window.env.APPLICATION_URL,
        },
        { admin, accountId: account?.id! }
      );

      return <LinkCell href={url} label={streetAddress} />;
    },
  };
};

export const CUSTOM_PROPERTY_LINK_CELLS = {
  "Properties.adminLink": propertyLink({ admin: true }),
  "Properties.publicLink": {
    cell: ({ row }: { row: { original: QueryPropertyTableResult } }) => {
      const linkProps = row.original["Properties.publicLink"];
      const address = row.original["Properties.streetAddress"];

      if (!linkProps) {
        return null;
      }

      const url = buildResidentPropertyURL({
        subdomain: linkProps.subdomain,
        protocol: window.location.protocol,
        appDomain: `${window.env.APPLICATION_DOMAIN}`,
        propertyId: linkProps.propertyId,
      });

      return <LinkCell href={url} target={"_blank"} label={address} />;
    },
  },
  "Properties.userLink": propertyLink({ admin: false }),
};

const CUSTOM_TABLE_CELLS: Record<string, CellRenderConfig> = {
  "Properties.warnings": {
    cell: ({ row }) => {
      const pills = row.original["Properties.warnings"]?.map(
        (w: { title: string }) => ({
          label: w.title,
          iconName: "alert-circle",
        })
      );

      return <ExpandableCell pills={pills} />;
    },
  },
  "Properties.files": {
    cell: ({ row }) => {
      const { authorized } = useContext(AuthContext);
      const canViewDocumentDetail = authorized({
        resource: RESOURCE_NAME.DOCUMENT_UPLOAD,
        permission: "view_details",
      });

      const pills = row.original["Properties.files"]?.map(
        (f: {
          issuedAt: string;
          createdAt: string;
          type: string;
          id: string;
        }) => {
          let href,
            to = null;

          if (canViewDocumentDetail) {
            to = buildLink("documentUploadDetail", {
              id: f.id,
            });
          } else {
            href = buildLink("documentUploadFile", { id: f.id });
          }

          return {
            label: formatDocumentUploadName({
              createdAt: f.createdAt,
              issuedAt: f.issuedAt,
              accountDocumentTypeName: f.type,
            }),
            iconName: "file-text",
            href,
            to,
          };
        }
      );

      return <ExpandableCell pills={pills} />;
    },
  },
  ...CUSTOM_PROPERTY_LINK_CELLS,
};

const PropertyActions = ({
  propertyData,
  onDelete,
}: {
  propertyData: QueryPropertyTableResult;
  onDelete: () => void;
}) => {
  const id = propertyData["Properties.id"];
  const streetAddress = propertyData["Properties.streetAddress"];
  const city = propertyData["Properties.city"];
  const latitude = propertyData["Properties.latitude"];
  const longitude = propertyData["Properties.longitude"];

  const { authorized } = useContext(AuthContext);
  const [showDeletePropertyModal, hideDeletePropertyModal] =
    useDeletePropertyModal({
      property: {
        id,
        streetAddress,
        city,
        fullAddress: formatAddressFromAddressParts({
          streetAddress,
          city,
          state: propertyData["Properties.state"],
          zipcode: propertyData["Properties.zipcode"],
        }),
        latitude,
        longitude,
      },
      updateMap: () => {},
      closeModal: () => {
        hideDeletePropertyModal();
      },
      onDelete: () => {
        hideDeletePropertyModal();
        onDelete();
      },
    });

  const actions: Array<ActionsProps> = [
    {
      label: "View",
      to: pipLink({
        lat: latitude?.toString() ?? "",
        lng: longitude?.toString() ?? "",
        address: streetAddress,
        propertyId: id,
      }),
    },
    {
      label: "Delete",
      onClick: () => {
        showDeletePropertyModal();
      },
      variant: "red",
      disabled: !authorized({
        resource: RESOURCE_NAME.PROPERTY,
        permission: "delete",
      }),
    },
  ];

  return <ActionCell actions={actions} />;
};

export const PropertiesTable = () => {
  const { user, admin } = useContext(AuthContext);

  const { data: columnConfigResponse, loading: loadingColumnConfig } =
    useGetPropertyColumnConfigQuery({ fetchPolicy: "network-only" });

  const [
    queryProperties,
    { previousData, data: currentData, networkStatus, loading, error, refetch },
  ] = useGetQueryPropertiesLazyQuery({
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const actionsColumn: ColumnDef<QueryPropertyTableResult> = {
    ...ACTION_COLUMN_DEF_CONSTANTS,
    cell: ({ row }) => {
      const property = row.original;
      return <PropertyActions propertyData={property} onDelete={refetch} />;
    },
  };

  const {
    initialTableState,
    queryDescription,
    updateQueryDescription,
    setQueryDescriptionInURL,
    defaultColumnDefinitions,
    generatedColumnDefinitions,
  } = useQueryDescription<QueryPropertyTableResult>({
    initialQueryDescription: DEFAULT_QUERY_DESCRIPTION,
    defaultColumnIds: DEFAULT_COLUMN_DEFINITION_IDS,
    tableConfig: columnConfigResponse?.getPropertyTableConfig.data || [],
    customTableCells: CUSTOM_TABLE_CELLS,
    actionsColumn,
    defaultSort: [{ id: "Properties.streetAddress", desc: false }],
  });

  const { pathname } = useLocation();

  const tableId = `${user?.id ?? admin?.id}-${pathname.replace("/", "")}`;
  const { getLocalTableState, setLocalColumnOrder } =
    useLocalTableDisplayConfig({
      tableId,
      defaultValue: {
        columnOrder: defaultColumnDefinitions.map(column => column.id!),
        columnSizing: {},
        sorting: [],
      },
    });

  const manualPaginationConfig = useManualPaginationConfig({
    ...initialTableState.pagination,
    currentTotalPages: currentData?.queryProperties.pageInfo.totalPages,
    previousTotalPages: previousData?.queryProperties.pageInfo.totalPages,
  });

  const tanstackColumnDefinitions: Array<ColumnDef<QueryPropertyTableResult>> =
    [...generatedColumnDefinitions, actionsColumn];

  const data =
    networkStatus === NetworkStatus.setVariables ? previousData : currentData;

  if (
    loadingColumnConfig ||
    !columnConfigResponse?.getPropertyTableConfig.data
  ) {
    return null;
  }

  const loadingDetails = {
    loading,
    loadingText: "Loading properties",
    noDataText: "No properties found",
  };

  const search = ({
    page,
    columns,
  }: {
    page: number;
    columns: Array<ColumnDef<QueryPropertyTableResult>>;
  }) => {
    const visibleColumns = columns.filter(c => c.id?.includes("."));
    const visibleFields = visibleColumns.map(c => {
      const { field, table } = splitTableAndColumnNames(c.id!);
      return {
        table,
        name: field,
      };
    });
    const variables: GetQueryPropertiesQueryVariables = {
      description: {
        table: TABLE_NAMES.PROPERTIES,
        fields: [
          ...visibleFields,
          //we need to always include these fields to generate PIP links in the action cell
          { table: TABLE_NAMES.PROPERTIES, name: "id" },
          { table: TABLE_NAMES.PROPERTIES, name: "latitude" },
          { table: TABLE_NAMES.PROPERTIES, name: "longitude" },
          { table: TABLE_NAMES.PROPERTIES, name: "zipcode" },
        ],
        orderBy: queryDescription.orderBy,
        filters: queryDescription.filters,
      },
      page,
    };

    void queryProperties({
      variables,
    });
  };

  const localTableConfig = getLocalTableState();

  const { columnOrder: localColumnOrder } = localTableConfig;

  const updatedColumnOrder = sanityCheckLocalColumnOrder({
    localColumnOrder,
    defaultColumns: defaultColumnDefinitions,
  });

  if (updatedColumnOrder) {
    setLocalColumnOrder(updatedColumnOrder);
  }

  const initialColumns = updatedColumnOrder
    ? updatedColumnOrder.map(
        id => defaultColumnDefinitions.find(c => c.id === id)!
      )
    : defaultColumnDefinitions;

  const timeoutError = error?.graphQLErrors.find(
    e => e.extensions.code === "TIMEOUT_ERROR"
  );

  return (
    <FullWidthTable<QueryPropertyTableResult, Array<FilterDescription>>
      columns={initialColumns}
      previousData={previousData?.queryProperties.data}
      currentData={data?.queryProperties.data ?? []}
      loadingDetails={loadingDetails}
      tableStyleDetails={{ hasHighlights: true, hasRowActions: true }}
      manualPaginationConfig={{
        ...manualPaginationConfig,
        pageCount: data?.queryProperties.pageInfo.totalPages ?? 1,
      }}
      initialState={initialTableState}
      filterable={{
        newFilterConfiguration: columnConfigResponse.getPropertyTableConfig
          .data as Array<Attribute>,
        search,
      }}
      columnSettingProps={{
        columnConfiguration: columnConfigResponse.getPropertyTableConfig.data,
        columnDefinitions: tanstackColumnDefinitions,
      }}
      timeoutError={timeoutError}
      queryDescription={queryDescription}
      updateQueryDescription={updateQueryDescription}
      setQueryDescriptionInURL={setQueryDescriptionInURL}
    />
  );
};
