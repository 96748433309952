import {
  FILTER_CONDITIONAL_TO_QUERY_OPERATOR,
  FILTER_CONDITIONALS,
  FilterDescription,
  OPERATOR_TO_CONDITIONAL,
  QueryDescription,
  TABLE_NAMES,
} from "common/utils/queryBuilder";
import {
  Attribute,
  isAttributeLeaf,
  isAttributeNode,
} from "../FullWidthTable/types";
import {
  buildFilterSegmentForFilter,
  FilterReducerState,
} from "./useFilterReducer";
import { Account } from "../../Authorization/types";
import { v4 as uuid } from "uuid";
import { formatBuildingElevationSource } from "common/utils/strings";
import { CertificateUploadStatus } from "../../../generated/graphql";
import { UserFriendlyCertificateStatus } from "common/constants";

type ValueHelperArgs = {
  account: Maybe<Account>;
  value: Maybe<string | number>;
  lastElement: Attribute;
};
const FILTER_STATE_VALUE_HELPERS: Record<
  string,
  (args: ValueHelperArgs) => Maybe<string | number>
> = {
  "Properties.warnings": ({ account, value }: ValueHelperArgs) => {
    if (!account) return null;

    return (
      account.accountPropertyWarningDefinitions.find(apwd => apwd.id === value)
        ?.title ?? null
    );
  },

  "Files.issues": ({ value, lastElement }: ValueHelperArgs) => {
    return (
      lastElement.options?.find(option => option.value === value)?.label ?? null
    );
  },

  "Certificates.buildingElevationSource": ({ value }: ValueHelperArgs) => {
    return formatBuildingElevationSource(value as string);
  },

  "CertificateUploads.status": ({ value }: ValueHelperArgs) => {
    return UserFriendlyCertificateStatus[value as CertificateUploadStatus];
  },
};

export const getAllFilterableAttributes = (
  columnConfig: Array<Attribute>
): Array<Attribute> => {
  const filterableAttributes = columnConfig.reduce<Array<Attribute>>(
    (acc, attribute) => {
      if (attribute.omitFromFilters) {
        return acc;
      } else if ("attributes" in attribute) {
        return [
          ...acc,
          {
            ...attribute,
            attributes: getAllFilterableAttributes(attribute.attributes),
          },
        ];
      } else {
        return [...acc, attribute];
      }
    },
    []
  );

  return filterableAttributes;
};

export const queryDescriptionToFilterState = ({
  queryDescription,
  filterConfig,
  account,
}: {
  queryDescription: QueryDescription;
  filterConfig: Array<Attribute>;
  account: Maybe<Account>;
}) => {
  const filterState: Partial<FilterReducerState> = { filterValues: {} };

  (queryDescription.filters || []).forEach(filter => {
    const key = `${filter.table}.${filter.field}`;
    const attributeHistory =
      findPathByColumnId(filterConfig, key, filter.value) ?? [];

    const lastElement = [...attributeHistory].pop()!;

    const isEnumField =
      isAttributeLeaf(lastElement) && lastElement.type === "enum";

    const isDateField =
      isAttributeLeaf(lastElement) && lastElement.type === "date";

    const isGroupField =
      "isGroup" in lastElement && lastElement.isGroup === true;

    const uniqueId = uuid();

    const presentationalValue =
      FILTER_STATE_VALUE_HELPERS[key]?.({
        account,
        value: filter.value,
        lastElement,
      }) ?? (isGroupField && !isEnumField ? null : filter.value);

    if (filterState.filterValues?.[key]) {
      filterState.filterValues[key]!.push(
        buildFilterForFilterState({
          filter,
          uniqueId,
          presentationalValue: presentationalValue?.toString() ?? null,
          key,
          filterConfig,
          isDateField,
        })
      );
    } else {
      filterState.filterValues = {
        ...filterState.filterValues,
        [key]: [
          buildFilterForFilterState({
            filter,
            uniqueId,
            presentationalValue: presentationalValue?.toString() ?? null,
            key,
            filterConfig,
            isDateField,
          }),
        ],
      };
    }
  });

  return filterState;
};

const buildFilterForFilterState = ({
  filter,
  uniqueId,
  presentationalValue,
  key,
  filterConfig,
  isDateField,
}: {
  filter: Omit<FilterDescription, "value"> & {
    value: any;
  };
  uniqueId: string;
  presentationalValue: Maybe<string>;
  key: string;
  filterConfig: Array<Attribute>;
  isDateField?: boolean;
}) => {
  let conditional = OPERATOR_TO_CONDITIONAL[filter.operator];

  //we need to convert greater than and less than date operators to the correct display conditionals
  if (isDateField) {
    if (conditional === FILTER_CONDITIONALS.IS_GREATHER_THAN) {
      conditional = FILTER_CONDITIONALS.AFTER;
    }
    if (conditional === FILTER_CONDITIONALS.IS_LESS_THAN) {
      conditional = FILTER_CONDITIONALS.BEFORE;
    }
  }

  return {
    value: filter.value,
    conditional: OPERATOR_TO_CONDITIONAL[filter.operator],
    uniqueId,
    filterSegment: buildFilterSegmentForFilter({
      attributeHistory:
        findPathByColumnId(filterConfig, key, filter.value) ?? [],
      conditional,
      label: presentationalValue?.toString(),
      context: { key, uniqueId },
    }),
  };
};

const findPathByColumnId = (
  data: Array<Attribute>,
  columnId: string,
  value: Maybe<string | number>,
  path: Array<Attribute> = []
): Maybe<Array<Attribute>> => {
  for (const item of data) {
    const currentPath = [...path, item];

    if (
      item.columnId === columnId ||
      // This narrows down options nodes to the correct value.
      // They'll be a leaf associated with a column, but there will be many of them, where the value is the tiebreaker
      (isAttributeLeaf(item) && item.key === columnId && value === item.value)
    ) {
      return currentPath;
    }

    if (isAttributeNode(item)) {
      const result = findPathByColumnId(
        item.attributes,
        columnId,
        value,
        currentPath
      );
      if (result) return result;
    }
  }
  return null;
};

export const buildFilterDescriptionFromFilterStateObject = ({
  field,
  filter,
}: {
  field: string;
  filter: { value: Maybe<string>; conditional: FILTER_CONDITIONALS };
}): FilterDescription => {
  const splitField = field.split(".");

  return {
    table: splitField[0] as TABLE_NAMES,
    field: splitField[1]!,
    operator: FILTER_CONDITIONAL_TO_QUERY_OPERATOR[filter.conditional],
    value: filter.value,
  };
};
