import React, { useContext, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { ParcelImportWizardContext } from "./context";
import {
  AvailableField,
  GpkgImportWizardFormData,
  PARCEL_IMPORT_CONFIG_WORKFLOW,
} from "./types";
import {
  useGeopackageUpload,
  useParcelImportConfigDefaultValues,
  useParcelImportFormSubmission,
} from "./hooks";
import ParcelImportWizard from "./ParcelImportWizard";
import GpkgParcelImportConfigStage from "./GpkgParcelImportConfigStage";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router";
import { isEmpty } from "lodash";

const FAILED_URL_REQUEST_MESSAGE =
  "Something went wrong while uploading the provided gpkg.";

const GpkgParcelImportWizard = () => {
  const history = useHistory();

  const { setFieldOptions, workflow } = useContext(ParcelImportWizardContext);

  const { addToast } = useToasts();

  const [fetchDefaultValues] =
    useParcelImportConfigDefaultValues<GpkgImportWizardFormData>({
      workflow,
    });

  const [submitForm] = useParcelImportFormSubmission();

  const formMethods = useForm<GpkgImportWizardFormData>({
    // for cases where we need to retrieve default values from
    // the server, react-hook-form allows "defaultValues" to
    // be an async function that returns a promise. Otherwise
    // everything would be "undefined" as a default value
    defaultValues: fetchDefaultValues,
    // this is set to "onChange" because we want to disable the
    // "Next", or submission button, if the form is invalid
    mode: "onChange",
  });

  // destructuring form methods and form state properties directly
  // acts as a way to "subscribe" to when these properties change
  const {
    formState: {
      isLoading: formIsLoading,
      isValid,
      isSubmitSuccessful,
      isSubmitting,
      dirtyFields,
    },
    getValues,
    handleSubmit,
    setError,
    setValue,
  } = formMethods;

  const [uploadGpkgFile, { loading: isUploading }] = useGeopackageUpload();

  const loading = formIsLoading || isUploading || isSubmitting;
  const enableNextBtn = isValid && !loading && !isSubmitSuccessful;

  const onFirstStageNext = async (changeStage: () => void) => {
    const file = getValues("geopackageUpload.blob");

    uploadGpkgFile({
      file,
      onCompleted: ({ availableFields, parcelModelFields, s3Key }) => {
        setValue("s3Key", s3Key);
        setFieldOptions({ availableFields, parcelModelFields });
        changeStage();
      },
      onError: e => {
        setError("geopackageUpload", {
          type: "custom",
          message: e instanceof Error ? e.message : FAILED_URL_REQUEST_MESSAGE,
        });
      },
    });
  };

  const onLastStageFinish = async () => {
    await handleSubmit(async formData => {
      await submitForm({
        dirtyFields,
        formData,
        onCompleted: () => {
          history.push("/settings/data-imports/parcels");
          addToast("Parcel source successfully updated.", {
            appearance: "success",
            autoDismiss: true,
          });
        },
        onError: () => {
          addToast("Parcel source failed to update.", {
            appearance: "error",
            autoDismiss: true,
          });
        },
      });
    })();
  };

  const isDisplaySettingsWorkflow =
    workflow === PARCEL_IMPORT_CONFIG_WORKFLOW.DISPLAY_SETTINGS;

  // This effect is used to update the available fields when the form
  // is done loading and if the user is editing a GPKG configuration.
  // This is necessary because the available fields are not retrieved
  // unless a GPKG file is uploaded, and we need available fields in
  // order to render the source field input.
  useEffect(() => {
    if (isDisplaySettingsWorkflow) {
      const formValues = getValues();
      const availableFields = [
        ...Object.values(isEmpty(formValues.mapping) ? {} : formValues.mapping),
        ...(Array.isArray(formValues.additionalFields)
          ? formValues.additionalFields
          : []),
      ].reduce((result, { source }) => {
        if (Array.isArray(source)) {
          source.map(s => result.push({ name: s }));
        } else {
          result.push({ name: source });
        }

        return result;
      }, [] as AvailableField[]);

      setFieldOptions({
        availableFields,
        parcelModelFields: [],
      });
    }
  }, [formIsLoading]);

  return (
    <FormProvider {...formMethods}>
      <ParcelImportWizard
        firstStageRender={() => <GpkgParcelImportConfigStage />}
        isStageComplete={() => enableNextBtn}
        loading={loading}
        onFirstStageNext={onFirstStageNext}
        onLastStageFinish={onLastStageFinish}
        skipFirstStage={isDisplaySettingsWorkflow}
        subtitle={
          isDisplaySettingsWorkflow
            ? "Update mapped field display options"
            : "Import geopackage file"
        }
      />
    </FormProvider>
  );
};

export default GpkgParcelImportWizard;
