import React, { useCallback } from "react";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router";

import {
  useEditPropertyMutation,
  useGetEditPropertyQuery,
} from "../../generated/graphql";
import Icon, { ICON_COLORS, Icons } from "../Common/Icons";
import PropertyForm, { ActionProps, FormData } from "./PropertyForm";
import EditPropertyWarnings from "./EditPropertyWarnings";
import { Button } from "../Common/Button";
import { useStatusToasts } from "../../hooks/useStatusToasts";

const EditProperty = () => {
  const location = useLocation<undefined | { prevLocation?: string }>();
  const history = useHistory();
  const { addSuccessToast, addFailureToast } = useStatusToasts();
  const params = queryString.parse(location.search);
  const propertyId = params.propertyId as string;
  if (!propertyId) return null;

  const { data, loading, error } = useGetEditPropertyQuery({
    variables: { propertyId },
  });

  const [editProperty, { error: updateError, loading: loadingEdit }] =
    useEditPropertyMutation({
      onCompleted: data => {
        const property = data.updateProperty;
        addSuccessToast(`Property edited at ${property.streetAddress}`);
        const query = {
          address: property.standardizedAddress,
          lat: property.latitude,
          lng: property.longitude,
          propertyId: property.id,
          parcelId: property.geocacheParcelId,
        };

        history.replace({
          pathname: "/map",
          search: `?${queryString.stringify(query)}`,
        });
      },
      onError: error => {
        const errorMessage = error.graphQLErrors
          .map(error => error.message)
          .join("\n");
        addFailureToast(errorMessage);
      },
    });

  const onSave = useCallback(async (data: FormData) => {
    const newData = {
      ...data,
      address: data.address ?? null,
      id: propertyId,
      ignoreParcel: data.geocacheParcelId === null,
    };

    await editProperty({ variables: { data: newData } });
  }, []);

  if (loading)
    return (
      <div style={{ textAlign: "center" }}>
        <Icon icon={Icons.LOADING} color={ICON_COLORS.LIGHT_GREY} />
      </div>
    );

  if (error || !data?.property) return <em>Problem loading edit form.</em>;

  const property = data.property;
  const startingParcel = property.parcel ?? null;

  const startingPoint =
    property.latitude && property.longitude
      ? { latitude: property.latitude, longitude: property.longitude }
      : null;

  const actions = (props: ActionProps): JSX.Element => {
    return (
      <Button
        size="small"
        styleVariant="primary"
        onClick={() => props.validateInput(onSave)}
        disabled={props.disabled || loadingEdit}
      >
        Save
      </Button>
    );
  };

  return (
    <PropertyForm
      headerText="Edit property details"
      title="Edit property details"
      subtitle="Please ensure that the property details below are correct."
      property={property}
      point={startingPoint}
      parcel={startingParcel}
      zoom={null}
      actions={actions}
      error={updateError?.message ?? null}
      warningsComponent={props => {
        return (
          <EditPropertyWarnings
            {...props}
            header={"Property Warnings"}
            showNone={true}
          />
        );
      }}
      canSubmitProperty={true}
      editingProperty={true}
    />
  );
};

export default EditProperty;
