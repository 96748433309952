import React from "react";
import { useFormContext } from "react-hook-form";
import { JsonEditor } from "json-edit-react";
import { generateCustomNodeDefinitions } from "../../../../../AdminApp/AccountsApp/BackofficeApp/BuildSubmissionType/NodeDefinitions";
import { SubmissionsBuilderFormDataStructure } from "../types";

const accountNodeDefinitions = generateCustomNodeDefinitions({
  accountDocumentTypes: [],
});

const FormJSONPreview = () => {
  const { getValues, reset } =
    useFormContext<SubmissionsBuilderFormDataStructure>();

  const formValues = getValues();

  return (
    <div>
      <JsonEditor
        maxWidth={"100%"}
        data={formValues}
        customNodeDefinitions={accountNodeDefinitions}
        onUpdate={({ newData }) => {
          reset(newData);
        }}
      />
    </div>
  );
};

export default FormJSONPreview;
