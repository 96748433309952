import React, { useContext, useEffect } from "react";
import { BuilderMainContentWrapper } from "../__styles__/SubmissionsBuilder";
import { useFormContext } from "react-hook-form";
import {
  InputFieldPath,
  SubmissionsBuilderField,
  SubmissionsBuilderFormDataStructure,
} from "../types";
import { decorateAndGenerateFormStructure } from "common/services/formBuilderService";
import Form from "@rjsf/core";
import { RegistryFieldsType, RegistryWidgetsType } from "@rjsf/utils";
import TextWidgetPreview from "./FieldPreviews/TextWidgetPreview";
import TextareaWidgetPreview from "./FieldPreviews/TextareaWidgetPreview";

import {
  DescriptionFieldTemplate,
  TitleFieldTemplate,
} from "../../../../../Submissions/Templates";
import NumberFieldPreview from "./FieldPreviews/NumberFieldPreview";
import DateWidgetPreview from "./FieldPreviews/DateWidgetPreview";
import CheckboxWidgetPreview from "./FieldPreviews/CheckboxWidgetPreview";
import ObjectFieldTemplatePreview from "./FieldPreviews/ObjectFieldTemplatePreview";
import { SubmissionsBuilderContextInstance } from "../context";
import { FieldContext } from "../reducer";
import { DescriptionContainer } from "../../../../../Submissions/__styles__/CreateSubmission";
import PropertyMarketValueFieldPreview from "./FieldPreviews/PropertyMarketValueFieldPreview";
import { SubmissionFormContext } from "../../../../../Submissions/Form";
import { formatDateString } from "common/utils/dates";
import { EmptyState } from "../../../../../Common/EmptyState";
import DamageDegreeWidgetPreview from "./FieldPreviews/DamageDegreeWidgetPreview";
import DocumentUploadFieldPreview from "./FieldPreviews/DocumentUploadFieldPreview";
import { Header } from "../../../../../Common/__styles__/FullPageFormLayout";
import SelectWidgetPreview from "./FieldPreviews/SelectWidgetPreview";
import TagFieldPreview from "./FieldPreviews/TagFieldPreview";
import NumberWithUnitsFieldPreview from "./FieldPreviews/NumberWithUnitsFieldPreview";
import { arrayHasAtLeastOneItem } from "common/utils/tools";
import useFieldActionsPropsUpdater from "./useFieldActionsPropsUpdater";

const builderWidgets: RegistryWidgetsType = {
  TextWidget: TextWidgetPreview,
  TextareaWidget: TextareaWidgetPreview,
  DateWidget: DateWidgetPreview,
  CheckboxWidget: CheckboxWidgetPreview,
  DamageDegree: DamageDegreeWidgetPreview,
  SelectWidget: SelectWidgetPreview,
};

const builderFields: RegistryFieldsType = {
  NumberField: NumberFieldPreview,
  NumberWithUnits: NumberWithUnitsFieldPreview,
  PropertyMarketValue: PropertyMarketValueFieldPreview,
  DocumentUploader: DocumentUploadFieldPreview,
  Tags: TagFieldPreview,
};

const builderTemplates = {
  TitleFieldTemplate: TitleFieldTemplate,
  DescriptionFieldTemplate: DescriptionFieldTemplate,
  ObjectFieldTemplate: ObjectFieldTemplatePreview,
};

const EmptyForm = ({
  message = "Add a field to your form!",
}: {
  message?: string;
}) => {
  const formMethods = useFormContext<SubmissionsBuilderFormDataStructure>();
  useFieldActionsPropsUpdater(formMethods, "inputs");

  return (
    <div style={{ marginTop: "128px" }}>
      <EmptyState message={message} />
    </div>
  );
};

const FormWrapper = ({ inputs }: { inputs: SubmissionsBuilderField[] }) => {
  useFieldPathBuilder(inputs);

  let schema,
    uiSchema = {};

  try {
    ({ schema, uiSchema } = decorateAndGenerateFormStructure({
      formData: inputs,
    }));
  } catch (e) {
    return (
      <div style={{ marginTop: "128px" }}>
        <EmptyState
          message={
            "Something went wrong with generating the form structure. Review the Form Structure JSON or contact support."
          }
        />
      </div>
    );
  }

  return (
    <Form
      fields={builderFields}
      formData={{}}
      formContext={{}}
      noHtml5Validate={true}
      onChange={() => {}}
      onError={() => {}}
      onSubmit={() => {}}
      schema={schema}
      showErrorList={false}
      templates={builderTemplates}
      uiSchema={{
        ...uiSchema,
        // This is a hack to prevent the submit button from rendering
        "ui:submitButtonOptions": { norender: true },
      }}
      widgets={builderWidgets}
      validator={{
        isValid: () => true,
        toErrorList: () => [],
        rawValidation: () => ({}),
        validateFormData: () => ({
          errors: [],
          errorSchema: {},
        }),
      }}
    />
  );
};

const useFieldPathBuilder = (formData: SubmissionsBuilderField[]) => {
  const { updateFieldContextsByNameMap } = useContext(
    SubmissionsBuilderContextInstance
  );

  useEffect(() => {
    const fieldPathsByNames: Record<string, FieldContext> = {};

    const recurse = (
      currentObj: SubmissionsBuilderField[],
      currentPath: string = "inputs"
    ) => {
      currentObj.forEach((child, index) => {
        const childPath = `${currentPath}.${index}` as InputFieldPath;

        fieldPathsByNames[child.name] = {
          fieldIndex: index,
          fieldPath: childPath,
        };

        if (Array.isArray(child.properties) && child.properties.length > 0) {
          recurse(child.properties, `${childPath}.properties`);
        }
      });
    };

    recurse(formData);

    updateFieldContextsByNameMap(fieldPathsByNames);
  }, [JSON.stringify(formData), updateFieldContextsByNameMap]);
};

export const MainColumn = () => {
  const { watch } = useFormContext<SubmissionsBuilderFormDataStructure>();

  const { inputs, name, description } = watch();

  return (
    <SubmissionFormContext.Provider
      value={{
        editing: false,
        documentUploads: null,
        defaultValues: {
          units: {
            feet: "feet",
            inches: "inches",
            hours: "hours",
            minutes: "minutes",
            days: "days",
          } as const,
          boolean: { true: true, false: false } as const,
          date: {
            today: formatDateString({
              dateString: new Date().toDateString(),
              format: "YYYY-MM-DD",
            }),
          },
          property: {
            improvementValue: 1000,
            sisd: {
              adjustmentRatio: 0.5,
            },
          },
        },
      }}
    >
      <BuilderMainContentWrapper>
        <Header style={{ marginBottom: "24px" }}>
          <h1 style={{ marginLeft: "17px" }}>{name}</h1>
          {description && <DescriptionContainer markdown={description} />}
        </Header>
        {arrayHasAtLeastOneItem(inputs) ? (
          <FormWrapper inputs={inputs} />
        ) : (
          <EmptyForm />
        )}
      </BuilderMainContentWrapper>
    </SubmissionFormContext.Provider>
  );
};
