import React from "react";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";

import { Button } from "../../Common/Button";
import FullPageBackgroundWithLogo from "../../Common/FullPageBackgroundWithLogo";
import { Text } from "../../Inputs/react-hook-form";
import { useRequestPasswordResetMutation } from "../../../generated/graphql";

import {
  ButtonRow,
  Container,
  Title,
} from "../../Common/__styles__/FormWithFullPageBackground";
import { BannerMessage } from "../__styles__/LoginBannerMessage";
import { WEB_REQUIRED_PASSWORD_RESET_MESSAGE } from "common/constants";
import { useLocation } from "react-router-dom";
import { isValidEmail } from "common/utils/strings";

const SuccessMessage = () => {
  return (
    <Container>
      <Title>Success!</Title>
      <p>
        If the account with the provided email exists we will email you
        instructions for reseting your password.
      </p>
    </Container>
  );
};

interface FormStructure {
  email: string;
}

export default () => {
  const { addToast } = useToasts();
  const location = useLocation();

  const [completed, setCompleted] = React.useState(false);

  const [requestReset, { loading }] = useRequestPasswordResetMutation({
    onCompleted: () => {
      setCompleted(true);
    },
    onError: error => {
      if (error.graphQLErrors[0]?.extensions?.code === "USER_ERROR") {
        addToast(error.message, {
          appearance: "error",
        });
      } else {
        addToast(
          "There was an issue requesting a password reset. Please try again. If the problem persists, please email us at support@withforerunner.com",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormStructure>({});

  const queryParams = new URLSearchParams(location.search);
  const showResetRequiredMessage = queryParams.get("resetRequired") === "true";

  return (
    <FullPageBackgroundWithLogo>
      {completed ? (
        <SuccessMessage />
      ) : (
        <form
          onSubmit={handleSubmit(async userProvidedData => {
            await requestReset({
              variables: { email: userProvidedData.email },
            });
          })}
        >
          <Container>
            <Title>Reset Password</Title>
            <div>
              {showResetRequiredMessage && (
                <BannerMessage style={{ marginBottom: "12px" }}>
                  {WEB_REQUIRED_PASSWORD_RESET_MESSAGE}
                </BannerMessage>
              )}
              <p style={{ marginTop: 0 }}>
                Please enter your email below and we will send you instructions
                to reset your password.
              </p>
              <Text
                label="Email"
                type="email"
                data-testid="email"
                error={errors.email?.message}
                {...register("email", {
                  validate: val => {
                    if (!isValidEmail(val)) {
                      return "A valid email is required";
                    }
                    return;
                  },
                })}
              />
            </div>

            <ButtonRow>
              <Button
                onClick={handleSubmit(async userProvidedData => {
                  await requestReset({
                    variables: { email: userProvidedData.email },
                  });
                })}
                disabled={loading}
                styleVariant="primary"
                size="medium"
              >
                Submit
              </Button>
            </ButtonRow>
          </Container>
        </form>
      )}
    </FullPageBackgroundWithLogo>
  );
};
