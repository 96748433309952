import React from "react";
import { useFormContext } from "react-hook-form";

import { Button } from "../../../../Common/Button";
import {
  ButtonSection,
  Container,
  ContentSection,
  FormSection,
  HeaderSection,
  PrimaryButtons,
} from "../../../../Common/__styles__/Modal";

import { FirmComponent } from "../../../../../generated/graphql";
import { UseDropzones, FirmImportForm } from "./FirmImportForm";
import { SingleFileUpload } from "../../../../Inputs/react-hook-form";
import { DropzoneLabel } from "./__styles__/UploadGeoJsonComponentsStage";
import { MIME_TYPE } from "common/constants";

export interface UploadGeoJsonComponentsStageProps {
  onNext: () => void;
  onCancel: () => void;
  useDropzones?: UseDropzones;
}

export const UploadGeoJsonComponentsStage = ({
  onNext,
  onCancel,
  useDropzones = undefined,
}: UploadGeoJsonComponentsStageProps) => {
  const { watch, control } = useFormContext<FirmImportForm>();

  const components = watch("components");

  return (
    <Container width="narrow">
      <HeaderSection>
        <h1>Import FIRM files</h1>
        <h2>
          All files must be geojson (.geojson) files. Please verify that your
          files match the required schema{" "}
          <a
            href="https://forerunner.helpscoutdocs.com/article/74-firm-requirements"
            target="_blank"
          >
            here
          </a>
        </h2>
      </HeaderSection>
      <FormSection>
        <ContentSection>
          {components!.map((component, index) => (
            <React.Fragment key={index}>
              <DropzoneLabel>{component.label}</DropzoneLabel>
              <SingleFileUpload
                control={control}
                name={`components.${index}`}
                allowedMimeTypes={[MIME_TYPE.GEOJSON]}
                compact={true}
                hasImage={false}
                key={component.name}
                useDropzone={
                  useDropzones?.find(
                    dropzone => dropzone.name === component.name
                  )?.useDropzone
                }
              />
            </React.Fragment>
          ))}
        </ContentSection>
      </FormSection>
      <ButtonSection>
        <PrimaryButtons>
          <Button styleVariant="secondary" onClick={onCancel} size="medium">
            Cancel
          </Button>
          <Button
            styleVariant="primary"
            onClick={() => onNext()}
            size="medium"
            disabled={
              !components?.find(
                component => component.name === FirmComponent.FLOODZONES
              )!.blob
            }
          >
            Next
          </Button>
        </PrimaryButtons>
      </ButtonSection>
    </Container>
  );
};

export default UploadGeoJsonComponentsStage;
