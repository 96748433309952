import { useModal } from "react-modal-hook";
import Modal from "../Common/Modal";
import React, { useState } from "react";
import { useStatusToasts } from "../../hooks/useStatusToasts";
import { useForm } from "react-hook-form";
import {
  ButtonSection,
  ContentSection,
  FormSection,
  HeaderSection,
  PrimaryButtons,
  Container,
  InputRow,
} from "../Common/__styles__/Modal";
import { Button } from "../Common/Button";
import { validatePassword } from "common/utils/validate-password";
import { validatePasswordsMatch } from "../../utils/password-match";
import {
  useUpdatePasswordMutation,
  UpdatePasswordMutationVariables,
  CurrentUserSettingsQuery,
} from "../../generated/graphql";
import { Text } from "../Inputs/react-hook-form";
import { PasswordNote } from "./__styles__/UpdatePasswordModal";

type UpdatePasswordModalHookProps = Partial<{
  user: CurrentUserSettingsQuery["currentUser"];
}>;

export const useUpdatePasswordModal = () => {
  const [props, setProps] = useState<UpdatePasswordModalHookProps>({});
  const [show, hide] = useModal(
    () => (
      <Modal onRequestClose={hide}>
        <UpdatePasswordModal onCancel={hide} onSave={hide} {...props} />
      </Modal>
    ),
    [props]
  );

  const showWithProps = (
    additionalProps: UpdatePasswordModalHookProps = {}
  ) => {
    setProps(additionalProps);
    show();
  };

  return [showWithProps, hide] as const;
};
type UpdatePasswordFormProps = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

export interface UpdatePasswordModalProps {
  onSave: () => void;
  onCancel: () => void;
  user?: CurrentUserSettingsQuery["currentUser"];
}

const UpdatePasswordModal = ({
  onCancel,
  onSave,
  user,
}: UpdatePasswordModalProps) => {
  if (!user) {
    return null;
  }

  const { addSuccessToast, addFailureToast } = useStatusToasts();

  const {
    register,
    handleSubmit,
    getValues,
    setError,
    getFieldState,
    formState: { errors, isDirty, isValid },
  } = useForm<UpdatePasswordFormProps>({ delayError: 200, mode: "onChange" });

  const minimumPasswordLength =
    user.account.securityConfiguration.minimumPasswordLength;

  const [updatePassword, { loading }] = useUpdatePasswordMutation({
    onCompleted: () => {
      addSuccessToast(`Password successfully reset.`);
      onSave();
    },
    onError: error => {
      if (error.graphQLErrors[0]?.extensions?.code === "USER_ERROR") {
        const property = error.message.match(/password is incorrect/i)
          ? "currentPassword"
          : "confirmNewPassword";
        setError(property, { message: error.message }, { shouldFocus: true });
      } else {
        addFailureToast(
          `There was an error resetting your password. Please try again. If the problem persists, please email us at support@withforerunner.com`
        );
      }
    },
  });

  const submitForm = async (userProvidedData: UpdatePasswordFormProps) => {
    const { currentPassword, newPassword } = userProvidedData;
    const payload: UpdatePasswordMutationVariables = {
      currentPassword,
      newPassword,
    };
    await updatePassword({ variables: payload });
  };

  return (
    <Container>
      <HeaderSection>
        <h1>Reset password</h1>
      </HeaderSection>
      <FormSection onSubmit={handleSubmit(submitForm)}>
        <ContentSection>
          <InputRow>
            <Text
              label="Current password"
              placeholder="Current password"
              type="password"
              data-testid="current-password"
              error={errors.currentPassword?.message}
              {...register("currentPassword", {
                required: "Required field",
              })}
            />
          </InputRow>
          <InputRow>
            <Text
              label="New password"
              placeholder="New password"
              type="password"
              data-testid="new-password"
              error={errors.newPassword?.message}
              {...register("newPassword", {
                required: "Required field",
                deps: ["confirmNewPassword"],
                validate: {
                  passwordIsValid: (newPassword: string) =>
                    validatePassword({
                      minimumPasswordLength,
                      password: newPassword,
                    }),
                },
              })}
            />
          </InputRow>
          <InputRow>
            <Text
              label="Confirm new password"
              placeholder="Confirm new password"
              type="password"
              data-testid="confirm-new-password"
              error={errors.confirmNewPassword?.message}
              {...register("confirmNewPassword", {
                validate: {
                  passwordsMatch: (confirmNewPassword: string) => {
                    const userHasEnteredValue =
                      getFieldState("confirmNewPassword").isDirty;
                    return (
                      userHasEnteredValue &&
                      validatePasswordsMatch(
                        getValues("newPassword"),
                        confirmNewPassword
                      )
                    );
                  },
                },
              })}
            />
          </InputRow>
          <PasswordNote>
            Note: please use at least {minimumPasswordLength} characters in your
            password and include at least one number, one upper case letter, and
            one special character.
          </PasswordNote>
        </ContentSection>
        <ButtonSection>
          <PrimaryButtons>
            <Button
              onClick={onCancel}
              size="medium"
              styleVariant="secondary"
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              size="medium"
              styleVariant="primary"
              type="submit"
              disabled={loading || !isDirty || !isValid}
            >
              Save
            </Button>
          </PrimaryButtons>
        </ButtonSection>
      </FormSection>
    </Container>
  );
};

export default UpdatePasswordModal;
