import React, { Dispatch } from "react";
import MultiLogicFilter, { FilterSegmentProps } from "../MultiLogicFilter";
import {
  FilterAction,
  FilterReducerState,
  FilterSegmentContext,
} from "./useFilterReducer";
import { flatten } from "lodash";

const FilterRows = ({
  filterState,
  dispatch,
}: {
  filterState: FilterReducerState;
  dispatch: Dispatch<FilterAction>;
}) => {
  const filterSegments = flatten(Object.values(filterState.filterValues)).map(
    filterValue => filterValue.filterSegment
  );

  const onFilterRemove = (
    _index: number,
    filterData: Array<FilterSegmentProps<FilterSegmentContext>>
  ) => {
    dispatch({ type: "removeFilter", data: { row: filterData } });
  };

  return (
    <MultiLogicFilter
      filters={filterSegments}
      onFilterRemove={onFilterRemove}
      includeAndPrefix
    />
  );
};

export default FilterRows;
