import React from "react";

import { Icon, IconNames, iconNames } from "../../Common/Icons/LucideIcons";
import {
  ActivityTargetType,
  ActivityType,
  SubmissionCategory,
} from "../../../generated/graphql";
import commentIcon from "./Icons/Comment.svg";
import documentIcon from "./Icons/Book.svg";
import designFloodElevationIcon from "./Icons/HouseInfo.svg";
import firmIcon from "./Icons/HouseInfo.svg";
import improvementIcon from "./Icons/House.svg";
import logIcon from "./Icons/Books.svg";
import mailIcon from "./Icons/Mail.svg";
import propertyIcon from "./Icons/PlainHouse.svg";
import warningIcon from "./Icons/BangInFilledCircle.svg";
import { IconContainer, GreyCircle } from "./__styles__/Bullet";
import { get } from "lodash";

const LucideIconBullet = ({ iconName }: { iconName: IconNames }) => {
  return (
    <IconContainer>
      <GreyCircle>
        <Icon iconName={iconName} color={"grey9"} size={"12"} />
      </GreyCircle>
    </IconContainer>
  );
};
export interface BulletProps {
  targetType: ActivityTargetType;
  activityType: ActivityType;
  category?: Maybe<SubmissionCategory>;
}

const ICONS_PER_TYPE = {
  [ActivityTargetType.COMMENT]: commentIcon,
  [ActivityTargetType.CERTIFICATE]: documentIcon,
  [ActivityTargetType.DESIGN_FLOOD_ELEVATION]: designFloodElevationIcon,
  [ActivityTargetType.FIRM]: firmIcon,
  [ActivityTargetType.LOG]: logIcon,
  [ActivityTargetType.PROPERTY]: {
    [ActivityType.SHARED]: mailIcon,
    [ActivityType.CREATED]: propertyIcon,
    [ActivityType.DELETED]: propertyIcon,
    [ActivityType.UPDATED]: propertyIcon,
    [ActivityType.HID]: propertyIcon,
    [ActivityType.DISPLAYED]: propertyIcon,
  },
  [ActivityTargetType.DOCUMENT_UPLOAD]: documentIcon,
  [ActivityTargetType.BATCH_CERTIFICATE_UPLOAD]: documentIcon,
  [ActivityTargetType.PROPERTY_WARNING]: warningIcon,
  [ActivityTargetType.REPETITIVE_LOSS]: propertyIcon,
  [ActivityTargetType.SUBMISSION]: {
    [SubmissionCategory.INSPECTIONS]: "clipboard-check",
    [SubmissionCategory.PERMITTING]: "clipboard-check",
    [SubmissionCategory.SUBSTANTIAL_IMPROVEMENT_SUBSTANTIAL_DAMAGE]:
      improvementIcon,
  },
} as const;

const Bullet = ({ targetType, activityType, category }: BulletProps) => {
  const icon = ICONS_PER_TYPE[targetType];

  const setIcon = (
    targetType: ActivityTargetType,
    activityType: ActivityType
  ) => {
    if (typeof icon === "string") {
      return <img src={icon} />;
    }

    // This is because we only use either category or activityType, if both are needed
    // then update the shape of ICONS_PER_TYPE as needed
    const iconSubtype = category ?? activityType;

    const subtypeIcon = get(icon, iconSubtype) as string | undefined;
    if (iconNames.includes(subtypeIcon as string)) {
      return <LucideIconBullet iconName={subtypeIcon as IconNames} />;
    }
    if (!subtypeIcon) {
      throw new Error(
        `No icon found for target ${targetType} with subtype ${iconSubtype}`
      );
    }

    return <img src={subtypeIcon} />;
  };

  return <IconContainer>{setIcon(targetType, activityType)} </IconContainer>;
};

export default Bullet;
