import React, { useContext } from "react";
import { FilterDescription, TABLE_NAMES } from "common/utils/queryBuilder";
import { NetworkStatus } from "@apollo/client";
import { ColumnDef } from "@tanstack/table-core/build/lib/types";
import { formatBuildingElevationSource } from "common/utils/strings";
import { useLocation } from "react-router";

import {
  GetQueryFilesQueryVariables,
  useGetFileTableConfigQuery,
  useGetQueryFilesLazyQuery,
} from "../../generated/graphql";
import { AuthContext } from "../Authorization/AuthContext";
import {
  useManualPaginationConfig,
  useQueryDescription,
} from "../Common/Tables/hooks";
import {
  FullWidthTable,
  splitTableAndColumnNames,
} from "../Common/FullWidthTable/FullWidthTable";
import { QueryFileTableResult } from "./__queries__/table";
import { ACTION_COLUMN_DEF_CONSTANTS } from "../Common/ActionCell";
import {
  buildLocalTableInfo,
  useLocalTableDisplayConfig,
} from "../../hooks/useTableDisplayConfig";
import { sanityCheckLocalColumnOrder } from "../Common/FullWidthTable/utils";
import { ExpandableCell } from "../Common/ExpandableCell";
import { Attribute } from "../Common/FullWidthTable/types";
import { CellRenderConfig } from "../Common/Tables/utils";
import { CertificateUploadStatus } from "common-client/generated/graphql";
import { UserFriendlyCertificateStatus } from "common/constants";
import { CUSTOM_PROPERTY_LINK_CELLS } from "../Property/PropertiesTable";
import { buildLink } from "common/routing";
import LinkCell from "../Common/LinkCell";

const DEFAULT_QUERY_DESCRIPTION = {
  table: TABLE_NAMES.FILES,
  fields: [
    { table: TABLE_NAMES.FILES, name: "id" },
    { table: TABLE_NAMES.PROPERTIES, name: "streetAddress" },
    { table: TABLE_NAMES.PROPERTIES, name: "city" },
    { table: TABLE_NAMES.FILES, name: "issueCount" },
    { table: TABLE_NAMES.FILES, name: "issues" },
    { table: TABLE_NAMES.FILES, name: "documentType" },
    { table: TABLE_NAMES.FILES, name: "createdAt" },
    { table: TABLE_NAMES.FILES, name: "issuedAt" },
    { table: TABLE_NAMES.CERTIFICATE_UPLOADS, name: "status" },
  ],
  filters: [],
  orderBy: {
    table: TABLE_NAMES.FILES,
    field: "documentType",
    direction: "ASC" as const,
  },
};

const DEFAULT_COLUMN_DEFINITION_IDS = [
  "Files.documentType",
  "Properties.streetAddress",
  "Properties.city",
  "Files.issues",
  "Files.issueCount",
  "Files.createdAt",
  "Files.issuedAt",
  "CertificateUploads.status",
  "actions",
];

export const FILES_TABLE_CUSTOM_CELLS: Record<string, CellRenderConfig> = {
  "Files.issues": {
    cell: ({ row }) => {
      const pills = row.original["Files.issues"]?.map((issue: string) => ({
        label: issue,
        iconName: "alert-circle",
      }));

      return <ExpandableCell pills={pills} />;
    },
  },
  "Certificates.buildingElevationSource": {
    accessorFn: (row: any) => {
      return formatBuildingElevationSource(
        row["Certificates.buildingElevationSource"]
      );
    },
  },
  "CertificateUploads.status": {
    accessorFn: (row: any) => {
      return UserFriendlyCertificateStatus[
        row["CertificateUploads.status"] as CertificateUploadStatus
      ];
    },
  },
  "Files.adminLink": {
    cell: ({ row }) => {
      const linkProps = row.original["Files.adminLink"];
      const { streetAddress } = row.original["Properties.streetAddress"];

      if (!linkProps) {
        return null;
      }

      const url = buildLink(
        "documentUploadDetail",
        { id: linkProps.fileId },
        {},
        { admin: true, accountId: linkProps.accountId }
      );

      return <LinkCell href={url} target={"_blank"} label={streetAddress} />;
    },
  },
  ...CUSTOM_PROPERTY_LINK_CELLS,
};

type DocumentUploadsTableProps = {
  actionCell: (data: {
    original: QueryFileTableResult;
    onUpdate: () => {};
    prevLocation?: string;
  }) => JSX.Element;
  tableActions?: React.ReactNode;
};

export const DocumentUploadsTable = ({
  actionCell,
  tableActions,
}: DocumentUploadsTableProps) => {
  const location = useLocation();

  const { user, admin } = useContext(AuthContext);

  const { data: columnConfigResponse, loading: loadingColumnConfig } =
    useGetFileTableConfigQuery({
      fetchPolicy: "network-only",
    });

  const [
    queryFiles,
    { previousData, data: currentData, networkStatus, loading, error, refetch },
  ] = useGetQueryFilesLazyQuery({
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const actionsColumn: ColumnDef<QueryFileTableResult> = {
    ...ACTION_COLUMN_DEF_CONSTANTS,
    cell: ({ row }) => {
      const original = {
        property: {
          id: row.original["Properties.id"],
          streetAddress: row.original["Properties.streetAddress"],
          longitude: row.original["Properties.longitude"],
          latitude: row.original["Properties.latitude"],
        },
        elevationCertificate: { id: row.original["Certificates.id"] },
        issuedAt: row.original["Files.issuedAt"],
        accountDocumentType: { name: row.original["Files.documentType"] },
        id: row.original["Files.id"],
        originalFilename: row.original["Files.originalFilename"],
        formData: row.original["Submissions.formData"],
        submission: row.original["Submissions.id"],
      };

      return actionCell({
        original,
        onUpdate: refetch!,
        prevLocation: location.pathname + location.search,
      });
    },
  };

  const {
    initialTableState,
    queryDescription,
    updateQueryDescription,
    setQueryDescriptionInURL,
    defaultColumnDefinitions,
    generatedColumnDefinitions,
  } = useQueryDescription<QueryFileTableResult>({
    initialQueryDescription: DEFAULT_QUERY_DESCRIPTION,
    defaultColumnIds: DEFAULT_COLUMN_DEFINITION_IDS,
    tableConfig: columnConfigResponse?.getFileTableConfig.data || [],
    customTableCells: FILES_TABLE_CUSTOM_CELLS,
    defaultSort: [{ id: "Files.documentType", desc: false }],
    actionsColumn,
  });

  const { id: tableId } = buildLocalTableInfo({
    entityId: user?.id ?? admin?.id,
    pathname: location.pathname,
  });

  const { getLocalTableState, setLocalColumnOrder } =
    useLocalTableDisplayConfig({
      tableId,
      defaultValue: {
        columnOrder: defaultColumnDefinitions.map(column => column.id!),
        columnSizing: {},
        sorting: [],
      },
    });

  const manualPaginationConfig = useManualPaginationConfig({
    ...initialTableState.pagination,
    currentTotalPages: currentData?.queryFiles.pageInfo.totalPages,
    previousTotalPages: previousData?.queryFiles.pageInfo.totalPages,
  });

  const tanstackColumnDefinitions: Array<ColumnDef<QueryFileTableResult>> = [
    ...generatedColumnDefinitions,
    actionsColumn,
  ];

  const data =
    networkStatus === NetworkStatus.setVariables ? previousData : currentData;

  if (loadingColumnConfig || !columnConfigResponse?.getFileTableConfig.data) {
    return null;
  }

  const loadingDetails = {
    loading,
    loadingText: "Loading files",
    noDataText: "No files found",
  };

  const search = ({
    page,
    columns,
  }: {
    page: number;
    columns: Array<ColumnDef<QueryFileTableResult>>;
  }) => {
    const visibleColumns = columns.filter(c => c.id?.includes("."));

    const visibleFields = visibleColumns.map(c => {
      const { field, table } = splitTableAndColumnNames(c.id!);
      return {
        table,
        name: field,
      };
    });

    const variables: GetQueryFilesQueryVariables = {
      description: {
        table: TABLE_NAMES.FILES,
        fields: [
          ...visibleFields,
          //required fields for the actions column
          { table: TABLE_NAMES.FILES, name: "id" },
          { table: TABLE_NAMES.PROPERTIES, name: "id" },
          { table: TABLE_NAMES.PROPERTIES, name: "streetAddress" },
          { table: TABLE_NAMES.PROPERTIES, name: "latitude" },
          { table: TABLE_NAMES.PROPERTIES, name: "longitude" },
          { table: TABLE_NAMES.FILES, name: "originalFilename" },
          { table: TABLE_NAMES.FILES, name: "hiddenFromPublic" },
          { table: TABLE_NAMES.CERTIFICATES, name: "id" },
          { table: TABLE_NAMES.SUBMISSIONS, name: "id" },
          { table: TABLE_NAMES.SUBMISSIONS, name: "formData" },
        ],
        orderBy: queryDescription.orderBy,
        filters: queryDescription.filters,
      },
      page,
    };

    void queryFiles({
      variables,
    });
  };

  const localTableConfig = getLocalTableState();

  const { columnOrder: localColumnOrder } = localTableConfig;

  const updatedColumnOrder = sanityCheckLocalColumnOrder({
    localColumnOrder,
    defaultColumns: defaultColumnDefinitions,
  });

  if (updatedColumnOrder) {
    setLocalColumnOrder(updatedColumnOrder);
  }

  const initialColumns = updatedColumnOrder
    ? updatedColumnOrder.map(
        id => defaultColumnDefinitions.find(c => c.id === id)!
      )
    : defaultColumnDefinitions;

  const timeoutError = error?.graphQLErrors.find(
    e => e.extensions.code === "TIMEOUT_ERROR"
  );

  return (
    <FullWidthTable<QueryFileTableResult, Array<FilterDescription>>
      actions={tableActions}
      columns={initialColumns}
      previousData={previousData?.queryFiles.data}
      currentData={data?.queryFiles.data ?? []}
      loadingDetails={loadingDetails}
      tableStyleDetails={{ hasHighlights: true, hasRowActions: true }}
      manualPaginationConfig={{
        ...manualPaginationConfig,
        pageCount: data?.queryFiles.pageInfo.totalPages ?? 1,
      }}
      initialState={initialTableState}
      filterable={{
        newFilterConfiguration: columnConfigResponse.getFileTableConfig
          .data as Array<Attribute>,
        search,
      }}
      columnSettingProps={{
        columnConfiguration: columnConfigResponse.getFileTableConfig.data,
        columnDefinitions: tanstackColumnDefinitions,
      }}
      timeoutError={timeoutError}
      queryDescription={queryDescription}
      updateQueryDescription={updateQueryDescription}
      setQueryDescriptionInURL={setQueryDescriptionInURL}
    />
  );
};
