import React from "react";
import { Select, Text, Textarea } from "../../../../../Inputs/react-hook-form";
import { Label } from "../../../../../Inputs";
import { useFormContext } from "react-hook-form";
import { SubmissionsBuilderFormDataStructure } from "../types";
import { categoryOptions } from "../constants";

const FormSettingsTab = () => {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<SubmissionsBuilderFormDataStructure>();

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <div>
        <Label text={"All fields are required"} required />
      </div>
      <Text
        {...register("name", { required: "Name is required" })}
        label="Form title"
        error={errors.name?.message}
      />
      <div>
        <Label text="Record type" htmlFor="category" />
        <Select
          control={control}
          name="category"
          options={categoryOptions}
          size="medium"
          required={true}
          rules={{ required: "Record type is required" }}
        />
      </div>

      <div>
        <Label text={"Form description"} />
        <Textarea
          {...register("description")}
          error={errors.description?.message}
        />
      </div>
      <div>
        <Label text={"Success message"} />
        {/* TODO: When we support external, success message will only be required for internal */}
        <Text
          {...register("successMessage", {
            required: "Success message is required",
          })}
          error={errors.successMessage?.message}
        />
      </div>
    </div>
  );
};

export default FormSettingsTab;
