import React, { useContext, useMemo } from "react";
import MultiLogicFilter, {
  MultiLogicFilterData,
} from "../../../../Common/MultiLogicFilter";
import LogicFilterBuilder from "./LogicFilterBuilder";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { UpsertSisdRuleFormStructure } from "../upsertSisdRuleModal";
import useFilterBuilderState from "./hooks/useFilterBuilderState";
import {
  convertRuleFilterToFilterSegmentProps,
  getFilterTargetFromFilterSegmentProps,
} from "./utils";
import { Required } from "../../../../Inputs/__styles__/Label";
import {
  LogicFiltersDescription,
  LogicFiltersTitle,
  LogicFiltersWrapper,
} from "./__styles__/LogicFilters";
import { FilterContext } from "../FilterContext";

const LogicFilters = () => {
  const { control } = useFormContext<UpsertSisdRuleFormStructure>();
  const { append, remove } = useFieldArray({ control, name: "filters" });
  const filters = useWatch<UpsertSisdRuleFormStructure, "filters">({
    control,
    name: "filters",
  });
  const filterContext = useContext(FilterContext);

  const filterBuilderProps = useFilterBuilderState({
    append,
    filters,
  });

  const filterSummaryData = useMemo(() => {
    return convertRuleFilterToFilterSegmentProps(
      filters,
      filterContext.filterTargetMetadata
    );
  }, [filters]);

  const removeFilterSummary = (
    filterIndex: number,
    filterSegmentProps: MultiLogicFilterData<{}>[number]
  ) => {
    remove(filterIndex);
    const filterTarget = getFilterTargetFromFilterSegmentProps(
      filterSegmentProps,
      filterContext.filterTargetOptions
    );

    // For type safety
    if (filterTarget) {
      filterBuilderProps.removeFilterSummary(filterTarget);
    }
  };
  return (
    <LogicFiltersWrapper>
      <LogicFiltersTitle>
        Logic filter(s) <Required>*</Required>
      </LogicFiltersTitle>
      <LogicFiltersDescription>
        This determines which properties the rule will be applied to.
      </LogicFiltersDescription>
      <MultiLogicFilter
        filters={filterSummaryData}
        onFilterRemove={removeFilterSummary}
        includeAndPrefix
      />
      <LogicFilterBuilder {...filterBuilderProps} />
    </LogicFiltersWrapper>
  );
};

export default LogicFilters;
