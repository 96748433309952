import React from "react";
import { useFormContext } from "react-hook-form";
import { Label } from "../../../../../../Inputs";
import { Text } from "../../../../../../Inputs/react-hook-form";
import { SubmissionsBuilderFormDataStructure } from "../../types";
import { SelectedSubmissionsBuilderField } from "../../reducer";
import { WidgetSettingsContainer } from "./__styles__/WidgetSettings";
import FieldsList from "../FieldsList";

const ObjectFieldTemplateSettings = ({
  fieldPath,
}: Exclude<SelectedSubmissionsBuilderField, null>) => {
  const { register } = useFormContext<SubmissionsBuilderFormDataStructure>();

  return (
    <WidgetSettingsContainer>
      <div>
        <Label text={"Title"} required />
        <Text {...register(`${fieldPath}.title`)} />
      </div>
      <hr />
      <FieldsList />
    </WidgetSettingsContainer>
  );
};

export default ObjectFieldTemplateSettings;
