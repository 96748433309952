import React, { useContext } from "react";
import { NetworkStatus } from "@apollo/client";
import { upperFirst } from "lodash";

import { Box } from "../../../Common/Layout";
import { DropdownMenu } from "../../../Inputs";
import { EmptyState } from "../../../Common/EmptyState";
import {
  ParcelImportTaskType,
  useAccountParcelImportTasksQuery,
  useHasParcelImportTaskInProgressQuery,
} from "../../../../generated/graphql";
import RecentImportTasksTable, {
  RecentImportTask,
} from "../RecentImportTasksTable";
import ParcelImportConfiguration from "./ParcelImportConfiguration";
import { AuthContext } from "../../../Authorization/AuthContext";
import { RESOURCE_NAME } from "common/authorization";
import Divider from "../../../Common/Divider";
import {
  Empty,
  Header,
  Section,
  SectionTitle,
  HeaderTitle,
} from "../../__styles__/Content";
import { Icons } from "../../../Common/Icons";
import { useManualPaginationConfig } from "../../../Common/Tables/hooks";
import { Button } from "../../../Common/Button";
import { ActionsProps } from "../../../Inputs/DropdownMenu";
import { useConfigurationOverridesModal } from "./ParcelImportWizard/ConfigurationOverridesModal";

export const Parcels = () => {
  const { authorized, account } = useContext(AuthContext);
  const manualPaginationConfig = useManualPaginationConfig({ pageSize: 10 });
  const variables = {
    page: manualPaginationConfig.pagination.pageIndex + 1,
    pageSize: manualPaginationConfig.pagination.pageSize,
  };

  const {
    data: currentData,
    networkStatus,
    loading,
    error,
    previousData,
    refetch: refetchAccountParcelImportTasks,
  } = useAccountParcelImportTasksQuery({ fetchPolicy: "no-cache", variables });

  const { data: statusData, refetch: refetchHasParcelImportTaskInProgress } =
    useHasParcelImportTaskInProgressQuery({
      fetchPolicy: "no-cache",
    });

  const refetch = async () => {
    await refetchAccountParcelImportTasks();
    await refetchHasParcelImportTaskInProgress();
  };

  const canCreateParcelConfiguration =
    authorized({
      resource: RESOURCE_NAME.PARCEL_IMPORT_CONFIGURATION,
      permission: "create",
    }) && !statusData?.hasParcelImportTaskInProgress;

  const parcelImportButton = ({
    onClick,
  }: {
    onClick: (_: React.MouseEvent<HTMLElement>) => void;
  }) => (
    <Button
      onClick={onClick}
      styleVariant="outlineLight"
      size="small"
      rightIconName="chevron-down"
    >
      Update parcel source
    </Button>
  );

  const data =
    networkStatus === NetworkStatus.setVariables ? previousData : currentData;

  const parcelImportTasks = data?.account?.parcelImportTasks.data;

  const parcelImportConfiguration = data?.account?.parcelImportConfiguration;

  const hasParcelImportTasks =
    parcelImportTasks && parcelImportTasks.length > 0;

  const hasParcelImportConfiguration = !!parcelImportConfiguration;

  const [showConfigOverridesModal] = useConfigurationOverridesModal({
    parcelImportConfiguration,
  });

  const actions: Array<ActionsProps> = [
    {
      label: "ArcGIS rest service import",
      onClick: () => showConfigOverridesModal(ParcelImportTaskType.ARCGIS),
      disabled: !canCreateParcelConfiguration,
    },
    {
      label: "Geopackage import",
      onClick: () => showConfigOverridesModal(ParcelImportTaskType.GPKG),
      disabled: !canCreateParcelConfiguration,
    },
  ];

  if (error) return <div>Error loading parcel imports.</div>;

  return (
    <Box>
      <Header>
        <HeaderTitle>Parcel data settings</HeaderTitle>
        <DropdownMenu actions={actions} customButton={parcelImportButton} />
      </Header>
      <Divider />
      {hasParcelImportConfiguration && (
        <>
          <Section>
            <SectionTitle>Parcel import configuration</SectionTitle>
          </Section>
          <ParcelImportConfiguration
            parcelImportConfiguration={parcelImportConfiguration}
            onUpdate={refetch}
            filename={parcelImportTasks?.[0]?.name}
          />
          {hasParcelImportTasks && <Divider margins="0px 24px" />}
        </>
      )}
      {hasParcelImportTasks && (
        <Section>
          <SectionTitle>Recent imports</SectionTitle>
          <RecentImportTasksTable
            recentImportTasks={parcelImportTasks.map(parcelImportTask => {
              return {
                id: parcelImportTask!.id,
                name: parcelImportTask!.name,
                error: parcelImportTask?.errors,
                createdAt: parcelImportTask!.createdAt,
                status: upperFirst(parcelImportTask!.status),
                csvDownloadHref: parcelImportTask?.failedProperties.length
                  ? `/api/parcel-import/${
                      parcelImportTask!.id
                    }/failed-properties?accountId=${account?.id}`
                  : null,
                icon:
                  parcelImportTask!.importType === ParcelImportTaskType.GPKG
                    ? Icons.GPKG
                    : Icons.ARC_GIS,
              } as RecentImportTask;
            })}
            manualPaginationConfig={{
              ...manualPaginationConfig,
              pageCount:
                data.account?.parcelImportTasks.pageInfo.totalPages ?? 1,
            }}
            loading={loading}
          />
        </Section>
      )}
      {!hasParcelImportConfiguration && !hasParcelImportTasks && (
        <Empty>
          <EmptyState message="Your account has no current parcel data source" />
        </Empty>
      )}
    </Box>
  );
};

export default Parcels;
