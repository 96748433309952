import React, { useContext, useState } from "react";
import {
  BuilderSidebarWrapper,
  TabGroupWrapper,
} from "../__styles__/SubmissionsBuilder";
import FormSettingsTab from "./FormSettingsTab";
import FormJSONPreview from "./JSONPreviewTab";
import TabGroup from "../../../../../Common/TabGroup";
import { FieldsTab } from "./FieldsTab";
import { SubmissionsBuilderContextInstance } from "../context";
import { JSON_PREVIEW_ENABLED_KEY } from "../constants";

type SidebarTabValue = "fields" | "settings" | "jsonPreview";

const DEFAULT_TABS = [
  { label: "Fields", value: "fields" },
  { label: "Settings", value: "settings" },
] as const;

const TabNameToTabBodyMap: Record<SidebarTabValue, () => JSX.Element> = {
  fields: FieldsTab,
  settings: FormSettingsTab,
  jsonPreview: FormJSONPreview,
};

export const SidebarColumn = () => {
  const builderContext = useContext(SubmissionsBuilderContextInstance);
  const [activeTab, setActiveTab] = useState<SidebarTabValue>("fields");

  const tabs = builderContext[JSON_PREVIEW_ENABLED_KEY]
    ? [
        ...DEFAULT_TABS,
        { label: "JSON Preview", value: "jsonPreview" } as const,
      ]
    : [...DEFAULT_TABS];

  const TabBody = TabNameToTabBodyMap[activeTab];

  return (
    <BuilderSidebarWrapper>
      <TabGroupWrapper>
        <TabGroup<SidebarTabValue>
          currentTab={activeTab}
          setTab={setActiveTab}
          tabs={tabs}
        />
      </TabGroupWrapper>
      <TabBody />
    </BuilderSidebarWrapper>
  );
};
