import React, { useEffect, useRef } from "react";
import { FlexRow } from "../../Layout";
import { Button, StyleVariant } from "../../Button";
import { useOutsideAlerter } from "../../../../utils/outsideAlerter";
import { SettingsDropdownWrapper } from "../__styles__/ViewSettings";
import { ColumnDef } from "@tanstack/react-table";
import { Attribute } from "../types";
import EditColumnsMenu from "./EditColumnsMenu";
import AddColumnMenu from "./AddColumnMenu";
import { tail } from "lodash";
import { ACTION_COLUMN_DEF_CONSTANTS } from "../../ActionCell";
import { useColumnSettingsReducer } from "./ColumnSettingsReducer";
import { DropdownItem } from "../../Filter/__styles__/Filters";
import { SectionTitle } from "../../Filter";
import Divider from "../../Divider";

const TableSettingsButton = <T,>({
  currentColumns,
  columnConfig,
  hideColumn,
  addColumn,
  buttonVariant = "outlineLight",
}: {
  currentColumns: ColumnDef<T>[];
  columnConfig: Array<Attribute>;
  hideColumn: (id: string) => void;
  addColumn: (id: string) => void;
  buttonVariant?: StyleVariant;
}) => {
  const wrapperRef = useRef(null as null | HTMLDivElement);

  const [settingsState, dispatch] = useColumnSettingsReducer();

  const { history, open } = settingsState;

  const toggleMenu = () => {
    if (!open) {
      dispatch({ type: "openMenu" });
    } else {
      dispatch({ type: "closeMenu" });
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        dispatch({ type: "closeMenu" });
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useOutsideAlerter(wrapperRef, () => dispatch({ type: "closeMenu" }));

  const editableColumns = tail(currentColumns).filter(
    column => column.id !== ACTION_COLUMN_DEF_CONSTANTS.id
  );

  const lastView = history[history.length - 1];

  let menuTitle = lastView?.label ?? "Columns";

  const canGoBack = history.length > 0;

  const hasEditableColumns = editableColumns.length > 0;

  return (
    <div
      style={{
        position: "relative",
        userSelect: "none",
        zIndex: 3,
      }}
      ref={wrapperRef}
    >
      <FlexRow style={{ gap: "8px" }}>
        <Button
          onClick={toggleMenu}
          size="small"
          aria-label="Veiw settings"
          leftIconName="settings"
          styleVariant={buttonVariant}
        >
          View settings
        </Button>
      </FlexRow>
      {open && (
        <SettingsDropdownWrapper>
          <DropdownItem>
            <SectionTitle
              onGoBack={
                canGoBack
                  ? () => {
                      dispatch({ type: "goBack" });
                    }
                  : undefined
              }
              label={menuTitle}
            />
          </DropdownItem>
          {hasEditableColumns && <Divider />}

          {history.length === 0 ? (
            <EditColumnsMenu
              editableColumns={editableColumns}
              hideColumn={hideColumn}
              dispatch={dispatch}
              columnConfig={columnConfig}
            />
          ) : (
            <AddColumnMenu
              currentColumns={currentColumns}
              addColumn={addColumn}
              settingsState={settingsState}
              dispatch={dispatch}
            />
          )}
        </SettingsDropdownWrapper>
      )}
    </div>
  );
};

export default TableSettingsButton;
