import React, { useState } from "react";
import { useModal } from "react-modal-hook";

import Modal from "../Common/Modal";
import {
  ButtonSection,
  Container,
  ContentSection,
  HeaderSection,
  PrimaryButtons,
} from "../Common/__styles__/Modal";
import { Radio } from "../Inputs";
import { Button } from "../Common/Button";

import "./__styles__/PropertyForm.scss";

interface SelectAddressProps {
  suggestedAddress: string;
  fullAddress: string;
  onSelect: (selectedAddress: string) => void;
  onCancel: () => void;
}

export type SelectAddressUseModalProps = Omit<SelectAddressProps, "onCancel">;

export default () => {
  const [props, setProps] = useState<Maybe<SelectAddressUseModalProps>>(null);

  const [show, hide] = useModal(() => {
    return (
      <Modal onRequestClose={hide}>
        <SelectAddressModal onCancel={hide} {...props!} />
      </Modal>
    );
  }, [props]);

  const showWithProps = (props: SelectAddressUseModalProps) => {
    setProps(props);
    show();
  };

  return [showWithProps, hide] as const;
};

export const SelectAddressModal = ({
  suggestedAddress,
  fullAddress,
  onSelect,
  onCancel,
}: SelectAddressProps) => {
  const [selectedAddress, setSelectedAddress] = useState(suggestedAddress);
  const options = [
    {
      value: suggestedAddress,
      text: (
        <div styleName="address-row">
          <div>{suggestedAddress}</div>
          <div styleName="address-row-legend">Standardized address</div>
        </div>
      ),
    },

    {
      value: fullAddress,
      text: (
        <div styleName="address-row">
          <div>{fullAddress}</div>
          <div styleName="address-row-legend">Your entry</div>
        </div>
      ),
    },
  ];
  return (
    <Container data-testid="select-address-modal">
      <HeaderSection>
        <h1>Address Standardization</h1>
        <h2>Can you confirm the correct address for your new property?</h2>
      </HeaderSection>
      <ContentSection>
        <Radio
          styleName="addresses"
          value={selectedAddress}
          options={options}
          name="address"
          required={true}
          disabled={false}
          onChange={value => setSelectedAddress(value!)}
          error={""}
        />
      </ContentSection>
      <ButtonSection>
        <PrimaryButtons>
          <Button styleVariant="secondary" size="medium" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            type="submit"
            styleVariant="primary"
            size="medium"
            onClick={() => {
              onSelect(selectedAddress);
            }}
          >
            Save
          </Button>
        </PrimaryButtons>
      </ButtonSection>
    </Container>
  );
};
