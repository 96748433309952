import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import { AuthContext } from "../../Authorization/AuthContext";
import { Button } from "../../Common/Button";
import {
  useAssignTaskMutation,
  useDashboardCountsQuery,
} from "../../../generated/graphql";

import "./__styles__/Dashboard.scss";
import { FlexRow } from "../../Common/Layout";

export default function Dashboard() {
  const history = useHistory();
  const { admin } = useContext(AuthContext);

  const { data, loading, error } = useDashboardCountsQuery({
    variables: { adminId: admin!.id },
    fetchPolicy: "no-cache",
    onError: error => {
      const unauthorized =
        error.graphQLErrors[0]?.extensions?.code === "UNAUTHENTICATED";
      if (unauthorized) {
        history.push("/admin/login");
      }
    },
  });

  const [assignTask, { loading: assignLoading }] = useAssignTaskMutation({
    refetchQueries: ["dashboardCounts"],
    onCompleted: ({ assignTranscriptionTask }) => {
      // We'll get null back if there are no tasks available,
      // which will refetch the count and remove the button
      if (assignTranscriptionTask) history.push("/task");
    },
  });

  if (error) return <em>There was a problem loading your dashboard</em>;

  const availableCount =
    data?.available && data?.assigned
      ? data?.available.count + data?.assigned.count
      : 0;
  const completedVersion = data?.completedVersion.count;
  const completedFullData = data?.completedFullData.count;
  const completedValidation = data?.completedValidation.count;
  const allowedToViewAccounts = admin?.allowedToViewDashboard;

  return (
    <div styleName="dashboard">
      <nav>
        <h1>👋 Hello</h1>
        <FlexRow style={{ gap: "8px" }}>
          <Button
            styleVariant="secondary"
            size="small"
            onClick={() => {
              window.location.href = "/logout";
            }}
          >
            Log out
          </Button>
          {allowedToViewAccounts && (
            <Button
              size="small"
              styleVariant="primary"
              onClick={() => {
                window.location.href = "/admin";
              }}
            >
              Go to accounts
            </Button>
          )}
        </FlexRow>
      </nav>

      {!loading && (
        <>
          <h2>📖 Jobs Available</h2>
          <ul>
            <li>
              <h3>Document Transcription</h3>
              <span styleName="highlight">
                {availableCount.toLocaleString()} documents available
              </span>
              {!!availableCount && (
                <Button
                  onClick={async () => {
                    await assignTask();
                  }}
                  size="small"
                  styleVariant="primary"
                  disabled={assignLoading}
                >
                  Start Transcribing
                </Button>
              )}
            </li>
          </ul>

          <h2>✅ Jobs Completed</h2>
          <ul>
            <li>
              <h3>Version Extraction</h3>
              <span>
                {completedVersion?.toLocaleString()} documents transcribed
              </span>
            </li>
            <li>
              <h3>Full Data Extraction</h3>
              <span>
                {completedFullData?.toLocaleString()} documents transcribed
              </span>
            </li>
            <li>
              <h3>Validation</h3>
              <span>
                {completedValidation?.toLocaleString()} documents validated
              </span>
            </li>
          </ul>
        </>
      )}
    </div>
  );
}
