import React, { useState } from "react";
import { get, useFormContext } from "react-hook-form";
import { Label, Select } from "../../../../../../Inputs";
import { Text } from "../../../../../../Inputs/react-hook-form";
import {
  SubmissionsBuilderFormDataStructure,
  InputFieldPath,
} from "../../types";
import { WidgetSettingsContainer } from "./__styles__/WidgetSettings";

import { LabelAndRequired } from "./shared";
import { FlexRow } from "../../../../../../Common/Layout";
import { setNumberValueAs } from "../../../../../../../utils/react-hook-form";

const prefixPostfixOptions = [
  {
    label: "%",
    value: "%",
  },
  {
    label: "$",
    value: "$",
  },
];

const NumberWidgetSettings = ({
  fieldPath,
  isModuleInput,
}: {
  fieldPath: InputFieldPath;
  isModuleInput: boolean;
}) => {
  const {
    formState: { errors },
    register,
    setValue,
    getValues,
  } = useFormContext<SubmissionsBuilderFormDataStructure>();

  const initialValues = getValues([
    `${fieldPath}.prefix`,
    `${fieldPath}.postfix`,
  ]);

  const [prefixPostfixValue, setPrefixPostfixValue] = useState<
    string | undefined
  >(initialValues[0] || initialValues[1]);

  const setPrefixPostfix = (value?: Maybe<string>) => {
    setPrefixPostfixValue(value ?? undefined);
    switch (value) {
      case "%":
        setValue(`${fieldPath}.prefix`, undefined, { shouldValidate: true });
        setValue(`${fieldPath}.postfix`, value, { shouldValidate: true });
        return;
      case "$":
        setValue(`${fieldPath}.postfix`, undefined, { shouldValidate: true });
        setValue(`${fieldPath}.prefix`, value, { shouldValidate: true });
        return;
      default:
    }
  };

  return (
    <WidgetSettingsContainer>
      <LabelAndRequired fieldPath={fieldPath} isModuleInput={isModuleInput} />
      <div>
        <Label text={"Placeholder text"} />
        <Text {...register(`${fieldPath}.placeholder`)} />
      </div>
      <div>
        <Text
          {...register(`${fieldPath}.step`, {
            setValueAs: setNumberValueAs,
          })}
          label="Step value"
          type="number"
          error={get(errors, `${fieldPath}.step.message`, "")}
        />
      </div>
      <Select
        name={`${fieldPath}.prefixPostfix`}
        options={prefixPostfixOptions}
        value={prefixPostfixValue}
        onChange={value => setPrefixPostfix(value)}
        label="Format"
        disabled={isModuleInput}
      />
      <FlexRow style={{ gap: "16px" }}>
        <Text
          {...register(`${fieldPath}.minimum`, {
            setValueAs: setNumberValueAs,
            deps: [`${fieldPath}.maximum`],
          })}
          label="Number minimum"
          type="number"
          error={get(errors, `${fieldPath}.minimum.message`, "")}
        />
        <Text
          {...register(`${fieldPath}.maximum`, {
            setValueAs: setNumberValueAs,
            deps: [`${fieldPath}.minimum`],
          })}
          label="Number maximum"
          type="number"
          error={get(errors, `${fieldPath}.maximum.message`, "")}
        />
      </FlexRow>
    </WidgetSettingsContainer>
  );
};

export default NumberWidgetSettings;
