import React from "react";
import { useToasts } from "react-toast-notifications";
import { track } from "../../utils/tracking";

import {
  useDeleteUserInviteMutation,
  useReinviteUserMutation,
} from "../../generated/graphql";
import { buildLink } from "common/routing";
import { DropdownMenu } from "../Inputs";
import { ActionsProps } from "../Inputs/DropdownMenu";

export interface UserInviteActionsProps {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  onUpdate: () => {};
  appUrl?: string;
  clipboard?: Pick<typeof navigator.clipboard, "writeText">;
}

const UserInviteActions = ({
  id,
  firstName,
  lastName,
  email,
  onUpdate,
  appUrl = window.env.APPLICATION_URL,
  clipboard = navigator.clipboard,
}: UserInviteActionsProps) => {
  const { addToast } = useToasts();

  const [deleteUserInvite, { loading: loadingDeletion }] =
    useDeleteUserInviteMutation({
      onCompleted: () => {
        addToast(`${firstName} ${lastName}'s invitation has been removed`, {
          appearance: "success",
          autoDismiss: true,
        });
        onUpdate();
      },
      onError: () => {
        addToast(
          "There was an error deleting this user invite. Please try again. If the problem persists, please email us at support@withforerunner.com",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      },
    });

  const [reinviteUser, { loading: loadingReinvite }] = useReinviteUserMutation({
    onCompleted: () => {
      track("User reinvited", { reinvitedUser: email });
      addToast(
        `An invitation to create their account was sent to ${firstName} ${lastName}`,
        {
          appearance: "success",
          autoDismiss: true,
        }
      );
    },
    onError: () => {
      addToast(
        "There was an error reinviting this user. Please try again. If the problem persists, please email us at support@withforerunner.com",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    },
  });

  const handleDelete = async () => {
    if (loadingDeletion) return;
    await deleteUserInvite({ variables: { inviteId: id } });
  };

  const handleReinvite = async () => {
    if (loadingReinvite) return;
    await reinviteUser({ variables: { inviteId: id } });
  };

  const copyLink = async () => {
    await clipboard.writeText(
      `${appUrl}${buildLink("acceptInvite", {
        inviteId: id,
      })}`
    );
    addToast("Link copied to clipboard", {
      appearance: "success",
      autoDismiss: true,
    });
  };

  const actions: Array<ActionsProps> = [
    {
      label: "Delete invitation",
      onClick: handleDelete,
    },
    {
      label: "Resend invitation",
      onClick: handleReinvite,
    },
    {
      label: "Copy invitation link",
      onClick: copyLink,
    },
  ];
  return <DropdownMenu actions={actions} />;
};

export default UserInviteActions;
