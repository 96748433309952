import React from "react";
import { Icon, IconNames } from "../../Icons/LucideIcons";
import {
  AttributeLabel,
  AttributeWrapper,
} from "../../Filter/__styles__/Filters";
import { FlexRow } from "../../Layout";

export const SettingsRow = ({
  label,
  leftIcon,
  rightIcon,
  onRowClick,
  onRightIconClick,
  disabled,
  testId,
  omitHoverStyles,
}: {
  label: string;
  leftIcon?: IconNames;
  rightIcon?: IconNames;
  onRowClick?: () => void;
  onRightIconClick?: () => void;
  disabled?: boolean;
  testId?: string;
  omitHoverStyles?: boolean;
}) => {
  return (
    <AttributeWrapper
      onClick={() => {
        !disabled && onRowClick?.();
      }}
      data-testid={testId}
      omitHoverStyles={omitHoverStyles || disabled}
    >
      <FlexRow style={{ gap: "4px" }}>
        {leftIcon && <Icon iconName={leftIcon} color={"grey4"} size={16} />}
        <AttributeLabel disabled={disabled} data-testid={`${testId}-text`}>
          <div data-testid={"spam"}>{label}</div>
        </AttributeLabel>
      </FlexRow>
      {rightIcon && (
        <Icon
          iconName={rightIcon}
          color={"grey4"}
          size={16}
          onClick={event => {
            if (onRightIconClick && !disabled) {
              event.stopPropagation();
              onRightIconClick();
            }
          }}
          data-testid={`${testId}-right-icon`}
          style={{ cursor: "pointer", minWidth: "16px" }}
        />
      )}
    </AttributeWrapper>
  );
};
