import React, { FC, useContext, useEffect, useState } from "react";
import {
  BuilderSidebarHeader,
  BuilderSidebarWrapper,
} from "../__styles__/SubmissionsBuilder";
import { useFormContext, useWatch } from "react-hook-form";
import { Button } from "../../../../../Common/Button";
import { Icon } from "../../../../../Common/Icons/LucideIcons";
import { SubmissionsBuilderFormDataStructure, InputFieldPath } from "../types";
import TextWidgetSettings from "./FieldSettings/TextWidgetSettings";
import NumberWidgetSettings from "./FieldSettings/NumberFieldSettings";
import DateWidgetSettings from "./FieldSettings/DateWidgetSettings";
import TextareaWidgetSettings from "./FieldSettings/TextareaWidgetSettings";
import CheckboxWidgetSettings from "./FieldSettings/CheckboxWidgetSettings";
import ObjectFieldTemplateSettings from "./FieldSettings/ObjectFieldTemplateSettings";
import PropertyMarketFieldSettings from "./FieldSettings/PropertyMarketValueFieldSettings";
import DamageDegreeWidgetSettings from "./FieldSettings/DamageDegreeWidgetSettings";
import DocumentUploadFieldSettings from "./FieldSettings/DocumentUploadFieldSettings";
import DropdownWidgetSettings from "./FieldSettings/DropdownWidgetSettings";
import TagsWidgetSettings from "./FieldSettings/TagsWidgetSettings";
import NumberWithUnitsFieldSettings from "./FieldSettings/NumberWithUnitsFieldSettings";
import { MODULES, SubmissionBuilderFieldType } from "../constants";
import { SubmissionsBuilderContextInstance } from "../context";
import Disclaimer from "../../../../../Common/Disclaimer";
import { formatCollection, pluralize } from "common/utils/strings";
import { SUBMISSION_TYPE_MODULE } from "common/constants";
import { getAllInputNames } from "../utils";

const ModuleDisclaimer = ({
  modules,
}: {
  modules: Array<SUBMISSION_TYPE_MODULE>;
}) => {
  const moduleNames = modules.map(
    module => MODULES.find(m => m.type === module)!.label
  );

  const formattedNoun = pluralize({
    count: modules.length,
    options: { singular: "module", plural: "modules" },
  });

  return (
    <div style={{ marginBottom: "16px" }}>
      <Disclaimer
        message={`This field is part of ${formatCollection(
          moduleNames,
          "and"
        )} ${formattedNoun}, some editing may be limited. To remove the ${formattedNoun}, delete any associated field.`}
      />
    </div>
  );
};

const fieldKeyToSettingsFormMap: Record<
  SubmissionBuilderFieldType,
  FC<{
    isModuleInput: boolean;
    name: string;
    inputType: SubmissionBuilderFieldType;
    fieldPath: `inputs.${number}`;
  }>
> = {
  text: TextWidgetSettings,
  textarea: TextareaWidgetSettings,
  number: NumberWidgetSettings,
  NumberWithUnits: NumberWithUnitsFieldSettings,
  date: DateWidgetSettings,
  checkbox: CheckboxWidgetSettings,
  group: ObjectFieldTemplateSettings,
  PropertyMarketValue: PropertyMarketFieldSettings,
  DamageDegree: DamageDegreeWidgetSettings,
  DocumentUploader: DocumentUploadFieldSettings,
  select: DropdownWidgetSettings,
  Tags: TagsWidgetSettings,
};

const FieldSettingsView = () => {
  const { watch } = useFormContext<SubmissionsBuilderFormDataStructure>();
  const { selectedField, toggleFormSettingsMode } = useContext(
    SubmissionsBuilderContextInstance
  );

  const [associatedModules, setAssociatedModules] = useState<
    Array<SUBMISSION_TYPE_MODULE>
  >([]);

  let title = useWatch<
    SubmissionsBuilderFormDataStructure,
    `${InputFieldPath}.title`
  >({
    name: `${selectedField!.fieldPath}.title`,
  });

  if (!title && selectedField?.inputType === "checkbox") {
    title = "Checkbox";
  }

  const modules = watch("modules");
  useEffect(() => {
    setAssociatedModules(
      MODULES.filter(module => modules.includes(module.type))
        .filter(module => {
          const inputNames = getAllInputNames([...module.inputs]);
          return inputNames.some(
            inputName => inputName === selectedField?.name
          );
        })
        .map(module => module.type)
    );
  }, [modules, selectedField?.name]);

  const FieldSettingsForm = fieldKeyToSettingsFormMap[selectedField!.inputType];

  if (!selectedField) {
    return <></>;
  }

  // when a field is selected, the side column changes its content to show the field settings
  // when you're in field mode, you can navigate BACK to selected fields
  return (
    <BuilderSidebarWrapper>
      <BuilderSidebarHeader>
        <Button
          css={{ padding: 0 }}
          styleVariant="ghost"
          onClick={toggleFormSettingsMode}
        >
          <Icon color="grey4" iconName="chevron-left" size={16} />
        </Button>
        <p
          style={{
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "1.4",
            margin: 0,
            padding: 0,
          }}
        >{`${title} properties`}</p>
      </BuilderSidebarHeader>
      {!!associatedModules.length && (
        <ModuleDisclaimer modules={associatedModules} />
      )}
      {/* adding `key` prop here to force re-mounting of component on selectedField change */}
      <FieldSettingsForm
        key={selectedField.name}
        {...selectedField}
        isModuleInput={!!associatedModules.length}
      />
    </BuilderSidebarWrapper>
  );
};

export default FieldSettingsView;
