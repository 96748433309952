import React, { useContext } from "react";

import { Box, FlexColumn } from "../../Common/Layout";
import Divider from "../../Common/Divider";
import {
  useGetAccountSecurityConfigurationQuery,
  useUpdateAccountMultifactorAuthMutation,
  useUpdateAccountSecurityConfigurationMutation,
} from "../../../generated/graphql";
import { SecurityConfigurationForm } from "./SecurityConfigurationForm";
import { AuthContext } from "../../Authorization/AuthContext";
import { InsufficientPermissionsBanner } from "./InsufficientPermissionsBanner";
import { RESOURCE_NAME } from "common/authorization";

import { Header, HeaderTitle, Footer } from "../__styles__/Content";
import { MultifactorAuthSettingsForm } from "./MultifactorAuthSettingsForm";
import { useStatusToasts } from "../../../hooks/useStatusToasts";

export const Security = () => {
  const { account, authorized } = useContext(AuthContext);

  const { addSuccessToast, addFailureToast } = useStatusToasts();

  const { data, loading, error } = useGetAccountSecurityConfigurationQuery();

  const [updateAccountSecurityConfigurationMutation] =
    useUpdateAccountSecurityConfigurationMutation({
      onCompleted: () => {
        addSuccessToast(
          "Your account security settings were successfully updated."
        );
      },
      onError: () => {
        addFailureToast(
          "There was a problem updating your account security settings. Please try again or contact support@withforerunner.com"
        );
      },
      update: (cache, result) => {
        const newSecurityConfiguration =
          result.data?.updateAccountSecurityConfiguration!;
        cache.modify({
          id: cache.identify(account!),
          fields: {
            securityConfiguration() {
              return { ...newSecurityConfiguration };
            },
          },
        });
      },
    });

  const [updateAccountMultifactorAuthMutation] =
    useUpdateAccountMultifactorAuthMutation({
      onCompleted: () => {
        addSuccessToast(
          "Your account Multi-Factor Authentication settings were successfully updated."
        );
      },
      onError: () => {
        addFailureToast(
          "There was a problem updating your account multi-factor authentication settings. Please try again or contact support@withforerunner.com"
        );
      },
    });

  const securityConfiguration = data?.account?.securityConfiguration;

  if (error || (!loading && !securityConfiguration)) {
    throw new Error("Error loading security configruation");
  }

  const canUpdateAccountInformation = authorized({
    resource: RESOURCE_NAME.ACCOUNT,
    permission: "update",
  });

  return (
    <>
      <FlexColumn style={{ gap: "20px" }}>
        {!canUpdateAccountInformation && <InsufficientPermissionsBanner />}
        <Box>
          <Header>
            <HeaderTitle>Security settings</HeaderTitle>
          </Header>
          <Divider />
          {loading ? (
            "loading..."
          ) : (
            <SecurityConfigurationForm
              canUpdateAccountInformation={canUpdateAccountInformation}
              securityConfiguration={securityConfiguration!}
              onUpdate={updateAccountSecurityConfigurationMutation}
            />
          )}
          <Footer />
        </Box>
        <Box>
          <Header>
            <HeaderTitle>Multi-Factor Authentication settings</HeaderTitle>
          </Header>
          <Divider />
          {loading ? (
            "loading..."
          ) : (
            <MultifactorAuthSettingsForm
              canUpdateAccountInformation={canUpdateAccountInformation}
              securityConfiguration={securityConfiguration!}
              onUpdate={updateAccountMultifactorAuthMutation}
            />
          )}
          <Footer />
        </Box>
      </FlexColumn>
    </>
  );
};

export default Security;
