import React from "react";
import { uniqBy } from "lodash";
import { StylesConfig } from "react-select";
import { formatCoordinates } from "common/utils/coordinates";

import { Select } from "../../Inputs";
import Icon, { Icons, ICON_COLORS } from "../../Common/Icons";
import { InformationalTooltip } from "../../Common/Tooltip";
import { AuthContext } from "../../Authorization/AuthContext";
import { useSharePropertyInfoModal } from "./SharePropertyModal";
import { track } from "../../../utils/tracking";
import PropertyOptions from "./PropertyOptions";
import SharePropertyInfoButton from "./SharePropertyInfoButton";
import { PropertyWithCoordinates } from "../utils";
import { HeaderParcel } from "./types";
import { Icon as LucideIcon } from "../../Common/Icons/LucideIcons";

import {
  Address,
  AddressSelector,
  ButtonRow,
  Header,
  MultipleAddressTooltip,
  ParcelIdRow,
  PrivatedInfo,
  TooltipText,
} from "./__styles__/Header";
import { getValueForParcelFieldNameOverride } from "common-client/utils/parcels";

const MultiplePropertiesTooltip = () => {
  const popoverContent = (
    <TooltipText>
      Warning: There are multiple addresses within this parcel.
    </TooltipText>
  );
  return (
    <MultipleAddressTooltip>
      <InformationalTooltip
        popoverContent={popoverContent}
        place="bottom"
        iconProps={{ color: "grey5" }}
      />
    </MultipleAddressTooltip>
  );
};

interface AddressSelectOption {
  value: string;
  label: string;
}

const getAddressOrCoords = (property: PropertyWithCoordinates) => {
  return (
    property.fullAddress ??
    formatCoordinates({
      latitude: property.latitude!,
      longitude: property.longitude!,
    })
  );
};

interface AddressPanelHeaderProps {
  loading: boolean;
  property: Maybe<PropertyWithCoordinates>;
  properties: Array<PropertyWithCoordinates>;
  parcel: HeaderParcel;
  latitude: number;
  longitude: number;
  onClose: () => void;
  selectProperty: (property: NonNullable<PropertyWithCoordinates>) => void;
}

export default ({
  loading,
  property,
  properties,
  parcel,
  latitude,
  longitude,
  onClose,
  selectProperty,
}: AddressPanelHeaderProps) => {
  const { account } = React.useContext(AuthContext);

  let options = properties.map(property => {
    const label = getAddressOrCoords(property);
    return {
      value: property.id,
      label,
      // Despite the SelectOption type not including this in its definition,
      // we need to be able to access property data once the option is selected
      property,
    };
  });

  options = uniqBy(options, "value");

  const styles: StylesConfig<AddressSelectOption, false> = {
    option: (provided, state) => {
      const backgroundColor = state.isFocused ? "#f3f3f3" : undefined;
      return { ...provided, backgroundColor, color: "#171e2d" };
    },
    control: provided => {
      const colors = { backgroundColor: "#7e8490", borderStyle: undefined };
      return { ...provided, ...colors };
    },
    singleValue: provided => {
      return { ...provided, color: "#ffffff" };
    },
    indicatorsContainer: () => {
      return {};
    },
  };

  const showAddressSelect = options.length > 1;

  const [showSharePropertyModal, hideSharePropertyModal] =
    useSharePropertyInfoModal({
      onCancel: () => {
        hideSharePropertyModal();
      },
      onSubmit: () => {
        hideSharePropertyModal();
      },
      property,
    });

  const propertyHiddenFromPublic = !!property?.hiddenFromPublic;
  const parcelIdLabel = getValueForParcelFieldNameOverride({
    overrides: account?.parcelFieldNameOverrides,
    name: "parcelId",
  });

  return (
    <Header data-testid="header">
      <ParcelIdRow>
        <div style={{ display: "flex" }}>
          {`${parcelIdLabel}: `}
          {parcel?.parcelNumber || "--"}
          {showAddressSelect && <MultiplePropertiesTooltip />}
        </div>
        <div
          style={{ width: "12px", height: "12px" }}
          data-testid="close-address-panel"
        >
          <Icon
            icon={Icons.CLOSE}
            color={ICON_COLORS.WHITE}
            hoverable
            onClick={onClose}
          />
        </div>
      </ParcelIdRow>
      <AddressSelector>
        {!loading && showAddressSelect && property ? (
          <Select
            name="type"
            options={options}
            value={property.id}
            styles={styles}
            dropdownIndicatorColor={ICON_COLORS.WHITE}
            onChange={selectedValue => {
              const selectedProperty = options.find(
                ({ value }) => value === selectedValue
              )?.property;

              if (selectedProperty) {
                selectProperty(selectedProperty);
              }
            }}
          />
        ) : (
          <Address>
            {loading ? (
              <div>
                <em>Loading...</em>
              </div>
            ) : (
              property && getAddressOrCoords(property)
            )}
          </Address>
        )}
      </AddressSelector>
      <ButtonRow data-testid="header-buttons">
        {property && account?.publicPortal.enabled && (
          <SharePropertyInfoButton
            onClick={() => {
              track("Share property info button clicked", {
                streetAddress: property.streetAddress,
                city: property.city,
              });
              showSharePropertyModal();
            }}
            disabled={propertyHiddenFromPublic}
          />
        )}
        {!loading && (
          <PropertyOptions
            latitude={latitude}
            longitude={longitude}
            parcel={parcel}
            property={property}
          />
        )}
      </ButtonRow>
      {propertyHiddenFromPublic && (
        <PrivatedInfo>
          <LucideIcon iconName="eye-off" color="grey6" size={16} />
          <div>Property hidden from public</div>
        </PrivatedInfo>
      )}
    </Header>
  );
};
