import React from "react";
import { isNil } from "lodash";

import { midnightET } from "common/utils/dates";
import {
  ParcelImportConfiguration,
  ParcelImportFrequency,
  ParcelImportTaskType,
} from "../../../../generated/graphql";
import Icon, { Icons } from "../../../Common/Icons";
import FileDisplay from "../../../Inputs/FileDisplay";
import { Container, Info, Row, Url } from "../__styles__/Row";
import ActionButton from "./ActionButton";

const IMPORT_FREQUENCY_MESSAGE_MAP = {
  [ParcelImportFrequency.DAILY]: "daily",
  [ParcelImportFrequency.WEEKLY]: "weekly on Sundays",
  [ParcelImportFrequency.BIWEEKLY]: "the first and 15th day of each month",
  [ParcelImportFrequency.MONTHLY]: "on the first day of each month",
};

const getRefreshTime = (frequency?: Maybe<ParcelImportFrequency>) => {
  if (isNil(frequency)) {
    return "";
  }

  let targetTime = midnightET();

  if (frequency === ParcelImportFrequency.DAILY) {
    targetTime.setHours(targetTime.getHours() + 5);
  }

  return targetTime.toLocaleTimeString("en-US", {
    hour12: true,
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short",
  });
};

export default ({
  parcelImportConfiguration,
  onUpdate,
  filename,
}: {
  parcelImportConfiguration: ParcelImportConfiguration;
  onUpdate: () => void;
  filename?: Maybe<string>;
}) => {
  const frequencyMessage = parcelImportConfiguration.frequency
    ? IMPORT_FREQUENCY_MESSAGE_MAP[parcelImportConfiguration.frequency]
    : "";

  const refreshTime = getRefreshTime(parcelImportConfiguration.frequency);

  const isArcGIS =
    parcelImportConfiguration.importType === ParcelImportTaskType.ARCGIS;

  return (
    <Container>
      <Row data-testid="configuration">
        <FileDisplay
          fileIcon={isArcGIS ? Icons.ARC_GIS : Icons.GPKG}
          titleText={parcelImportConfiguration.name}
        >
          {isArcGIS ? (
            <Url
              href={parcelImportConfiguration.url!}
              target="_blank"
              data-testid="arcgis-url"
            >
              {parcelImportConfiguration.url}
            </Url>
          ) : (
            <span>{filename}</span>
          )}
        </FileDisplay>

        <Info data-testid="info">
          {isArcGIS && (
            <>
              <Icon icon={Icons.REFRESH} />
              <span>
                Refreshes {frequencyMessage} at {refreshTime}
              </span>
            </>
          )}
          <ActionButton
            parcelImportConfiguration={parcelImportConfiguration}
            onUpdate={onUpdate}
          />
        </Info>
      </Row>
    </Container>
  );
};
