import _ from "lodash";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { getForm } from "./Forms";
import { Button } from "../../Common/Button";
import { useSimpleForm } from "../../Common/simpleForm";
import { FormTitle } from "./Forms/Common";
import {
  useCurrentTaskQuery,
  useAssignTaskMutation,
  useSubmitTaskMutation,
  useUnassignTaskMutation,
  CurrentTaskQuery,
} from "../../../generated/graphql";
import { useFlagTaskModal } from "./FlagTaskModal";

import { useStatusToasts } from "../../../hooks/useStatusToasts";
import { sleep } from "common/utils/tools";
import {
  ButtonRow,
  Buttons,
  DangerText,
  FileViewer,
  SpanWrapper,
  TaskContainer,
  WorkArea,
} from "./__styles__/Task";

export default () => {
  const { data, loading } = useCurrentTaskQuery({
    fetchPolicy: "no-cache",
  });

  if (loading) return null;

  if (!data?.currentTranscriptionTask) return null;

  return (
    <Form
      task={data.currentTranscriptionTask}
      availableTasks={data?.availableTasks}
    />
  );
};

export type TaskType = NonNullable<
  CurrentTaskQuery["currentTranscriptionTask"]
>;
type AvailableTasksType = NonNullable<CurrentTaskQuery["availableTasks"]>;

const Form = ({
  task,
  availableTasks,
}: {
  task: TaskType;
  availableTasks: AvailableTasksType;
}) => {
  const history = useHistory();
  const [input, get, set] = useSimpleForm();
  const [processing, setProcessing] = useState(false);
  const { addSuccessToast, addFailureToast } = useStatusToasts();
  const [formYear, setFormYear] = useState<number | null | undefined | string>(
    task.certificateUpload.formExpirationYear
  );
  const [doAnother, setDoAnother] = useState(false);

  const documentUrl = task.certificateUpload.documentUpload.url;
  const accountId = task.certificateUpload?.accountId;
  const availableTasksCount = availableTasks?.count;

  const exit = () => history.push("/dashboard");

  const [submitTask, { data: submitData, called: submitCalled }] =
    useSubmitTaskMutation({
      onCompleted: async ({ submitTask: { result } }) => {
        const next = async () => (doAnother ? assignTask() : exit());
        switch (result) {
          case "accepted":
            await next();
            break;
          case "resubmitted":
            addSuccessToast("This task has already been submitted");
            //Toast messages dissappear when we reload the page inside assignTask
            //This sleep gives the user a chance to read the toast message
            await sleep(700).then(next);
            break;
          case "missing":
            addFailureToast(
              "Unable to submit this task. Task may have been reassigned to another user"
            );
            await sleep(700).then(next);
            break;
          default:
            setProcessing(false);
        }
      },
      onError: () => {
        setProcessing(false);
        addFailureToast(
          "There was an issue submitting your transcription. Please try again. If the problem persists, please email us at support@withforerunner.com"
        );
      },
    });

  let errors;

  if (task.type === "validation") {
    errors = task.certificateUpload.validationErrors || {};
  } else if (task.type === "geocoding") {
    errors = task.transcribedData.errors || {};
  } else {
    errors = submitData?.submitTask?.errors || {};
  }

  const handleSubmit = async () => {
    // Prevent accidental double submissions
    if (processing) return;

    setProcessing(true);

    if (input.topOfBottomFloorAlt) {
      input.topOfBottomFloor = input.topOfBottomFloorAlt;
      delete input.topOfBottomFloorAlt;
    }

    await submitTask({
      variables: {
        id: task.id,
        input,
        expirationYear:
          typeof formYear === "string" ? parseInt(formYear) : formYear,
        overrideValidation:
          (submitCalled && task.type !== "version_extraction") ||
          task.type === "validation",
      },
    });
  };

  const [assignTask] = useAssignTaskMutation({
    onCompleted: ({ assignTranscriptionTask }) => {
      // We'll get null back if there are no tasks available
      // FIXME: This does a full page reload. How can we do a SPA reload?
      if (assignTranscriptionTask) history.go(0);
      else exit();
    },
  });

  const [unassignTask] = useUnassignTaskMutation({
    variables: { id: task.id },
    onCompleted: exit,
    onError: error => {
      if (error.graphQLErrors[0]?.extensions?.code === "USER_ERROR") {
        addFailureToast(error.message);
        exit();
      } else {
        addFailureToast("There was an error, please try again");
      }
    },
  });

  const handleExit = async () => {
    setProcessing(true);
    await unassignTask();
  };

  const [showModal] = useFlagTaskModal({
    taskId: task.id,
    enableForm: () => setProcessing(false),
    assignTask,
  });

  const openFlagTaskModal = () => {
    setProcessing(true);

    showModal();
  };

  useEffect(() => {
    const upload = task.certificateUpload;

    const typesToTranscribeDataFor = [
      "validation",
      "geocoding",
      "full_data_extraction",
    ];
    if (upload && typesToTranscribeDataFor.includes(task.type)) {
      for (const [key, val] of Object.entries(task.transcribedData || {})) {
        set(key)(val);
      }
    }
    // There is no current task—send user back to dashboard
    if (!task) exit();
  }, [task, history]);

  // We determine the form to show based on the given Task. See `getTask`
  // above and ./Forms/index.js for more information.
  const { Form, title: formTitle } = getForm(task);
  if (!Form) throw new Error("Could not determine form to show for Task");

  const hasErrors = !_.isEmpty(errors);

  const btnText = hasErrors
    ? "Confirm validation issues"
    : "Submit & continue to next doc";

  return (
    <TaskContainer>
      <WorkArea>
        {formTitle && <FormTitle>{formTitle}</FormTitle>}
        <Form
          get={get}
          set={set}
          data={input}
          errors={errors}
          // @ts-ignore
          accountId={accountId}
          handleFormYearChange={setFormYear}
          year={formYear}
          setProcessing={setProcessing}
        />

        <Buttons>
          <ButtonRow>
            <Button
              size="small"
              onClick={async () => {
                setDoAnother(true);
                await handleSubmit();
              }}
              disabled={processing}
              styleVariant={hasErrors ? "alert" : "primary"}
            >
              {btnText}
            </Button>
            <SpanWrapper>
              {availableTasksCount.toLocaleString()} documents remaining
            </SpanWrapper>
          </ButtonRow>
          <ButtonRow>
            {hasErrors ? (
              <DangerText>
                We found issues with the transcribed data. Please double check
                the highlighted fields to make sure they are accurate. If the
                transcribed fields match the document, please click Confirm
                validation issues to move forward.
              </DangerText>
            ) : null}
          </ButtonRow>
          <ButtonRow>
            <Button
              size="small"
              styleVariant="outlineLight"
              onClick={openFlagTaskModal}
              disabled={processing}
            >
              Flag & skip doc
            </Button>
            <span>Flag doc for having an issue and continue to next doc</span>
          </ButtonRow>
          <ButtonRow>
            <Button
              size="small"
              styleVariant="outlineLight"
              onClick={async () => {
                setDoAnother(false);
                await handleSubmit();
              }}
              disabled={processing}
            >
              Submit & exit
            </Button>
            <span>Submit document and stop transcribing for now</span>
          </ButtonRow>
          <ButtonRow>
            <Button
              size="small"
              styleVariant="secondary"
              onClick={handleExit}
              disabled={processing}
            >
              Exit
            </Button>
            <span>Stop transcribing document</span>
          </ButtonRow>
        </Buttons>
      </WorkArea>
      <FileViewer>
        <iframe src={documentUrl} />
      </FileViewer>
    </TaskContainer>
  );
};
