import React, { useContext } from "react";
import { useModal } from "react-modal-hook";
import { AuthContext } from "../Authorization/AuthContext";
import { RESOURCE_NAME } from "common/authorization";
import Modal from "../Common/Modal";
import ExportDataForm from "./ExportDataForm";
import { Button } from "../Common/Button";

interface ExportButtonProps {
  firms?: Array<{ id: string; name: string }>;
}

const useExportFormModal = ({
  firms,
}: ExportButtonProps): ReturnType<typeof useModal> => {
  const [showExportModal, hideExportModal] = useModal(
    () => (
      <Modal
        onRequestClose={hideExportModal}
        shouldReturnFocusAfterClose
        shouldFocusAfterRender
        aria={{
          labelledby: "export-form-heading",
        }}
      >
        <ExportDataForm firms={firms} closeModal={hideExportModal} />
      </Modal>
    ),
    [firms]
  );

  return [showExportModal, hideExportModal];
};

export default ({ firms }: ExportButtonProps) => {
  const { authorized } = useContext(AuthContext);
  const [showExportModal] = useExportFormModal({ firms });
  const canExportCertificates = authorized({
    resource: RESOURCE_NAME.CERTIFICATE,
    permission: "export",
  });

  const canExportSubmissions = authorized({
    resource: RESOURCE_NAME.SUBMISSION,
    permission: "export",
  });

  return (
    <Button
      onClick={showExportModal}
      size="small"
      styleVariant="secondary"
      disabled={!canExportCertificates && !canExportSubmissions}
      aria-label="Export"
      leftIconName="download"
    >
      Export
    </Button>
  );
};
