import React from "react";
import { useFormContext } from "react-hook-form";

import { ParcelImportFieldConfig, ParcelImportWizardFormData } from "../types";
import { Icon } from "../../../../../Common/Icons/LucideIcons";
import { SideIconButton } from "../__styles__/ParcelImportWizard";
import ImportFieldSourceSelect from "./ImportFieldSourceSelect";
import ImportFieldSourceText from "./ImportFieldSourceText";

const ImportFieldSourceSwapper = ({
  disabled,
  fieldConfig,
  fieldPath,
}: {
  disabled: boolean;
  fieldConfig: ParcelImportFieldConfig;
  fieldPath: `mapping.${string}`;
}) => {
  const sourceFieldPath = `${fieldPath}.source` as const;
  const fieldTypeFieldPath = `${fieldPath}.fieldType` as const;

  const { getValues, setValue, unregister } =
    useFormContext<ParcelImportWizardFormData>();

  const fieldType = getValues(fieldTypeFieldPath);
  const usingHardcodedInput = fieldType === "HARDCODED";
  const swapperLabel = `Swap ${sourceFieldPath} Input`;

  const swapInputs = () => {
    unregister(sourceFieldPath);
    setValue(fieldTypeFieldPath, usingHardcodedInput ? "MAPPED" : "HARDCODED");
  };

  return (
    <>
      {usingHardcodedInput ? (
        <ImportFieldSourceText
          disabled={disabled}
          fieldConfig={fieldConfig}
          fieldName={sourceFieldPath}
        />
      ) : (
        <ImportFieldSourceSelect
          disabled={disabled}
          fieldConfig={fieldConfig}
          fieldName={sourceFieldPath}
        />
      )}
      {!disabled && (
        <SideIconButton
          onClick={swapInputs}
          styleVariant={"ghost"}
          aria-label={swapperLabel}
        >
          <Icon iconName={"arrow-left-right"} color={"grey4"} size={16} />
        </SideIconButton>
      )}
    </>
  );
};

export default ImportFieldSourceSwapper;
