import React, { CSSProperties } from "react";

import { InformationalTooltip } from "../../Common/Tooltip";
import { Container, Text } from "./__styles__/Tooltip";

export const SettingsTooltip = ({
  contentText,
  containerStyle,
}: {
  contentText: string;
  containerStyle?: CSSProperties;
}) => {
  const popoverContent = <Text>{contentText}</Text>;

  return (
    <Container style={containerStyle}>
      <InformationalTooltip popoverContent={popoverContent} place="right" />
    </Container>
  );
};
