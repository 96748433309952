import React from "react";
import { useModal } from "react-modal-hook";
import Modal from "../../../Common/Modal";
import {
  ButtonSection,
  Container,
  ContentSection,
  HeaderSection,
  PrimaryButtons,
} from "../../../Common/__styles__/Modal";
import { Button } from "../../../Common/Button";
import { FormProvider, useForm } from "react-hook-form";
import { SectionSettings } from "./OverviewSections/SectionSettings";
import { PROPERTY_INFORMATION_SECTION_CODE } from "common/constants";
import { EditSectionFormStructure } from "./OverviewSections/EditSection";
import { useCreatePropertyInformationSectionMutation } from "../../../../generated/graphql";
import { useHistory } from "react-router";
import { buildLink } from "common/routing";
import { useStatusToasts } from "../../../../hooks/useStatusToasts";

export const useCreateOverviewSectionModal = ({
  onUpdate,
}: {
  onUpdate: () => void;
}) => {
  const [showCreateOverviewSectionModal, hideCreateOverviewSectionModal] =
    useModal(() => {
      return (
        <Modal onRequestClose={hideCreateOverviewSectionModal}>
          <CreateOverviewSectionModal
            onCancel={hideCreateOverviewSectionModal}
            onUpdate={onUpdate}
          />
        </Modal>
      );
    });

  return [
    showCreateOverviewSectionModal,
    hideCreateOverviewSectionModal,
  ] as const;
};

export const CreateOverviewSectionModal = ({
  onCancel,
  onUpdate,
}: {
  onCancel: () => void;
  onUpdate: () => void;
}) => {
  const history = useHistory();
  const { addFailureToast, addSuccessToast } = useStatusToasts();
  const formMethods = useForm<EditSectionFormStructure>();
  const { handleSubmit } = formMethods;

  const [createPropertyInformationSection, { loading }] =
    useCreatePropertyInformationSectionMutation({
      onCompleted: data => {
        onUpdate();
        addSuccessToast("Section created successfully");
        history.push(
          buildLink("editSection", {
            sectionId: data.createPropertyInformationSection.id,
          })
        );
      },
      onError: () => {
        addFailureToast("Failed to create section");
      },
    });

  const onSubmit = async (data: EditSectionFormStructure) => {
    await createPropertyInformationSection({
      variables: {
        data: {
          label: data.label,
          hiddenFromPublic: !data.displayToPublic,
        },
      },
    });
  };

  return (
    <Container>
      <HeaderSection>
        <h1>Add section</h1>
      </HeaderSection>
      <ContentSection>
        <FormProvider {...formMethods}>
          <SectionSettings code={PROPERTY_INFORMATION_SECTION_CODE.CUSTOM} />
        </FormProvider>
      </ContentSection>
      <ButtonSection>
        <PrimaryButtons>
          <Button size="medium" styleVariant="secondary" onClick={onCancel}>
            Cancel
          </Button>

          <Button
            size="medium"
            styleVariant="primary"
            onClick={handleSubmit(onSubmit)}
            loading={loading}
          >
            Add
          </Button>
        </PrimaryButtons>
      </ButtonSection>
    </Container>
  );
};
