import React, { useReducer } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { useToasts } from "react-toast-notifications";
import { getPath } from "common/routing";
import { validatePassword } from "common/utils/validate-password";
import {
  SecurityConfiguration,
  useAcceptInviteMutation,
  UserInvite as UserInviteType,
} from "../../generated/graphql";
import { ProvideCode } from "../Authentication/ProvideCode";
import { ProvidePhoneNumber } from "../Authentication/ProvidePhoneNumber";
import {
  MFAReducer,
  SCREEN_DISPLAY,
  useMFAFlow,
} from "common-client/utils/useMFAFlow";

import { Grid, Row, Col, FlexColumn } from "../Common/Layout";
import { Button } from "../Common/Button";
import { Checkbox, Text } from "../Inputs/react-hook-form";

import {
  Container,
  FooterRow,
  Form,
  Note,
  NoteRow,
  Optional,
  PositionedErrorSpan,
  Title,
  UserAgreement,
  Wrapper,
} from "./__styles__/UserInvite";

type UserInviteProps = {
  userInvite: Omit<
    UserInviteType,
    "role" | "canViewPersonalIdentifiableInformation" | "account"
  > & {
    account: {
      securityConfiguration: Pick<
        SecurityConfiguration,
        "minimumPasswordLength" | "multifactorAuthEnabled"
      >;
    };
  };
};

interface FormStructure {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: Maybe<string>;
  password: string;
  terms: boolean;
  code?: Maybe<string>;
}

export const UserInvite = ({ userInvite }: UserInviteProps) => {
  const history = useHistory();
  const { addToast } = useToasts();
  const [state, dispatch] = useMFAFlow({
    defaultValue: { currentScreen: SCREEN_DISPLAY.PROVIDE_CREDENTIALS },
    useReducer: useReducer as MFAReducer,
  });

  const onLoginSuccess = () => history.push(getPath("map"));
  const [acceptInvite, { loading: mutationLoading }] = useAcceptInviteMutation({
    onCompleted: data => {
      if (data.acceptInvite.ok) {
        onLoginSuccess();
      } else {
        dispatch({ type: "login", data: data.acceptInvite });
      }
    },
    onError: () => {
      addToast(
        "There was an error accepting this invite. Please try again. If the problem persists, please email us at support@withforerunner.com",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    },
  });

  const useFormProps = useForm<FormStructure>({
    defaultValues: {
      firstName: userInvite.firstName,
      lastName: userInvite.lastName,
      email: userInvite.email,
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useFormProps;

  const hasMultifactorAuthEnabled =
    userInvite.account.securityConfiguration.multifactorAuthEnabled;

  const optional = hasMultifactorAuthEnabled ? null : (
    <Optional>Optional</Optional>
  );

  return (
    <Wrapper>
      <Container>
        <FormProvider {...useFormProps}>
          <Form>
            {state.currentScreen === SCREEN_DISPLAY.PROVIDE_CREDENTIALS && (
              <>
                <Title>Create your account</Title>
                <Grid>
                  <Row>
                    <Col>
                      <Text
                        label="First Name"
                        error={errors.firstName?.message}
                        required
                        {...register("firstName", {
                          required: "Required field",
                        })}
                      />
                    </Col>
                    <Col>
                      <Text
                        label="Last Name"
                        error={errors.lastName?.message}
                        required
                        {...register("lastName", {
                          required: "Required field",
                        })}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Text
                        disabled
                        label="Email Address"
                        {...register("email")}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Text
                        type="tel"
                        label="Mobile phone number"
                        placeholder="(000) 000-0000"
                        error={errors.phoneNumber?.message}
                        children={optional}
                        {...register("phoneNumber", {
                          validate: {
                            required: value => {
                              if (!value && hasMultifactorAuthEnabled) {
                                return "Phone number is required";
                              }
                              return true;
                            },
                          },
                        })}
                        required={hasMultifactorAuthEnabled}
                      />
                    </Col>
                  </Row>
                  {hasMultifactorAuthEnabled && (
                    <NoteRow>
                      <Col>
                        <Note>
                          Note: Enter a phone number where we can send an
                          authenticationcode via SMS.
                        </Note>
                      </Col>
                    </NoteRow>
                  )}
                  <Row>
                    <Col>
                      <Text
                        label="Password"
                        type="password"
                        error={errors.password?.message}
                        placeholder="********"
                        required
                        {...register("password", {
                          validate: (val: Maybe<string>) => {
                            return validatePassword({
                              password: val ?? "",
                              minimumPasswordLength:
                                userInvite.account.securityConfiguration
                                  .minimumPasswordLength,
                            });
                          },
                        })}
                      />
                    </Col>
                  </Row>
                  <NoteRow>
                    <Col>
                      <Note>
                        Note: Use at least{" "}
                        {
                          userInvite.account.securityConfiguration
                            .minimumPasswordLength
                        }{" "}
                        characters in your password and include at least one
                        number, one upper case letter, and one special
                        character.
                      </Note>
                    </Col>
                  </NoteRow>
                  <FooterRow>
                    <FlexColumn style={{ width: "100%", alignItems: "end" }}>
                      <UserAgreement>
                        <Checkbox
                          {...register("terms", {
                            validate: (val: Maybe<boolean>) => {
                              if (!val) {
                                return "You must accept the Terms of Use";
                              }
                              return;
                            },
                          })}
                        />
                        I have read and agree to the{" "}
                        <a
                          href="https://www.withforerunner.com/terms-of-use"
                          target="_blank"
                        >
                          Terms of Use
                        </a>
                        {errors.terms?.message && (
                          <PositionedErrorSpan>
                            {errors.terms.message}
                          </PositionedErrorSpan>
                        )}
                      </UserAgreement>

                      <Button
                        type="submit"
                        styleVariant="primary"
                        size="medium"
                        onClick={handleSubmit(async data => {
                          await acceptInvite({
                            variables: {
                              ...data,
                              phoneNumber: data.phoneNumber,
                              inviteId: userInvite!.id,
                            },
                          });
                        })}
                        disabled={mutationLoading}
                      >
                        Agree and continue
                      </Button>
                    </FlexColumn>
                  </FooterRow>
                </Grid>
              </>
            )}

            {state.currentScreen === SCREEN_DISPLAY.PROVIDE_PHONE && (
              <ProvidePhoneNumber dispatch={dispatch} error={state.error} />
            )}
            {state.currentScreen === SCREEN_DISPLAY.PROVIDE_CODE && (
              <ProvideCode
                allowAnotherNumber={!state.phoneNumberVerified!}
                dispatch={dispatch}
                error={state.error}
                maskedPhoneNumber={state.phoneNumber!}
                onSuccess={onLoginSuccess}
              />
            )}
          </Form>
        </FormProvider>
      </Container>
    </Wrapper>
  );
};
