import React from "react";
import { Switch, Route } from "react-router-dom";

import EditProperty from "./EditProperty";
import EditCertificate from "./EditCertificate";
import BuildSubmissionType from "./BuildSubmissionType";

export default function BackofficeApp() {
  return (
    <Switch>
      <Route
        path="/backoffice/properties/:propertyId"
        component={EditProperty}
      />
      <Route
        path="/backoffice/certificates/:certificateId"
        component={EditCertificate}
      />
      <Route
        path="/backoffice/submissionTypes/build"
        component={BuildSubmissionType}
      />
    </Switch>
  );
}
