import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "../../../../Common/__styles__/StripedTable";
import { DropdownMenu } from "../../../../Inputs";
import { ActionsProps } from "../../../../Inputs/DropdownMenu";

import { PROPERTY_ATTRIBUTE_SOURCE } from "common/constants";
import { RasterOptions } from "..";

import {
  CustomMap,
  GetPropertyInformationSectionQuery,
  useUpdateOrCreatePropertyAttributeMutation,
} from "../../../../../generated/graphql";
import { useUpdateOrCreateAttributeModal } from "./updateOrCreateSectionAttributeModal";
import {
  DeleteAttributeModalProps,
  useDeleteAttributeModal,
} from "./deleteAttributeModal";
import { AuthContext } from "../../../../Authorization/AuthContext";
import { FlexRow } from "../../../../Common/Layout";
import Divider from "../../../../Common/Divider";
import { PropertyOverviewTooltip } from "../../../../AddressPanel/PropertyOverview/Common/PropertyOverviewTooltip";
import { Body } from "../../../../Common/__styles__/Typography";
import { EmptyState } from "../../../../Common/EmptyState";

type BasePropertyAttributeTableProps = Omit<
  NonNullable<
    GetPropertyInformationSectionQuery["propertyInformationSection"]
  >["accountPropertyAttributes"][number],
  "__typename"
>;

const SectionAttributesTable = ({
  currentData,
  sectionId,
  rasterOptions,
  customMapOptions,
  onUpdate,
}: {
  currentData: {
    accountPropertyAttributes?: Array<BasePropertyAttributeTableProps>;
    ruleDefinitions: DeleteAttributeModalProps["ruleDefinitions"];
  };
  sectionId: string;
  rasterOptions: RasterOptions;
  customMapOptions: Array<Pick<CustomMap, "id" | "type" | "name">>;
  onUpdate: () => void;
}) => {
  const { account } = React.useContext(AuthContext);

  const [updateOrCreatePropertyAttribute] =
    useUpdateOrCreatePropertyAttributeMutation();

  const [showEditAttributeModal] = useUpdateOrCreateAttributeModal({
    onSubmit: updateOrCreatePropertyAttribute,
    onUpdate,
    rasterOptions,
    customMapOptions,
    sectionId,
  });

  const [showDeleteAttributeModal] = useDeleteAttributeModal({
    onUpdate,
    ruleDefinitions: currentData.ruleDefinitions,
  });

  const renderActionButton = ({
    existingPropertyAttribute,
    rasterOptions,
  }: {
    existingPropertyAttribute: BasePropertyAttributeTableProps;
    rasterOptions: RasterOptions;
  }) => {
    const actions: Array<ActionsProps> = [
      {
        label: "Edit attribute",
        onClick: () => {
          showEditAttributeModal({
            existingPropertyAttribute,
            rasterOptions,
          });
        },
      },
    ];
    if (
      existingPropertyAttribute.source === PROPERTY_ATTRIBUTE_SOURCE.RASTER ||
      existingPropertyAttribute.source === PROPERTY_ATTRIBUTE_SOURCE.CUSTOM_MAP
    ) {
      actions.push({
        label: "Delete",
        variant: "red",
        onClick: () =>
          showDeleteAttributeModal({
            id: existingPropertyAttribute.id,
            label: existingPropertyAttribute.label,
          }),
      });
    }

    return <DropdownMenu actions={actions} isTableAction />;
  };

  if (!currentData.accountPropertyAttributes?.length) {
    return (
      <div style={{ marginTop: "72px" }}>
        <EmptyState message="Add attributes to your section!" />;
      </div>
    );
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableCell style={{ paddingBottom: "8px" }}>
            <Body size="default" type="emphasis">
              Attribute name
            </Body>
          </TableCell>
          {account?.publicPortal.enabled && (
            // 50px right is to align with the isPublic column, offsetting for the action button
            <TableCell style={{ marginRight: "50px", paddingBottom: "8px" }}>
              <Body size="default" type="emphasis">
                Public website
              </Body>
            </TableCell>
          )}
        </TableRow>
      </TableHeader>
      <Divider />
      <TableBody>
        {currentData.accountPropertyAttributes.map(attribute => (
          <TableRow key={attribute.id}>
            <TableCell
              style={{
                margin: "auto 0",
                overflowX: "hidden",
                whiteSpace: "normal",
              }}
            >
              {attribute.label}
              {account?.publicPortal.enabled && (
                <PropertyOverviewTooltip
                  isGuestOnly={false}
                  tooltipMarkdown={attribute.tooltip}
                />
              )}
            </TableCell>
            <FlexRow style={{ margin: "auto 0" }}>
              {account?.publicPortal.enabled && (
                <TableCell style={{ margin: "auto 0" }}>
                  {attribute.isPublic ? "Displayed" : "Hidden"}
                </TableCell>
              )}

              <TableCell>
                {renderActionButton({
                  existingPropertyAttribute: attribute,
                  rasterOptions,
                })}
              </TableCell>
            </FlexRow>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default SectionAttributesTable;
