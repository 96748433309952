import { PDFDocument, PDFCheckBox } from "pdf-lib";
import { getPath } from "common/routing";
import * as Sentry from "@sentry/browser";
import { useHistory, useLocation } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import React, { useContext, useState } from "react";
import { useDropzone as useReactDropzone } from "react-dropzone";
import { useStatusToasts } from "../../../../hooks/useStatusToasts";
import { CreateDocumentTemplateForm, FORM_STAGES } from "./CreateOrUpdateForm";
import { useGetPresignedDocumentTemplateUploadS3UrlLazyQuery } from "../../../../generated/graphql";

import {
  Select,
  SingleFileUpload,
  Text,
} from "../../../Inputs/react-hook-form";
import { Button } from "../../../Common/Button";
import { HeaderRowComponent } from "../../../FileUploads";
import { AuthContext } from "../../../Authorization/AuthContext";
import FullPageFormLayout from "../../../Common/FullPageFormLayout";
import {
  findFieldFont,
  allFontNames,
  visitPDFFields,
  Font,
  getFieldType,
} from "common/utils/documentTemplates";

import {
  ContentSection,
  FormSection,
  InputRow,
} from "../../../Common/__styles__/Modal";
import { ButtonContainer, Container } from "./__styles__/DocumentTemplates";
import { MIME_TYPE } from "common/constants";
import { isNotNull } from "common/utils/tools";

export type UploadStepProps = {
  setFormStage: (formStage: FORM_STAGES) => void;
  useDropzone?: (
    args: Pick<NonNullable<Parameters<typeof useReactDropzone>[0]>, "onDrop">
  ) => ReturnType<typeof useReactDropzone>;
  get?: typeof fetch;
};

const UploadStep = ({
  setFormStage,
  useDropzone = undefined,
  get = fetch,
}: UploadStepProps) => {
  const history = useHistory();
  const { account } = useContext(AuthContext);
  const location = useLocation<undefined | { prevLocation?: string }>();
  const [uploadingFile, setUploadingFile] = useState(false);
  const [documentTemplateUploadIsValid, setDocumentTemplateUploadIsValid] =
    useState(false);
  const { addFailureToast } = useStatusToasts();

  const accountDocumentTypes = account?.accountDocumentTypes.filter(adt =>
    adt.allowedMimeTypes.includes("application/pdf")
  );
  const prevLocation =
    location.state?.prevLocation ?? getPath("documentTemplates");

  const {
    watch,
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useFormContext<CreateDocumentTemplateForm>();

  const setMappingPdfFields = async (pdfBlob: Blob) => {
    const pdfBuffer = await pdfBlob.arrayBuffer();
    const pdfDoc = await PDFDocument.load(pdfBuffer);
    const pdfForm = pdfDoc.getForm();
    const mappingWithPdfFields = pdfForm
      .getFields()
      .filter(field => !field.getName().includes("ignore"))
      .map(field => ({
        label: field.getName(),
        value: null,
        pdfFieldType: getFieldType(field),
        forerunnerFieldType: null,
      }));

    setValue("mapping", mappingWithPdfFields);
  };

  const upload = async ({
    uploadURL,
    pdfBlob,
  }: {
    uploadURL: string;
    pdfBlob: Blob;
  }) => {
    setUploadingFile(true);

    try {
      const result = await get(uploadURL, {
        method: "PUT",
        body: pdfBlob,
      });

      if (!result.ok) {
        throw new Error(
          `Received a ${result.status} when uploading document template`
        );
      }
    } catch (error) {
      Sentry.captureException(error);
      addFailureToast(
        "Your upload failed. Please remove the file and try reuploading it."
      );
    } finally {
      setUploadingFile(false);
    }
  };

  const pdfBlob = watch("documentTemplateUpload.blob");

  const [getPresignedUrl, { loading }] =
    useGetPresignedDocumentTemplateUploadS3UrlLazyQuery({
      onCompleted: async data => {
        const { s3Key, uploadURL } =
          data.getPresignedDocumentTemplateUploadS3Url;

        setValue("s3Key", s3Key);
        await setMappingPdfFields(pdfBlob);
        await upload({ uploadURL, pdfBlob });

        setFormStage(FORM_STAGES.MAPPING);
      },
      fetchPolicy: "network-only",
    });

  const disabled = loading || uploadingFile || !documentTemplateUploadIsValid;

  const rightContainer = (
    <ButtonContainer>
      <Button
        styleVariant="secondary"
        onClick={() => history.push({ pathname: prevLocation })}
        size="medium"
      >
        Cancel
      </Button>
      <Button
        styleVariant="primary"
        disabled={disabled}
        loading={uploadingFile || loading}
        onClick={handleSubmit(() => getPresignedUrl())}
        size="medium"
      >
        Next
      </Button>
    </ButtonContainer>
  );

  return (
    <FullPageFormLayout
      subtitle="Import template file"
      prevLocation={prevLocation}
      rightContainer={rightContainer}
    >
      <Container>
        <FormSection>
          <ContentSection overflows={true}>
            <InputRow>
              <Text
                label="Name"
                size="medium"
                required
                {...register("name", {
                  required: "Name is required",
                })}
                error={errors.name?.message}
              />
            </InputRow>
            <InputRow>
              <Select
                label="Associated document type"
                name="associatedDocumentTypeId"
                placeholder="Select document type..."
                size="medium"
                control={control}
                rules={{
                  required: "Document type is required",
                }}
                required
                options={accountDocumentTypes!.map(adt => ({
                  label: adt.name,
                  value: adt.id,
                }))}
                error={errors.associatedDocumentTypeId?.message}
              />
            </InputRow>
            <SingleFileUpload
              label="Upload file"
              name="documentTemplateUpload"
              description="File must be a PDF file with smart fields"
              control={control}
              rules={{
                required: "PDF file is required",
                validate: async file => {
                  const document = await PDFDocument.load(
                    await file.blob.arrayBuffer()
                  )!;
                  const warnings = visitPDFFields({
                    document,
                    visit: ({ field, fieldName }) => {
                      if (field instanceof PDFCheckBox) {
                        return null;
                      }
                      const fontName = findFieldFont(field.acroField);
                      if (!allFontNames.includes(fontName as Font)) {
                        return `Font ${fontName} for field ${fieldName} is not a supported font.`;
                      } else {
                        return null;
                      }
                    },
                  }).filter(isNotNull);

                  if (!warnings.length) {
                    setDocumentTemplateUploadIsValid(true);
                    return true;
                  } else {
                    return warnings.join("\n");
                  }
                },
              }}
              required
              useDropzone={useDropzone}
              allowedMimeTypes={[MIME_TYPE.PDF]}
              fileHeader={
                <HeaderRowComponent hasGreenCheckmark={true}>
                  <div tabIndex={0}>File name</div>
                </HeaderRowComponent>
              }
            />
          </ContentSection>
        </FormSection>
      </Container>
    </FullPageFormLayout>
  );
};

export default UploadStep;
