import React, { useContext, useState } from "react";
import { useModal } from "react-modal-hook";
import Modal from "../../../../Common/Modal";
import {
  ButtonSection,
  Container,
  ContentSection,
  FormSection,
  HeaderSection,
  PrimaryButtons,
} from "../../../../Common/__styles__/Modal";
import { Button } from "../../../../Common/Button";
import {
  DfeRule,
  PropertyAttribute,
  useDeleteAccountPropertyAttributeMutation,
} from "../../../../../generated/graphql";
import { useStatusToasts } from "../../../../../hooks/useStatusToasts";
import { flatten } from "lodash";
import { formatCollection, pluralize } from "common/utils/strings";
import { AuthContext } from "../../../../Authorization/AuthContext";

export const useDeleteAttributeModal = (
  props: Omit<
    DeleteAttributeModalProps,
    "accountPropertyAttribute" | "onCancel"
  >
) => {
  const [accountPropertyAttribute, setAccountPropertyAttribute] = useState<{
    id: string;
    label: string;
  }>({ id: "", label: "" });

  const [show, hideUpdateOrCreatePropertyAttributeModal] = useModal(
    () => (
      <Modal onRequestClose={hideUpdateOrCreatePropertyAttributeModal}>
        <DeleteAttributeModal
          {...props}
          accountPropertyAttribute={accountPropertyAttribute}
          onCancel={hideUpdateOrCreatePropertyAttributeModal}
        />
      </Modal>
    ),

    [accountPropertyAttribute]
  );

  const showUpdateOrCreatePropertyAttributeModal = (
    accountPropertyAttribute: Pick<PropertyAttribute, "id" | "label">
  ) => {
    setAccountPropertyAttribute(accountPropertyAttribute);
    show();
  };

  return [
    showUpdateOrCreatePropertyAttributeModal,
    hideUpdateOrCreatePropertyAttributeModal,
  ] as const;
};

export type DeleteAttributeModalProps = {
  onCancel: () => void;
  onUpdate: () => void;
  accountPropertyAttribute: Pick<PropertyAttribute, "id" | "label">;
  ruleDefinitions: Array<{
    rules: Array<Pick<DfeRule, "referenceAttribute" | "name">>;
  }>;
};

export const DeleteAttributeModal = ({
  accountPropertyAttribute,
  ruleDefinitions,
  onCancel,
  onUpdate,
}: DeleteAttributeModalProps) => {
  const { account } = useContext(AuthContext);
  const { addFailureToast, addSuccessToast } = useStatusToasts();
  const [deleteAccountPropertyAttribute, { loading }] =
    useDeleteAccountPropertyAttributeMutation();
  const handleDelete = async () => {
    await deleteAccountPropertyAttribute({
      variables: { id: accountPropertyAttribute.id },
      onCompleted: () => {
        addSuccessToast(
          `${accountPropertyAttribute.label} attribute was successfully deleted.`
        );
        onUpdate();
        onCancel();
      },
      onError: () => {
        addFailureToast(
          `${accountPropertyAttribute.label} attribute failed to delete.`
        );
        // We refetch in case it was a timing issue and a rule now uses the attribute
        onUpdate();
      },
    });
  };

  const associatedRules = flatten(
    ruleDefinitions.map(ruleDefinition =>
      ruleDefinition.rules
        .filter(rule => rule.referenceAttribute === accountPropertyAttribute.id)
        .map(rule => `"${rule.name}"`)
    )
  );

  const canDelete = associatedRules.length === 0;

  let contentText;
  if (canDelete) {
    if (account?.publicPortal.enabled) {
      contentText =
        "This action will result in a permanent deletion of an attribute from the Property Information Panel, for both public-facing and internal dashboards.";
    } else {
      contentText =
        "This action will result in a permanent deletion of an attribute from the Property Information Panel.";
    }
  } else {
    const rulesCollection = formatCollection(associatedRules);
    const ruleText = pluralize({
      count: associatedRules.length,
      options: { singular: "rule", plural: "rules" },
    });
    const ruleTextWithArticle = pluralize({
      count: associatedRules.length,
      options: { singular: "this rule", plural: "these rules" },
    });

    contentText = `This attribute cannot be deleted because it is linked to the ${rulesCollection} Design Flood Elevation logic ${ruleText}. Edit or delete ${ruleTextWithArticle} from the settings page first in order to proceed.`;
  }

  return (
    <Container>
      <HeaderSection>
        <h1>Delete "{accountPropertyAttribute.label}" attribute</h1>
      </HeaderSection>
      <FormSection>
        <ContentSection>{contentText}</ContentSection>
      </FormSection>
      <ButtonSection>
        <PrimaryButtons>
          <Button size="medium" styleVariant="secondary" onClick={onCancel}>
            {canDelete ? "Cancel" : "Close"}
          </Button>

          {canDelete && (
            <Button
              size="medium"
              styleVariant="alert"
              onClick={handleDelete}
              loading={loading}
            >
              I understand, delete attribute
            </Button>
          )}
        </PrimaryButtons>
      </ButtonSection>
    </Container>
  );
};
