import React from "react";
import { useModal } from "react-modal-hook";
import { FirmSource } from "../../../../generated/graphql";

import Modal from "../../../Common/Modal";
import { FirmImportForm } from "./ImportForm/FirmImportForm";

export interface UseFirmImportModalProps {
  firmId: string;
  firmSource: FirmSource;
  onScheduledFirmImport: () => void;
}

export const useFirmImportModal = ({
  firmId,
  firmSource,
  onScheduledFirmImport,
}: UseFirmImportModalProps) => {
  const [showFirmImportModal, hideFirmImportModal] = useModal(() => (
    <Modal onRequestClose={hideFirmImportModal}>
      <FirmImportForm
        firmId={firmId}
        firmSource={firmSource}
        onCancel={hideFirmImportModal}
        onScheduledFirmImport={onScheduledFirmImport}
      ></FirmImportForm>
    </Modal>
  ));

  return [showFirmImportModal, hideFirmImportModal] as const;
};
