import React, { createRef, useContext, useEffect, useState } from "react";
import {
  buildFormStructure,
  CommonForms,
} from "common/services/formBuilderService";
import { JsonEditor } from "json-edit-react";

import {
  AppContainer,
  FormWrapper,
  Schema,
  SchemaOptions,
  SchemaWrapper,
  Schemas,
  Title,
} from "./__styles__/BuildSubmissionType";
import { Controller, useForm } from "react-hook-form";
import { FormComponent } from "../../../../Submissions/Form";
import { Button } from "../../../../Common/Button";
import { generateCustomNodeDefinitions } from "./NodeDefinitions";
import { Select } from "../../../../Inputs/react-hook-form";
import { IMPROVEMENT_KINDS, SUBMISSION_TYPE_MODULE } from "common/constants";
import { AuthContext } from "../../../../Authorization/AuthContext";

export interface SubmissionBuilder {
  schema: Array<any>;
  formData: any;
  valid: boolean;
  currentSchema: typeof CommonForms[number]["name"];
  modules: Array<SUBMISSION_TYPE_MODULE>;
}

export const BuildSubmission = () => {
  const { account } = useContext(AuthContext);

  const { control, watch, setValue } = useForm<SubmissionBuilder>({
    defaultValues: {
      schema: CommonForms[0]?.inputs,
      formData: {},
      currentSchema: CommonForms[0]?.name,
      modules: CommonForms[0]?.modules ?? [],
    },
  });

  const submitFormRef = createRef<HTMLButtonElement>();

  const [parsedFormStructure, setParsedFormStructure] = useState({
    schema: {},
    uiSchema: {},
  });

  const [parseError, setParseError] = useState<Maybe<string>>(null);

  useEffect(() => {
    const { schema, uiSchema, isValid, errors } = buildFormStructure({
      formData: watch("schema"),
    });

    if (!isValid) {
      setParseError(errors.join("\n"));
    } else {
      setParsedFormStructure({ schema, uiSchema });
      setParseError(null);
    }
  }, [watch("schema")]);

  const accountNodeDefinitions = generateCustomNodeDefinitions({
    accountDocumentTypes: account?.accountDocumentTypes ?? [],
  });

  return (
    <AppContainer>
      <Schemas>
        <SchemaWrapper>
          <SchemaOptions>
            <Title>Schema</Title>
            <Select
              name="currentSchema"
              control={control}
              options={CommonForms.map(sample => ({
                label: sample.name,
                value: sample.name,
              }))}
              onChange={value => {
                const sampleForm = CommonForms.find(
                  form => form.name === value
                )!;

                setValue("formData", {});
                setValue("schema", sampleForm.inputs);
                setValue("modules", sampleForm.modules ?? []);
              }}
            />
            <Button
              styleVariant="secondary"
              onClick={() =>
                navigator.clipboard.writeText(JSON.stringify(watch("schema")))
              }
              size={"small"}
            >
              Copy
            </Button>
          </SchemaOptions>
          <Schema>
            <Controller
              control={control}
              name="schema"
              render={({ field }) => {
                return (
                  <JsonEditor
                    maxWidth={"100%"}
                    data={field.value}
                    customNodeDefinitions={accountNodeDefinitions}
                    onUpdate={({ newData }) => {
                      field.onChange(newData);
                      setValue("formData", {});
                    }}
                  />
                );
              }}
            />
          </Schema>
        </SchemaWrapper>
        <SchemaWrapper>
          <Title>Form Data</Title>
          <Schema>
            <JsonEditor
              key={JSON.stringify(watch("formData"))}
              maxWidth={"100%"}
              data={{ valid: watch("valid"), ...watch("formData") }}
            />
          </Schema>
        </SchemaWrapper>
      </Schemas>
      <FormWrapper>
        <Title>Form Preview</Title>
        {parseError && <div>{parseError}</div>}
        <FormComponent
          // this is required because react schema form loses it's mind
          // when the schema changes out from under it, so we force
          // react to unmount and remount the form component
          key={JSON.stringify(parsedFormStructure)}
          submissionType={{
            currentVersion: { formStructure: parsedFormStructure },
            modules: watch("modules") ?? [],
          }}
          onSubmit={formData => setValue("formData", formData)}
          onChange={({ isValid }) => setValue("valid", isValid)}
          submitFormRef={submitFormRef}
          defaultValues={{
            parcel: {
              mailingAddress1: "1234 Main St",
              mailingAddress2: "Chicago, IL",
              improvementValue: 10,
              lot: "123_456_7890",
              block: "987_654_3210",
              parcelId: "parcelId",
              ownerName: "Owner Person",
            },
            property: {
              improvementValue: 1000,
              latitude: 123.456,
              longitude: 456.789,
              sisd: {
                adjustmentRatio: 0.5,
                defaultExclude: false,
                costType: IMPROVEMENT_KINDS.IMPROVEMENT,
              },
            },
            user: {
              firstName: "John",
              lastName: "Doe",
            },
          }}
        />
        <Button
          style={{ marginTop: "20px" }}
          size="small"
          styleVariant="primary"
          onClick={() => submitFormRef.current?.click()}
        >
          Submit
        </Button>
      </FormWrapper>
    </AppContainer>
  );
};

export default BuildSubmission;
