import { isUndefined } from "lodash";
import React from "react";
import { useHistory } from "react-router";
import { useToasts } from "react-toast-notifications";
import { useDeleteSubmissionMutation } from "../../../generated/graphql";
import { track } from "../../../utils/tracking";
import {
  ButtonSection,
  Container,
  ContentSection,
  HeaderSection,
  PrimaryButtons,
} from "../../Common/__styles__/Modal";
import { Icon } from "../../DocumentUploads/__styles__/DeleteConfirmation";
import { Button } from "../../Common/Button";

export type DeleteConfirmationProps = {
  submission: {
    id: string;
    property?: Maybe<{
      id: string;
    }>;
    submissionTypeVersion: {
      submissionType: { name: string; category: string };
    };
  };
  prevLocation?: string;
  closeModal: () => void;
  onUpdate?: () => void;
};

const DeleteConfirmation = ({
  submission,
  prevLocation,
  closeModal,
  onUpdate,
}: DeleteConfirmationProps) => {
  const { addToast } = useToasts();
  const history = useHistory();

  const [deleteSubmission, { loading }] = useDeleteSubmissionMutation({
    onCompleted: () => {
      track("Submission deleted", {
        submissionName: submission.submissionTypeVersion.submissionType.name,
        propertyId: submission.property?.id,
      });
      onUpdate?.();
      addToast("Your submission was successfully deleted", {
        appearance: "success",
        autoDismiss: true,
      });
      if (!isUndefined(prevLocation)) {
        history.push(prevLocation);
      }
      closeModal();
    },
    onError: () => {
      closeModal();
      addToast(
        "There was an issue deleting this submission. Please try again. If the problem persists, please email us at support@withforerunner.com",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    },
  });

  return (
    <Container width={"narrow"}>
      <HeaderSection>
        <h1>Delete Submission</h1>
      </HeaderSection>
      <ContentSection style={{ textAlign: "center" }}>
        <Icon />
        <p>Are you certain you would like to delete this submission?</p>
        <p>
          All associated files will also be deleted from your internal dashboard
          and public website.
        </p>
      </ContentSection>
      <ButtonSection>
        <PrimaryButtons>
          <Button styleVariant="secondary" onClick={closeModal} size="medium">
            Cancel
          </Button>
          <Button
            styleVariant="alert"
            onClick={async () => {
              await deleteSubmission({
                variables: { submissionId: submission.id },
              });
            }}
            size="medium"
            disabled={loading}
          >
            Delete
          </Button>
        </PrimaryButtons>
      </ButtonSection>
    </Container>
  );
};

export default DeleteConfirmation;
