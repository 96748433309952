import React from "react";
import { Label } from "../../../../../../Inputs";
import { Checkbox, Text } from "../../../../../../Inputs/react-hook-form";
import { CheckboxWrapper } from "./__styles__/WidgetSettings";
import { useFormContext } from "react-hook-form";
import { SubmissionsBuilderFormDataStructure } from "../../types";
import { SelectedSubmissionsBuilderField } from "../../reducer";

export const LabelAndRequired = ({
  fieldPath,
  isModuleInput,
}: Pick<SelectedSubmissionsBuilderField, "fieldPath"> & {
  isModuleInput: boolean;
}) => {
  const { register } = useFormContext<SubmissionsBuilderFormDataStructure>();
  return (
    <>
      <div>
        <Label text={"Field label"} required />
        <Text {...register(`${fieldPath}.title`)} />
      </div>
      <CheckboxWrapper>
        <Checkbox
          {...register(`${fieldPath}.required`)}
          id={`${fieldPath}.required`}
          disabled={isModuleInput}
        />
        <Label
          text={"Required field"}
          htmlFor={`${fieldPath}.required`}
          disabled={isModuleInput}
        />
      </CheckboxWrapper>
    </>
  );
};
