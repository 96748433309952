import React, { createRef, useState } from "react";
import { RouteComponentProps, useHistory, useLocation } from "react-router";
import { useStatusToasts } from "../../hooks/useStatusToasts";

import { RESOURCE_NAME } from "common/authorization";
import { buildLink } from "common/routing";
import {
  useGetPropertyForSubmissionQuery,
  useGetSubmissionQuery,
  useUpdateSubmissionMutation,
} from "../../generated/graphql";
import { track } from "../../utils/tracking";
import { AuthContext } from "../Authorization/AuthContext";
import FullPageFormLayout from "../Common/FullPageFormLayout";
import ActionButton from "./ActionButton";
import { FormComponent, FormDataType } from "./Form";

import { ButtonContainer } from "./__styles__/Submission";
import { Button } from "../Common/Button";
import SubmissionHeader from "./SubmissionHeader";
import { generateUpsertErrorMessage, hasSummary } from "./utils";

interface URLParams {
  submissionId: string;
}

export interface EditSubmissionProps extends RouteComponentProps<URLParams> {}

const EditSubmission = ({ match }: EditSubmissionProps) => {
  const history = useHistory();
  const location =
    useLocation<Maybe<{ prevLocation?: string; search?: string }>>();
  const { authorized, user, admin, isGuest } = React.useContext(AuthContext);
  const { addFailureToast, addSuccessToast } = useStatusToasts();
  const [valid, setValid] = useState(true);
  const [dirty, setDirty] = useState(false);

  const { submissionId } = match.params;

  const { data, loading, error } = useGetSubmissionQuery({
    variables: { submissionId },
    fetchPolicy: "no-cache",
  });

  const [updateSubmission, { loading: updateLoading }] =
    useUpdateSubmissionMutation({
      onCompleted: data => {
        const submission = data.updateSubmission;
        track("Submission updated", {
          submissionId: submission.id,
          "Submission Category": submissionType?.category,
          "Submission Type": submissionType?.name,
        });
        addSuccessToast("Your submission has been updated.");
        history.push(prevLocation, { forceRefresh: true });
      },
      onError: error => {
        const message = generateUpsertErrorMessage(error);
        addFailureToast(message);
      },
    });

  const propertyId = data?.submission?.property?.id;
  const { data: propertyData } = useGetPropertyForSubmissionQuery({
    variables: { propertyId: propertyId!, isGuest },
    skip: !propertyId,
  });

  const submission = data?.submission;
  if (loading || error || !submission) {
    return <div />;
  }

  const submitFormRef = createRef<HTMLButtonElement>();
  const prevLocation = `${location.state?.prevLocation ?? buildLink("map")}${
    location.state?.search ?? ""
  }`;

  const submissionType = submission.submissionTypeVersion.submissionType;
  const canUpdateSubmission = authorized({
    resource: RESOURCE_NAME.SUBMISSION,
    permission: "update",
  });

  const handleSubmit = async (formData: FormDataType) => {
    await updateSubmission({
      variables: { data: { submissionId, formData } },
    });
  };

  const handleOnChange = ({
    isDirty,
    isValid,
  }: {
    isDirty: boolean;
    isValid: boolean;
  }) => {
    setDirty(isDirty);
    setValid(isValid);
  };

  const rightContainer = (
    <ButtonContainer>
      <Button
        size="small"
        styleVariant="primary"
        onClick={() => {
          submitFormRef.current!.click();
        }}
        disabled={!canUpdateSubmission || !valid || !dirty}
        loading={updateLoading}
      >
        Update
      </Button>
      <ActionButton
        isEditView={true}
        submission={{
          ...submission,
          hasSummary: hasSummary(submission),
          category: submissionType.category,
        }}
      />
    </ButtonContainer>
  );

  const property = propertyData?.property;
  const parcel = property?.parcel;

  return (
    <FullPageFormLayout
      subtitle={submissionType.name}
      prevLocation={prevLocation}
      rightContainer={rightContainer}
    >
      <div>
        <SubmissionHeader
          property={submission.property}
          submissionType={submissionType}
        />
        <FormComponent
          disabled={!canUpdateSubmission}
          submissionType={{
            currentVersion: submission.submissionTypeVersion,
            modules: submission.submissionTypeVersion.submissionType.modules,
          }}
          existingSubmission={data.submission}
          onSubmit={handleSubmit}
          onChange={handleOnChange}
          submitFormRef={submitFormRef}
          defaultValues={{
            property: {
              improvementValue:
                property?.improvementValue || parcel?.improvementValue,
              sisd: {
                adjustmentRatio: property?.sisd.rule?.adjustmentRatio,
              },
            },
            parcel,
            user: user ?? admin,
          }}
        />
      </div>
    </FullPageFormLayout>
  );
};

export default EditSubmission;
