import React from "react";
import { useHistory } from "react-router";
import { buildLink } from "common/routing";

import { Box } from "../../../Common/Layout";
import {
  Header,
  HeaderTitle,
  HelperText,
  Section,
  SectionTitle,
} from "../../__styles__/Content";
import { Button } from "../../../Common/Button";

const PropertyOperations = () => {
  const history = useHistory();

  return (
    <Box>
      <Header style={{ padding: "24px" }}>
        <HeaderTitle style={{ margin: 0 }}>Property Operations</HeaderTitle>
      </Header>
      <Section
        style={{
          display: "flex",
          flexDirection: "column",
          paddingTop: "8px",
          paddingBottom: "32px",
        }}
      >
        <div style={{ flexBasis: "50%" }}>
          <div style={{ flexBasis: "50%" }}>
            <Button
              leftIconName="plus"
              styleVariant="outlineLight"
              size="small"
              style={{ float: "right" }}
              onClick={() => {
                history.push({
                  pathname: buildLink("dispatchBulkPropertyCreation"),
                });
              }}
            >
              Dispatch Bulk Property Creation
            </Button>
            <SectionTitle>Bulk Property Creation</SectionTitle>
          </div>
          <HelperText>
            Upload a CSV and dispatch a bulk property creation operation using
            the CSV as input data.
          </HelperText>
        </div>
      </Section>
    </Box>
  );
};

export default PropertyOperations;
