import React, { useContext } from "react";
import { useModal } from "react-modal-hook";
import { useLocation } from "react-router";
import { RESOURCE_NAME } from "common/authorization";
import {
  ADDRESS_PANEL_TAB_NAME,
  buildLink,
  pipLink,
  RECORDS_TAB_NAME,
} from "common/routing";
import {
  Property,
  Submission,
  SubmissionStatus,
  SubmissionType,
} from "../../generated/graphql";
import DeleteConfirmation from "../AddressPanel/Permits/DeleteConfirmation";
import { AuthContext } from "../Authorization/AuthContext";
import Modal from "../Common/Modal";
import { Actions } from "../Common/ActionCell";
import { ActionsProps } from "../Inputs/DropdownMenu";
import { track } from "../../utils/tracking";
import { SUBMISSION_CATEGORY } from "common/constants";

export type SubmissionActionButtonProps = {
  isEditView?: boolean;
  isSummaryView?: boolean;
  submission: Pick<Submission, "id" | "status"> & {
    property?: Maybe<
      Pick<Property, "id" | "fullAddress" | "longitude" | "latitude">
    >;
    submissionTypeVersion: {
      submissionType: Pick<SubmissionType, "category" | "name">;
    };
    hasSummary: boolean;
    category: SUBMISSION_CATEGORY;
  };
  onUpdate?: () => void;
};

const SUBMISSION_CATEGORY_TO_TAB = {
  [SUBMISSION_CATEGORY.PERMITTING]: RECORDS_TAB_NAME.PERMITS,
  [SUBMISSION_CATEGORY.INSPECTIONS]: RECORDS_TAB_NAME.INSPECTIONS,
  [SUBMISSION_CATEGORY.SUBSTANTIAL_IMPROVEMENT_SUBSTANTIAL_DAMAGE]:
    RECORDS_TAB_NAME.IMPROVEMENTS,
};

const ActionButton = ({
  isEditView = false,
  isSummaryView = false,
  submission,
  onUpdate,
}: SubmissionActionButtonProps) => {
  const { pathname, search, state } = useLocation<{
    prevLocation?: Maybe<string>;
  }>();

  const { authorized } = useContext(AuthContext);
  const isDetailView = isSummaryView || isEditView;
  const deletePrevLocation = isDetailView
    ? state?.prevLocation ?? ""
    : undefined;
  const [showDeleteSubmissionModal, hideDeleteSubmissionModal] = useModal(
    () => (
      <Modal onRequestClose={hideDeleteSubmissionModal}>
        <DeleteConfirmation
          submission={submission}
          prevLocation={deletePrevLocation}
          closeModal={hideDeleteSubmissionModal}
          onUpdate={onUpdate}
        />
      </Modal>
    )
  );

  const prevLocation = `${pathname}${search}`;
  const recordTab = SUBMISSION_CATEGORY_TO_TAB[submission.category];
  const hasSummary = submission.hasSummary;

  const canDeleteSubmission =
    authorized({
      resource: RESOURCE_NAME.SUBMISSION,
      permission: "delete",
    }) && submission.status !== SubmissionStatus.PROCESSING;

  const actions: Array<ActionsProps> = [];

  if (!isDetailView) {
    actions.push({
      label: "View",
      to: {
        pathname: buildLink("viewSubmission", {
          submissionId: submission.id,
        }),
        state: { prevLocation },
      },
      track: () => {
        track("Submission viewed", {
          submissionId: submission.id,
          "Submission Category":
            submission.submissionTypeVersion.submissionType?.category,
          "Submission Type":
            submission.submissionTypeVersion.submissionType?.name,
        });
      },
    });
  }

  if (isSummaryView) {
    actions.push({
      label: "Edit",
      to: {
        pathname: buildLink("editSubmission", {
          submissionId: submission.id,
        }),
        state: { prevLocation },
      },
    });
  }

  if (isEditView && hasSummary) {
    actions.push({
      label: "View summary",
      to: {
        pathname: buildLink("submissionSummary", {
          submissionId: submission.id,
        }),
        state: { prevLocation },
      },
    });
  }

  if (submission.property) {
    actions.push({
      label: "View on map",
      to: pipLink({
        address: submission.property.fullAddress,
        propertyId: submission.property.id,
        lat: submission.property.latitude?.toString() ?? "",
        lng: submission.property.longitude?.toString() ?? "",
        tab: ADDRESS_PANEL_TAB_NAME.RECORDS,
        recordTab,
      }),
    });
  }
  actions.push({
    label: "Delete",
    onClick: () => showDeleteSubmissionModal(),
    disabled: !canDeleteSubmission,
    variant: "red",
    track: () => {
      track("Submission deleted", {
        submissionId: submission.id,
        "Submission Category":
          submission.submissionTypeVersion.submissionType?.category,
        "Submission Type":
          submission.submissionTypeVersion.submissionType?.name,
      });
    },
  });

  return (
    <Actions
      actions={actions}
      buttonText={isDetailView ? "Actions" : undefined}
    />
  );
};

export default ActionButton;
