import { useRevalidateElevationCertificatesMutation } from "../../../../generated/graphql";
import { Button } from "../../../Common/Button";
import { Box } from "../../../Common/Layout";
import {
  Header,
  HeaderTitle,
  HelperText,
  Section,
  SectionTitle,
} from "../../__styles__/Content";
import { useRevalidateEcsModal } from "./revalidateEcsModal";
import React from "react";

export const EcValidations = () => {
  const [runEcRevalidation] = useRevalidateElevationCertificatesMutation();

  const [showRevalidateEcs] = useRevalidateEcsModal({
    onSubmit: runEcRevalidation,
    onUpdate: () => {},
  });

  return (
    <Box>
      <Header style={{ padding: "24px" }}>
        <HeaderTitle style={{ margin: 0 }}>
          Elevation Certificate validations
        </HeaderTitle>
      </Header>
      <Section
        style={{
          display: "flex",
          flexDirection: "column",
          paddingTop: "8px",
          paddingBottom: "32px",
        }}
      >
        <div style={{ flexBasis: "50%" }}>
          <div style={{ flexBasis: "50%" }}>
            <Button
              leftIconName="plus"
              styleVariant="outlineLight"
              size="small"
              style={{ float: "right" }}
              onClick={() => {
                showRevalidateEcs();
              }}
            >
              Revalidate
            </Button>
            <SectionTitle>EC revalidation</SectionTitle>
          </div>
          <HelperText>
            Recompute errors on selected elevation certificates in an account.
          </HelperText>
        </div>
      </Section>
    </Box>
  );
};
