import { ColumnDef } from "@tanstack/react-table";
import React, { useContext } from "react";
import {
  AttributesWrapper,
  DropdownItem,
  SectionTitleLabel,
} from "../../Filter/__styles__/Filters";
import { useColumnSettingsReducer } from "./ColumnSettingsReducer";
import Divider from "../../Divider";
import { SettingsRow } from "./SettingsRow";
import { hasColumnId } from "./utils";
import { TableContext } from "../TableContext";
import { track } from "../../../../utils/tracking";
import { Attribute } from "../types";

const AddColumnMenu = <T,>({
  currentColumns,
  addColumn,
  settingsState,
  dispatch,
}: {
  currentColumns: ColumnDef<T>[];
  addColumn: (id: string) => void;
  settingsState: ReturnType<typeof useColumnSettingsReducer>[0];
  dispatch: ReturnType<typeof useColumnSettingsReducer>[1];
}) => {
  const { name } = useContext(TableContext);
  const { currentAttribute } = settingsState;

  const hasAttributes = "attributes" in currentAttribute;

  if (!hasAttributes) {
    return null;
  }

  const onAddColumn = (attribute: Pick<Attribute, "columnId" | "label">) => {
    addColumn(attribute.columnId!);
    track("Added column", {
      tableName: name,
      column: attribute.label,
    });
  };

  return (
    <>
      <Divider />
      <DropdownItem>
        <AttributesWrapper>
          <SectionTitleLabel>Attributes</SectionTitleLabel>
          {currentAttribute.attributes.map((attribute, index) => {
            if (hasColumnId(attribute)) {
              return (
                <SettingsRow
                  label={attribute.label}
                  onRowClick={() => onAddColumn(attribute)}
                  key={index}
                  leftIcon={attribute.icon}
                  disabled={currentColumns.some(
                    column => column.id === attribute.columnId
                  )}
                />
              );
            } else if ("attributes" in attribute) {
              return (
                <SettingsRow
                  onRowClick={() => {
                    dispatch({
                      type: "setAttribute",
                      data: {
                        newAttribute: attribute,
                      },
                    });
                  }}
                  key={index}
                  rightIcon="chevron-right"
                  leftIcon={attribute.icon}
                  label={attribute.label}
                />
              );
            } else {
              return null;
            }
          })}
        </AttributesWrapper>
      </DropdownItem>
    </>
  );
};

export default AddColumnMenu;
