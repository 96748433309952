import React, { useContext } from "react";
import { DropdownMenu } from "../../../Inputs";
import { useDeleteModal } from "../../../Common/DeleteModal";
import { useStatusToasts } from "../../../../hooks/useStatusToasts";
import { DocumentTemplate as AccountDocumentTemplate } from "./__queries__/documentTemplates";
import { useDeleteAccountDocumentTemplateMutation } from "../../../../generated/graphql";
import { ActionsProps } from "../../../Inputs/DropdownMenu";
import { AuthContext } from "../../../Authorization/AuthContext";
import { RESOURCE_NAME } from "common/authorization";
import { useHistory } from "react-router";
import { buildLink } from "common/routing";

export const ActionButton = ({
  documentTemplate,
  onUpdate,
}: {
  documentTemplate: AccountDocumentTemplate;
  onUpdate: () => void;
}) => {
  const history = useHistory();
  const { authorized } = useContext(AuthContext);
  const { addFailureToast, addSuccessToast } = useStatusToasts();

  const [deleteDocumentTemplate] = useDeleteAccountDocumentTemplateMutation({
    onCompleted: () => {
      onUpdate();
      hideDeleteDocumentTemplateModal();
      addSuccessToast(`${documentTemplate.name} deleted successfully`);
    },
    onError: () => {
      hideDeleteDocumentTemplateModal();
      addFailureToast(
        `There was an issue deleting ${documentTemplate.name}. Please try again or reach out to support dev.`
      );
    },
  });

  const [showDeleteDocumentTemplateModal, hideDeleteDocumentTemplateModal] =
    useDeleteModal({
      headerText: "Delete document template",
      subheaderText: `Delete "${documentTemplate.name}"`,
      width: "narrow",
      Content: (
        <p>
          This action will result in <b>permanent</b> deletion of the template
          from the list of available templates within the account. Any documents
          created on properties using this template will not be deleted, but
          users will not have the ability to generate new documents using this
          specific template.
        </p>
      ),
      deleteButtonText: "I understand, delete template",
      onDelete: async () =>
        await deleteDocumentTemplate({
          variables: { id: documentTemplate.id },
        }),
    });

  const canDeleteDocumentTemplate = authorized({
    resource: RESOURCE_NAME.DOCUMENT_TEMPLATE,
    permission: "delete",
  });

  const canUpdateDocumentTemplate = authorized({
    resource: RESOURCE_NAME.DOCUMENT_TEMPLATE,
    permission: "update",
  });

  const actions: Array<ActionsProps> = [
    {
      label: "Edit settings",
      disabled: !canUpdateDocumentTemplate,
      onClick: () =>
        history.push({
          pathname: buildLink("updateDocumentTemplate"),
          search: `?id=${documentTemplate.id}`,
        }),
    },
    {
      label: "Delete",
      variant: "red",
      disabled: !canDeleteDocumentTemplate,
      onClick: showDeleteDocumentTemplateModal,
    },
  ];

  return (
    <DropdownMenu
      actions={actions}
      data-testid={`actionButton-${documentTemplate.name}`}
    />
  );
};
