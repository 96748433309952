import { useModal } from "react-modal-hook";
import Modal from "../../../../Common/Modal";
import React, { useEffect, useState } from "react";
import {
  ButtonSection,
  Container,
  ContentSection,
  FormSection,
  HeaderSection,
  InputRow,
  PrimaryButtons,
} from "../../../../Common/__styles__/Modal";
import { Button } from "../../../../Common/Button";
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from "react-hook-form";
import {
  UpdateOrCreatePropertyAttributeMutationFn,
  CreateCustomAccountPropertyAttributeMutationFn,
  CustomMapType,
  CustomMap,
} from "../../../../../generated/graphql";
import {
  PROPERTY_ATTRIBUTE_NAMES,
  PROPERTY_ATTRIBUTE_NAME_ALREADY_EXISTS_ERROR,
  PROPERTY_ATTRIBUTE_SOURCE,
} from "common/constants";
import {
  Checkbox,
  Select,
  Text,
  Textarea,
} from "../../../../Inputs/react-hook-form";
import { AuthContext } from "../../../../Authorization/AuthContext";
import { useStatusToasts } from "../../../../../hooks/useStatusToasts";
import { Label } from "../../../../Inputs";
import { DisplaySettingLabel } from "./__styles__/updateOrCreateSectionAttribute";
import { InputGrid } from "../__styles__/floodInfoModals";
import { setTextValueAs } from "../../../../../utils/react-hook-form";
import { RasterOptions } from "..";
import { ApolloError } from "@apollo/client";
import { track } from "../../../../../utils/tracking";
import { captureMessage } from "@sentry/browser";

export type UpdateOrCreatePropertyAttributesFormStructure = {
  id: string;
  label: string;
  name: string;
  source?: Maybe<PROPERTY_ATTRIBUTE_SOURCE>;
  sourceId?: Maybe<string>;
  isPublic: boolean;
  tooltip?: Maybe<string>;
  displayValueAsLink: boolean;
};

export interface UpdateOrCreatePropertyAttributeModalProps {
  onCancel: () => void;
  onSubmit:
    | UpdateOrCreatePropertyAttributeMutationFn
    | CreateCustomAccountPropertyAttributeMutationFn;
  onUpdate: () => void;
  existingPropertyAttribute?: UpdateOrCreatePropertyAttributesFormStructure;
  rasterOptions: RasterOptions;
  customMapOptions: Array<Pick<CustomMap, "id" | "type" | "name">>;
  sectionId: string;
}

export const useUpdateOrCreateAttributeModal = ({
  onSubmit,
  onUpdate,
  customMapOptions,
  sectionId,
}: Omit<
  UpdateOrCreatePropertyAttributeModalProps,
  "existingPropertyAttribute" | "onCancel"
>) => {
  const [props, setProps] = useState<{
    existingPropertyAttribute?: UpdateOrCreatePropertyAttributesFormStructure;
    rasterOptions: RasterOptions;
  }>({ rasterOptions: [] });

  const [show, hideUpdateOrCreatePropertyAttributeModal] = useModal(
    () => (
      <Modal onRequestClose={hideUpdateOrCreatePropertyAttributeModal}>
        <UpdateOrCreatePropertyAttributeModal
          onSubmit={onSubmit}
          onCancel={hideUpdateOrCreatePropertyAttributeModal}
          onUpdate={() => {
            hideUpdateOrCreatePropertyAttributeModal();
            onUpdate();
          }}
          customMapOptions={customMapOptions}
          sectionId={sectionId}
          {...props}
        />
      </Modal>
    ),
    [props]
  );

  const showUpdateOrCreatePropertyAttributeModal = ({
    existingPropertyAttribute,
    rasterOptions,
  }: {
    existingPropertyAttribute?: UpdateOrCreatePropertyAttributesFormStructure;
    rasterOptions: RasterOptions;
  }) => {
    setProps({
      existingPropertyAttribute,
      rasterOptions,
    });
    show();
  };

  return [
    showUpdateOrCreatePropertyAttributeModal,
    hideUpdateOrCreatePropertyAttributeModal,
  ] as const;
};

export const UpdateOrCreatePropertyAttributeModal = ({
  onSubmit,
  onCancel,
  onUpdate,
  existingPropertyAttribute,
  rasterOptions,
  customMapOptions,
  sectionId,
}: UpdateOrCreatePropertyAttributeModalProps) => {
  const { account } = React.useContext(AuthContext);
  const { addFailureToast, addSuccessToast } = useStatusToasts();
  const formMethods = useForm<UpdateOrCreatePropertyAttributesFormStructure>({
    defaultValues: existingPropertyAttribute ?? {
      isPublic: false,
      displayValueAsLink: false,
    },
    reValidateMode: "onChange",
  });

  const {
    control,
    formState: { errors, isDirty, isValid },
    handleSubmit,
    register,
    setError,
    setValue,
    watch,
  } = formMethods;

  const headerText = `${existingPropertyAttribute ? "Edit" : "Add"} attribute`;

  const sourceOptions = [
    {
      label: "Raster layers",
      options: rasterOptions.map(raster => ({
        value: raster.id,
        label: `${raster.layerName} - Band number ${raster.bandMappings.bandNumber}`,
      })),
    },
    {
      label: "Custom maps",
      options: customMapOptions
        .filter(customMap => customMap.type !== CustomMapType.VISUAL)
        .map(customMap => ({
          value: customMap.id,
          label: `${customMap.name} label`,
        })),
    },
  ];

  const sourceId = watch("sourceId");
  const isGreatestInclusionCustomMapAttribute =
    customMapOptions.find(customMap => customMap.id === sourceId)?.type ===
    CustomMapType.GREATEST_INCLUSION;
  useEffect(() => {
    if (!isGreatestInclusionCustomMapAttribute) {
      setValue("displayValueAsLink", false);
    }
  }, [isGreatestInclusionCustomMapAttribute]);

  const isDFEAttribute =
    existingPropertyAttribute?.name === PROPERTY_ATTRIBUTE_NAMES.DFE;

  const nameDisabled =
    existingPropertyAttribute?.source === PROPERTY_ATTRIBUTE_SOURCE.FEMA ||
    isDFEAttribute;

  const submitButtonDisabled = !isValid || !isDirty;
  const submitButtonText = existingPropertyAttribute ? "Update" : "Save";
  const tooltipLabel = isDFEAttribute
    ? "Tooltip verbiage"
    : "Public website tooltip verbiage";

  const handleSubmitCallback = async (
    data: UpdateOrCreatePropertyAttributesFormStructure
  ) => {
    const onError = (error: Error, verb: string) => {
      if (error.message === PROPERTY_ATTRIBUTE_NAME_ALREADY_EXISTS_ERROR) {
        setError("name", {
          message: PROPERTY_ATTRIBUTE_NAME_ALREADY_EXISTS_ERROR,
        });
      } else {
        addFailureToast(`${verb} attribute failed to process.`);
      }
    };

    const successVerb = existingPropertyAttribute ? "updated" : "added";
    const errorVerb = existingPropertyAttribute ? "Update" : "Add new";

    let onSubmitParams = {
      onCompleted: () => {
        addSuccessToast(`Attribute successfully ${successVerb}.`);
        onUpdate();
      },
      onError: (error: ApolloError) => onError(error, errorVerb),
    };

    data.label = data.label.trim();

    if (existingPropertyAttribute) {
      track("Section attribute updated", {
        id: existingPropertyAttribute.id,
        label: data.label,
      });
      await (onSubmit as UpdateOrCreatePropertyAttributeMutationFn)({
        variables: {
          data: {
            accountId: account!.id,
            propertyAttributeId: data.id,
            label: data.label,
            isPublic: data.isPublic,
            tooltip: data.tooltip,
            displayValueAsLink: isGreatestInclusionCustomMapAttribute
              ? data.displayValueAsLink
              : false,
          },
        },
        ...onSubmitParams,
      });
    } else if (data.sourceId) {
      track("Section attribute added", {
        label: data.label,
        sourceId: data.sourceId,
      });
      const source = rasterOptions.some(raster => raster.id === data.sourceId)
        ? PROPERTY_ATTRIBUTE_SOURCE.RASTER
        : PROPERTY_ATTRIBUTE_SOURCE.CUSTOM_MAP;
      await (onSubmit as CreateCustomAccountPropertyAttributeMutationFn)({
        variables: {
          data: {
            accountId: account!.id,
            label: data.label,
            isPublic: data.isPublic,
            tooltip: data.tooltip,
            displayValueAsLink: data.displayValueAsLink,
            source,
            sourceId: data.sourceId,
            sectionId,
          },
        },
        ...onSubmitParams,
      });
    } else {
      captureMessage("No sourceId provided for new section attribute");
    }
  };

  return (
    <Container overflows>
      <HeaderSection>
        <h1>{headerText}</h1>
      </HeaderSection>
      <FormSection overflows>
        <FormProvider {...formMethods}>
          <ContentSection overflows>
            <InputGrid style={{ marginTop: "8px" }}>
              <Text
                compactLabel={true}
                required
                error={errors.name?.message}
                {...register("label", {
                  required: "Attribute name is required.",
                })}
                label="Attribute name"
                disabled={nameDisabled}
                data-testid="attributeName"
              />

              {!existingPropertyAttribute && (
                <div>
                  <Label
                    text="Source"
                    htmlFor="sourceId"
                    compact={true}
                    required
                  />
                  <Controller
                    control={control}
                    name="sourceId"
                    rules={{ required: "Attribute source is required" }}
                    render={({ field, fieldState }) => {
                      return (
                        <Select
                          name="sourceId"
                          required={true}
                          control={control}
                          value={field.value}
                          error={fieldState.error?.message}
                          options={sourceOptions}
                          onChange={field.onChange}
                          data-testid="sourceSelect"
                        />
                      );
                    }}
                  />
                </div>
              )}
              {(isGreatestInclusionCustomMapAttribute ||
                account?.publicPortal.enabled) && (
                <DisplaySettings
                  isGreatestInclusionCustomMapAttribute={
                    isGreatestInclusionCustomMapAttribute
                  }
                />
              )}

              {account?.publicPortal.enabled && (
                <div>
                  <Textarea
                    id="tooltip"
                    {...register("tooltip", {
                      setValueAs: setTextValueAs,
                      validate: () => true,
                    })}
                    draggable={false}
                    label={tooltipLabel}
                    compactLabel={true}
                    name="tooltip"
                    error={errors.tooltip?.message}
                    styleOverride={{
                      maxHeight: "74px",
                      marginBottom: "15px",
                      resize: "none",
                      borderRadius: "1px",
                    }}
                    disabled={isDFEAttribute}
                    required
                  />
                </div>
              )}
            </InputGrid>
          </ContentSection>
        </FormProvider>
      </FormSection>
      <ButtonSection>
        <PrimaryButtons>
          <Button size="medium" styleVariant="secondary" onClick={onCancel}>
            Cancel
          </Button>

          <Button
            size="medium"
            styleVariant="primary"
            onClick={handleSubmit(handleSubmitCallback)}
            disabled={submitButtonDisabled}
          >
            {submitButtonText}
          </Button>
        </PrimaryButtons>
      </ButtonSection>
    </Container>
  );
};

const DisplaySettings = ({
  isGreatestInclusionCustomMapAttribute,
}: {
  isGreatestInclusionCustomMapAttribute: boolean;
}) => {
  const { account } = React.useContext(AuthContext);
  const { register } =
    useFormContext<UpdateOrCreatePropertyAttributesFormStructure>();

  const label =
    isGreatestInclusionCustomMapAttribute && account?.publicPortal.enabled
      ? "Display settings"
      : "Display setting";

  return (
    <div>
      <Label text={label} style={{ marginBottom: "8px" }} required />
      {account?.publicPortal.enabled && (
        <InputRow>
          <Checkbox
            id={"displaySetting"}
            {...register("isPublic")}
            aria-labelledby="displaySettingLabel"
          />
          <DisplaySettingLabel
            id="displaySettingLabel"
            htmlFor="displaySetting"
          >
            Display on public website
          </DisplaySettingLabel>
        </InputRow>
      )}

      {isGreatestInclusionCustomMapAttribute && (
        <InputRow style={{ marginBottom: "0" }}>
          <Checkbox
            id="displayValueAsLink"
            {...register("displayValueAsLink")}
            aria-labelledby="displayValueAsLinkLabel"
          />
          <DisplaySettingLabel
            id="displayValueAsLinkLabel"
            htmlFor="displayValueAsLink"
          >
            Display value as link
          </DisplaySettingLabel>
        </InputRow>
      )}
    </div>
  );
};
