import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { Label } from "../../../../../../Inputs";
import { Text } from "../../../../../../Inputs/react-hook-form";
import {
  InputFieldPath,
  SubmissionsBuilderFormDataStructure,
} from "../../types";
import { HelpText, WidgetSettingsContainer } from "./__styles__/WidgetSettings";
import { Button } from "../../../../../../Common/Button";
import LabelValueOptionsInput from "./LabelValueOptionsInput";
import { LabelAndRequired } from "./shared";

const DropdownWidgetSettings = ({
  fieldPath,
  isModuleInput,
}: {
  fieldPath: InputFieldPath;
  isModuleInput: boolean;
}) => {
  const { register, setValue, watch } =
    useFormContext<SubmissionsBuilderFormDataStructure>();

  let options = watch(`${fieldPath}.enum`);
  let optionsAsObjects = options?.map(
    (option: string | { label: string; value: string }) => {
      if (typeof option === "string") {
        return {
          label: option,
          value: option,
        };
      }
      return option;
    }
  );
  const [dropdownOptions, setDropdownOptions] = useState<
    { label: string; value: string }[]
  >(optionsAsObjects?.length ? optionsAsObjects : [{ label: "", value: "" }]);

  const setDropdownOption = (index: number, label: string, value: string) => {
    const updatedOptions = [...dropdownOptions];
    updatedOptions[index] = { label, value };
    setDropdownOptions(updatedOptions);
    setValue(`${fieldPath}.enum`, updatedOptions, { shouldValidate: true });
  };

  const addDropdownOption = () => {
    const updatedOptions = [...dropdownOptions, { label: "", value: "" }];
    setDropdownOptions(updatedOptions);
    setValue(`${fieldPath}.enum`, updatedOptions, { shouldValidate: true });
  };

  const removeDropdownOption = (index: number) => {
    dropdownOptions.splice(index, 1);
    // creating a shallow copy of the array since splice modifies in place
    const updatedOptions = [...dropdownOptions];
    setDropdownOptions(updatedOptions);
    setValue(`${fieldPath}.enum`, updatedOptions, { shouldValidate: true });
  };

  return (
    <WidgetSettingsContainer>
      <LabelAndRequired fieldPath={fieldPath} isModuleInput={isModuleInput} />
      <div>
        <Label text={"Placeholder text"} />
        <Text {...register(`${fieldPath}.placeholder`)} />
      </div>
      <div>
        <div>
          <Label
            style={{ marginBottom: "4px" }}
            required
            text={"Dropdown options"}
          />
          <HelpText style={{ marginTop: "0px", paddingBottom: "8px" }}>
            Labels are visible to users, while values are backend identifiers
            and hidden from them.
          </HelpText>
        </div>
        <LabelValueOptionsInput
          dropdownOptions={dropdownOptions}
          setDropdownOption={setDropdownOption}
          removeDropdownOption={removeDropdownOption}
          valueEditingDisabled={isModuleInput}
        />
        <div style={{ paddingTop: "8px" }}>
          <Button
            styleVariant="outlineLight"
            size="small"
            leftIconName="plus"
            onClick={addDropdownOption}
            disabled={isModuleInput}
          >
            Add option
          </Button>
        </div>
      </div>
    </WidgetSettingsContainer>
  );
};

export default DropdownWidgetSettings;
