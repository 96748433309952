import { styled } from "../../../../../stitches.config";
import { FormSection } from "../../../../Common/__styles__/Modal";
import { Label } from "../../../../Inputs";
import { Row } from "../../__styles__/Row";

export const IssuedAtStartDatePickerContainer = styled("div", {
  marginRight: "8px",
});

export const IssuedAtEndDatePickerContainer = styled("div", {
  marginLeft: "8px",
});

export const IssuedAtRow = styled(Row, {
  paddingTop: "0px",
  display: "none",
  variants: {
    visible: {
      true: {
        display: "inherit",
      },
    },
  },
});

export const RevalidateEcsFormSection = styled(FormSection, {
  minHeight: "330px",
});

export const EcErrorCodesLabel = styled(Label, {
  marginTop: "16px",
});
