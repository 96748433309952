import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useModal } from "react-modal-hook";

import Modal from "../../../Common/Modal";
import { Button } from "../../../Common/Button";
import { useStatusToasts } from "../../../../hooks/useStatusToasts";

import {
  ButtonSection,
  Container,
  ContentSection,
  FormSection,
  HeaderSection,
  InputRow,
  PrimaryButtons,
} from "../../../Common/__styles__/Modal";
import { Select, Text, Checkbox } from "../../../Inputs/react-hook-form";
import {
  useCreateFirmMutation,
  FirmType,
  Datum,
  FirmSource,
} from "../../../../generated/graphql";
import { AuthContext } from "../../../Authorization/AuthContext";
import { getUserErrors } from "../../../../utils/apollo";

export const useCreateFIRMModal = () => {
  const [showCreateFIRMModal, hideCreateFIRMModal] = useModal(
    () => (
      <Modal
        onRequestClose={() => {
          hideCreateFIRMModal();
        }}
      >
        <CreateFIRMModal
          onSave={hideCreateFIRMModal}
          onCancel={hideCreateFIRMModal}
        />
      </Modal>
    ),
    []
  );
  return [showCreateFIRMModal, hideCreateFIRMModal] as const;
};

type CreateFIRMForm = {
  type: FirmType;
  name: string;
  useForRegulation: boolean;
  datum?: Datum;
  source: FirmSource;
};

export const CreateFIRMModal = ({
  onSave,
  onCancel,
}: {
  onSave: () => void;
  onCancel: () => void;
}) => {
  const { addSuccessToast, addFailureToast } = useStatusToasts();
  const { account } = React.useContext(AuthContext);

  const formMethods = useForm<CreateFIRMForm>({
    defaultValues: { useForRegulation: false },
  });

  const {
    handleSubmit,
    formState: { errors, isValid },
    register,
    control,
  } = formMethods;

  const [createFIRM, { loading: creatingFIRM }] = useCreateFirmMutation({
    onCompleted: () => {
      addSuccessToast("Your FIRM was created! Don't forget to import it.");
      onSave();
    },
    onError: error => {
      const userErrors = getUserErrors(error);
      if (userErrors) {
        addFailureToast(userErrors);
      } else {
        addFailureToast("Your FIRM could not be created.");
      }
    },
    update: (cache, result) => {
      cache.modify({
        id: cache.identify(account!),
        fields: {
          firms(existingFIRMs = []) {
            return [...existingFIRMs, result.data?.createFIRM];
          },
        },
      });
    },
  });

  const onSubmit = async (data: CreateFIRMForm) => {
    if (creatingFIRM) return;

    await createFIRM({ variables: { data } });
  };

  return (
    <FormProvider {...formMethods}>
      <Container>
        <HeaderSection>
          <h1>Create FIRM</h1>
        </HeaderSection>
        <FormSection>
          <ContentSection>
            <InputRow>
              <Text
                label="Name"
                size="medium"
                required
                {...register("name", {
                  required: "Name is required",
                })}
                error={errors.name?.message}
              />
            </InputRow>
            <InputRow>
              <Select
                label="Type"
                name="type"
                placeholder="Select FIRM type..."
                size="medium"
                control={control}
                rules={{
                  required: "Type is required",
                }}
                required
                options={Object.values(FirmType).map(type => ({
                  label: type,
                  value: type,
                }))}
                error={errors.type?.message}
              />
            </InputRow>
            <InputRow>
              <Select
                label="Source"
                name="source"
                placeholder="Select source..."
                size="medium"
                control={control}
                rules={{
                  required: "Source is required",
                }}
                required
                options={Object.values(FirmSource).map(type => ({
                  label: type,
                  value: type,
                }))}
                error={errors.source?.message}
              />
            </InputRow>
            <InputRow>
              <Select
                label="Datum"
                name="datum"
                placeholder="Select datum..."
                size="medium"
                control={control}
                options={Object.values(Datum).map(type => ({
                  label: type,
                  value: type,
                }))}
              />
            </InputRow>
            <InputRow>
              <Checkbox
                id="useForRegulation"
                {...register("useForRegulation")}
              />
              <label htmlFor="useForRegulation">Use for regulation</label>
            </InputRow>
          </ContentSection>
          <ButtonSection>
            <PrimaryButtons>
              <Button styleVariant="secondary" size="medium" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                styleVariant="primary"
                disabled={!isValid || creatingFIRM}
                size="medium"
                onClick={handleSubmit(onSubmit)}
              >
                Create
              </Button>
            </PrimaryButtons>
          </ButtonSection>
        </FormSection>
      </Container>
    </FormProvider>
  );
};
