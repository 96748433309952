import React from "react";
import cx from "classnames";

import "./__styles__/TextComponents.scss";
import {
  DefaultTitle,
  LargeTitle,
  SmallTitle,
  TextSize,
} from "./__styles__/Typography";
import { colors } from "../../stitches.config";

/**
 * deprecated: use Title or Body from Typography.ts
 * @deprecated
 */
export enum TEXT_COLORS {
  BLACK = "black",
  GREY_1 = "#0c111b",
  GREY_3 = "#434a58",
}

interface TextProps {
  color?: TEXT_COLORS;
}

interface HeadingProps {
  medium?: boolean;
}
/**
 * deprecated: use Title or Body from Typography.ts
 * @deprecated
 */
export const Heading6: React.FC<HeadingProps & TextProps> = ({
  children,
  medium = false,
  color = TEXT_COLORS.BLACK,
}) => (
  <h6 styleName={cx("h6", { medium })} style={{ color }}>
    {children}
  </h6>
);

/**
 * deprecated: use Title or Body from Typography.ts
 * @deprecated
 */
export const Body3: React.FC<TextProps> = ({
  children,
  color = TEXT_COLORS.BLACK,
}) => (
  <p styleName="b3" style={{ color }}>
    {children}
  </p>
);

/**
 * deprecated: use Title or Body from Typography.ts
 * @deprecated
 */
export const ExternalURL: React.FC<
  React.HTMLProps<HTMLAnchorElement>
> = props => (
  <a {...props} styleName="external-link">
    {props.children}
  </a>
);

export type TitleType = "semiBold" | "regular";

export const Title = ({
  size,
  type,
  color = "contentPrimary",
  children,
}: {
  size: TextSize;
  type: TitleType;
  color?: keyof typeof colors;
  children: React.ReactNode;
}) => {
  const css = { color: colors[color].toString() };

  switch (size) {
    case "small":
      return (
        <SmallTitle type={type} css={css}>
          {children}
        </SmallTitle>
      );
    case "default":
      return (
        <DefaultTitle type={type} css={css}>
          {children}
        </DefaultTitle>
      );
    case "large":
      return (
        <LargeTitle type={type} css={css}>
          {children}
        </LargeTitle>
      );
    default:
      return null;
  }
};
